import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TalentService } from "../talent.service";
import { FormControl } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { map, startWith } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
@Component({
  selector: "app-communication-management",
  templateUrl: "./communication-management.component.html",
  styleUrls: ["./communication-management.component.css"],
})
export class CommunicationManagementComponent implements OnInit {
  is_admin: boolean = false;
  is_loading: boolean = true;
  is_update_loading: boolean = false;
  is_done: boolean = false;

  filter_input = "";
  sort_asc: boolean = true;
  sort_type: string = "id";

  is_admin_edit = false;
  admin_users = [];
  adminCtrl = new FormControl();
  filteredActiveUsersForAdmin: any = [];
  @ViewChild("adminInput", { static: false })
  adminInput: ElementRef<HTMLInputElement>;

  user_communication_settings: Array<Object> = [];
  filtered_user_communication_settings: Array<Object> = [];
  all_active_users = [];
  all_active_users_obj = {};

  edit_user: number = -1;
  userViewerCtrl = new FormControl();
  filteredActiveUsers: any = [];

  @ViewChild("userViewerInput", { static: false })
  userViewerInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private talentService: TalentService) {}

  ngOnInit() {
    this.talentService.checkUserCommunicationAdmin().subscribe((res) => {
      this.is_admin = res["is_communication_admin"];

      if (this.is_admin) {
        this.getAllUserCommunicationSettings();
        this.getAdmins();
        this.talentService.getAllActiveUsers().subscribe((res) => {
          this.all_active_users = res;
          for (let user of this.all_active_users) {
            this.all_active_users_obj[user["id"]] = user;
          }

          this.filteredActiveUsersForAdmin = this.adminCtrl.valueChanges.pipe(
            startWith(""),
            map((user_name: any) =>
              typeof user_name === "string"
                ? this._userAdminFilter(user_name)
                : []
            )
          );

          this.filteredActiveUsers = this.userViewerCtrl.valueChanges.pipe(
            startWith(""),
            map((user_name: any) =>
              typeof user_name === "string"
                ? this._userViewerFilter(user_name)
                : []
            )
          );

          this.is_loading = false;
        });
      }
    });
  }

  getAllUserCommunicationSettings() {
    this.talentService.getAllUserCommunicationSettings().subscribe((res) => {
      this.user_communication_settings = res;
      console.log(this.user_communication_settings);
      for (let user of this.user_communication_settings) {
        user["filter_input"] =
          user["user_name"] +
          " " +
          user["email_address"] +
          " " +
          user["full_name"];
      }
      this.filtered_user_communication_settings =
        this.user_communication_settings;
    });
  }

  getAdmins() {
    this.talentService.getCommunicationAdmins().subscribe((res) => {
      this.admin_users = res;
    });
  }

  applyFilter() {
    if (this.filter_input == "") {
      this.filtered_user_communication_settings =
        this.user_communication_settings;
    } else {
      this.filtered_user_communication_settings =
        this.user_communication_settings.filter((user) => {
          return user["filter_input"]
            .toLowerCase()
            .includes(this.filter_input.toLowerCase());
        });
    }
  }

  changeSortType(new_sort_type) {
    if (new_sort_type == this.sort_type) {
      this.sort_asc = !this.sort_asc;
    } else {
      this.sort_type = new_sort_type;
    }
  }

  removeUserViewer(viewer_id) {
    this.filtered_user_communication_settings.filter((user) => {
      if (user["id"] == this.edit_user) {
        user["viewers"] = user["viewers"].filter((viewer) => {
          return viewer["id"] != viewer_id;
        });
      }
    });
    this.user_communication_settings.filter((user) => {
      if (user["id"] == this.edit_user) {
        user["viewers"] = user["viewers"].filter((viewer) => {
          return viewer["id"] != viewer_id;
        });
      }
    });
  }

  selectViewerForUser(event: MatAutocompleteSelectedEvent) {
    let viewer_id = event.option.value;
    let viewer = this.all_active_users_obj[viewer_id];

    // Check if the viewer is already in the list to avoid duplicates
    this.filtered_user_communication_settings.forEach((user) => {
      if (
        user["id"] === this.edit_user &&
        !user["viewers"].some((v) => v.id === viewer_id)
      ) {
        user["viewers"].push(viewer);
      }
    });

    this.user_communication_settings.forEach((user) => {
      if (
        user["id"] === this.edit_user &&
        !user["viewers"].some((v) => v.id === viewer_id)
      ) {
        user["viewers"].push(viewer);
      }
    });

    this.userViewerCtrl.setValue("");
    this.userViewerInput.nativeElement.value = "";
  }

  clickEdit(user_id) {
    this.edit_user = user_id;
    this.userViewerCtrl.setValue("");
  }

  saveEdit(user_id) {
    this.updateEditedUser();
    this.edit_user = -1;
  }

  updateEditedUser() {
    this.is_update_loading = true;
    let edited_user = this.user_communication_settings.find(
      (user) => user["id"] === this.edit_user
    );
    let edited_user_viewers = edited_user["viewers"].map((viewer) => viewer.id);
    this.talentService
      .updateUserCommunicationSettings(edited_user, edited_user_viewers)
      .subscribe((res) => {
        this.updateDone();
      });
  }

  removeAdmin(user_id) {
    this.admin_users = this.admin_users.filter((user) => user["id"] != user_id);
  }

  selectAdmin(event: MatAutocompleteSelectedEvent) {
    let admin_id = event.option.value;
    let admin = this.all_active_users_obj[admin_id];
    this.admin_users.push(admin);
    this.adminCtrl.setValue("");
    this.adminInput.nativeElement.value = "";
  }

  clickEditAdminList() {
    this.is_admin_edit = !this.is_admin_edit;
  }

  saveAdminList() {
    let admin_ids = this.admin_users.map((user) => user["id"]);
    this.talentService
      .updateCommunicationAdmins({ admin_ids: admin_ids })
      .subscribe((res) => {
        this.is_update_loading = true;
        this.getAdmins();
        this.getAllUserCommunicationSettings();
        this.is_admin_edit = false;
        this.updateDone();
      });
  }

  updateDone() {
    this.is_update_loading = false;
    this.is_done = true;
    setTimeout(() => {
      this.is_done = false;
    }, 1800);
  }

  private _userAdminFilter(value: string): any[] {
    let filterValue = "";
    if (typeof value === "string") {
      filterValue = value.toLowerCase();
    }

    let res = this.all_active_users.filter(
      (user) =>
        !this.admin_users.some((admin) => admin["id"] === user["id"]) &&
        (user["first_name"].toLowerCase().includes(filterValue) ||
          user["last_name"].toLowerCase().includes(filterValue))
    );
    return res;
  }

  private _userViewerFilter(value: string): any[] {
    let filterValue = "";
    if (typeof value === "string") {
      filterValue = value.toLowerCase();
    }

    // Get the currently edited user's viewers
    const editedUser = this.user_communication_settings.find(
      (user) => user["id"] === this.edit_user
    );
    const selectedViewerIds = new Set(
      editedUser ? editedUser["viewers"].map((viewer) => viewer.id) : []
    );

    // Filter active users excluding the already selected viewers
    return this.all_active_users.filter(
      (user) =>
        !selectedViewerIds.has(user["id"]) &&
        (user["first_name"].toLowerCase().includes(filterValue) ||
          user["last_name"].toLowerCase().includes(filterValue))
    );
  }
}
