import {
  Input,
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
  Optional,
} from "@angular/core";
import { TalentService } from "../talent.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { Time } from "@angular/common";

@Component({
  selector: "app-shortlist",
  templateUrl: "./shortlist.component.html",
  styleUrls: ["./shortlist.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ShortlistComponent implements OnInit {
  @Input() filter: string;
  @Input() label: String;
  @Input() folder_id: number = 0;
  @Input() shortlistContextId: number = 0;
  @Input() title: string = "Shortlist Portfolio";
  @Input() shortlist = [];
  @Input() candidate_in_shortlist: Object = {};
  candidate_id: any;
  addToShortListContext = false;
  addShortlist = true;
  contexData: any;
  name: String;
  show_add_new_folder = false;
  show_add_new_shortlist = false;
  shortlistlabel;
  enabled = false;
  id;
  old_label;
  update_folder_label;
  update_shortlist_label;
  show_detail: Object = {};

  show_shortlist: Object = {};
  show_folder: Object = {};
  favorite_shortlist: Object = {};
  favorite_folder: Object = {};
  new_shortlist_label: String;
  new_folder_label: String;
  move_shortlist_id: Number = 0;
  constructor(
    private talentService: TalentService,
    @Optional() @Inject(MAT_DIALOG_DATA) data: any,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    if (data == null) {
      this.contexData = {
        shortlistContext: "shortlist",
      };
    } else {
      this.contexData = data;
      this.shortlistContextId = data.shortlistContextId;
      if (data.move_shortlist_id) {
        this.move_shortlist_id = data.move_shortlist_id;
      }
    }
  }

  ngOnInit() {
    if (this.shortlistContextId != 0) {
      this.addToShortListContext = true;
      this.addShortlist = false;
    }
    if (this.folder_id == 0) {
      this.getShortlist();
      this.candidate_id = this.shortlistContextId;
      this.getCandidateShortlists();
    }
  }

  getShortlist(): void {
    this.talentService.getShortlist().subscribe((shortlist) => {
      this.shortlist = shortlist.list;
      this.favorite_shortlist = shortlist.favorite_shortlist;
      this.favorite_folder = shortlist.favorite_folder;
      this.sortShortlist();
    });
  }
  sortShortlist() {
    this.shortlist = this.shortlist
      .filter((n) => {
        if (n.type == "folder") {
          return this.favorite_folder[n.id];
        } else {
          return this.favorite_shortlist[n.id];
        }
      })
      .concat(
        this.shortlist.filter((n) => {
          if (n.type == "folder") {
            return !this.favorite_folder[n.id];
          } else {
            return !this.favorite_shortlist[n.id];
          }
        })
      );

    if (this.move_shortlist_id != 0) {
      this.shortlist.unshift({
        id: 0,
        label: "/",
        type: "folder",
        children: [],
      });
    }
  }
  getCandidateShortlists(): void {
    if (this.candidate_id) {
      this.talentService
        .getCandidateShortlists(this.candidate_id)
        .subscribe((shortlists) => {
          shortlists.forEach(
            (shortlist_id) => (this.candidate_in_shortlist[shortlist_id] = true)
          );
        });
    }
  }

  saveShortlist() {
    this.talentService
      .isShortlistNameExists({
        label: this.new_shortlist_label,
        shortlist_type: "shortlist",
      })
      .subscribe((data) => {
        if (data) {
          alert("Shortlist name already exists");
        } else {
          let data = {
            label: this.new_shortlist_label,
            folder_id: this.folder_id,
          };
          this.talentService.createShortlist(data).subscribe((result) => {
            if (this.folder_id == 0) {
              this.getShortlist();
            } else {
              window.location.reload();
            }
          });
        }
      });
  }

  saveNewFolder() {
    this.talentService
      .isShortlistNameExists({
        label: this.new_folder_label,
        shortlist_type: "folder",
      })
      .subscribe((data) => {
        if (data) {
          alert("Shortlist name already exists");
        } else {
          let data = {
            label: this.new_folder_label,
            parent_id: this.folder_id,
          };
          this.talentService
            .createNewShortlistFolder(data)
            .subscribe((result) => {
              this.getShortlist();
              this.show_add_new_folder = false;
            });
        }
      });
  }

  addToShortList(label, id) {
    this.label = label;
    this.candidate_in_shortlist[id] = true;
    this.talentService
      .addCandidateToShortlist(this.label, this.shortlistContextId)
      .subscribe((result) => {
        this.getShortlist();
      });
  }

  edit(id) {
    this.enabled = true;
    this.id = id;
  }

  Cancel() {
    this.enabled = false;
    this.show_add_new_folder = false;
    this.show_add_new_shortlist = false;
  }

  editShortlist(old_label) {
    this.talentService
      .isShortlistNameExists({
        label: this.update_shortlist_label,
        shortlist_type: "shortlist",
      })
      .subscribe((data) => {
        if (data) {
          alert("Shortlist name already exists");
        } else {
          this.enabled = false;
          this.old_label = old_label;
          this.talentService
            .updateShortlistName(this.old_label, this.update_shortlist_label)
            .subscribe((result) => {
              if (this.folder_id == 0) {
                this.getShortlist();
              } else {
                let objIndex = this.shortlist.findIndex(
                  (s) => s.label == old_label
                );
                this.shortlist[objIndex].label = this.update_shortlist_label;
              }
              this.update_shortlist_label = "";
            });
        }
      });
  }

  editShortlistFolderInfo(id) {
    this.talentService
      .isShortlistNameExists({
        label: this.update_folder_label,
        shortlist_type: "folder",
      })
      .subscribe((data) => {
        if (data) {
          alert("Folder name already exists");
        } else {
          console.log(this.update_folder_label);
          this.enabled = false;
          this.talentService
            .editShortlistFolderInfo({
              id: id,
              label: this.update_folder_label,
            })
            .subscribe((result) => {
              this.getShortlist();
              this.update_folder_label = "";
            });
        }
      });
  }

  toggleShortlist(id): void {
    this.show_shortlist[id] = !this.show_shortlist[id];
  }
  toggleFolder(id): void {
    this.show_folder[id] = !this.show_folder[id];
  }

  // Type: 0 means shortlist, 1 means folder
  toggleFavoriteShortlist(id, type): void {
    let update_status = false;
    if (type == "folder") {
      this.favorite_folder[id] = !this.favorite_folder[id];
      update_status = this.favorite_folder[id];
    } else {
      this.favorite_shortlist[id] = !this.favorite_shortlist[id];
      update_status = this.favorite_shortlist[id];
    }
    this.talentService
      .updateFavoriteShortlist(id, update_status, type)
      .subscribe();
    this.sortShortlist();
  }

  showAddNewFolder() {
    this.show_add_new_folder = true;
    this.show_add_new_shortlist = false;
    this.new_folder_label = "";
  }

  showAddNewShortlist() {
    this.show_add_new_shortlist = true;
    this.show_add_new_folder = false;
    this.new_shortlist_label = "";
  }

  moveToFolder(shortlist_id) {
    let dialogRef = this.dialog.open(ShortlistComponent, {
      height: "600px",
      width: "800px",
      data: {
        shortlistContext: "addToShortlist",
        shortlistContextId: this.id,
        move_shortlist_id: shortlist_id,
        folder_id: 0,
      },
    });
  }

  moveShortlistToSelectedFolder(folder_id) {
    this.talentService
      .updateShortlistFolder({
        id: this.move_shortlist_id,
        folder_id: folder_id,
      })
      .subscribe((result) => {
        window.location.reload();
      });
  }
}
