import { ViewChild,Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TalentService } from '../talent.service';
import { PageEvent, MatPaginator, MatIconRegistry } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-role-recommendations',
  templateUrl: './role-recommendations.component.html',
  styleUrls: ['./role-recommendations.component.css']
})
export class RoleRecommendationsComponent implements OnInit {
  @Input() keywords:string = '';
  @Input() filter:string;
  candidates:any[];
  sortType: string = '';
  sortReverse: boolean = false;;
  show_filter:boolean = false;
  show_detail: Object = {};
  pdf_show: Object = {};
  active_candidates: any = {};
  length;
  safe_url;
  role_id;
  roles:any = [];
  search_context:string;
  role_title;
  client;
  change_color = [];
  pdf: any = {};
  filteredCandidates:any =[];
  show_advanced_filter: boolean = false;
  min_experience: number;
  max_experience: number;
  workingStatusOptions: any = [];
  selectedWorkingStatus: any = {[-1]: true};
  show_filter_button: boolean = false;
  jobPreferenceOptions: any = [];
  selectedJobPreference: any = {[-1]: true};
  itemsPerPage: number = 30;
  userSettings: any = {};
  lastViewedTimes: Object = {};
  recommendedCandidates = [];
  displayMessage: string;
  loadingRecommedations: boolean = true;
  isdisabled = [];
  isdisabled1= [];
  


  // MatPaginator Output
  pageEvent: PageEvent;



  constructor(iconRegistry: MatIconRegistry,private route: ActivatedRoute, private sanitizer: DomSanitizer, private talentService: TalentService) { 
    
    iconRegistry.addSvgIcon(
      'list',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/baseline-list-24px.svg'));
   

    iconRegistry.addSvgIcon(
      'add',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/baseline-add_circle_outline-24px.svg'));


    iconRegistry.addSvgIcon(
        'remove',
        sanitizer.bypassSecurityTrustResourceUrl('assets/images/baseline-remove_circle_outline-24px.svg'));
    
    this.role_id = +this.route.snapshot.paramMap.get('role_id');
    if (this.role_id){
      this.search_context='Role-Search';
      this.itemsPerPage = 10;
    }
    else {
      this.search_context = 'Generic-Search';
    }
    this.show_filter=true;
  }
  
  ngOnInit() {
    this.getRoleDetail(this.role_id);
    this.getCandidateLastViewedTimes();
    this.talentService.getUserSettings()
    .subscribe(result=>{
      this.userSettings = result;
    });
    // this.talentService.getRoleAddedOrRemovedCandidates(this.role_id)
    // .subscribe(result=>{
    //   result.added.forEach(n=>this.isdisabled[n]=true)
    //   result.removed.forEach(n=>this.isdisabled1[n]=true)
    // });
    this.loadingRecommedations = true;
    this.talentService.getRoleRecommendedCandidates(this.role_id)
    .subscribe(result=>{
      this.loadingRecommedations = false;
      if(typeof result === 'string'){
        this.displayMessage = result;
      }
      else{
        this.recommendedCandidates = result;
      }
    });
  } 

  getCandidateLastViewedTimes(): void {
    this.talentService.getCandidateLastViewedTimes().subscribe(times=>{
      this.lastViewedTimes = times;
    });
  }


  getRoleDetail(role_id): void {
    this.talentService.getRole(role_id).subscribe(role => { 
    this.role_title = role.title;
    this.client = role.client
});
}


  
  addToRole(candidate_id,role_id): void {
    this.isdisabled[candidate_id]=true;
    this.show_detail[candidate_id]=false;
    this.pdf_show[candidate_id]=false;
    var x = {candidate_id:candidate_id,role_details: {role_id, client: this.client}};
    this.talentService.addCandidateToRole(x).subscribe(result => {
      console.log(result);
    });
  }
  

  removeFromRole(candidate_id): void{
      this.isdisabled1[candidate_id]=true;
      this.show_detail[candidate_id]=false;
      this.pdf_show[candidate_id]=false;
      var role_id = this.role_id;
      this.talentService.removeCandidateFromRole(role_id,candidate_id).subscribe(result => { 
        console.log("result"+result);
      }) ;
  }

  detail_toggle(x): void {
    var id = x.id;
    this.show_detail[id] = !this.show_detail[id] ;
    console.log(id);
    console.log(this.show_detail[id]); 
    if(this.show_detail[id]){
      this.pdf_show[id]=false;
    }
    else{
      this.getCandidateLastViewedTimes();
    }
  }

  pdf_toggle(x): void {
    var id = x.id;
    // this.pdf_show[id]=!this.pdf_show[id];
    x.pdf_file_id="https://www.yodawe.com/pdf_viewer/web/viewer.html?file=/docs/resumes/"+x.file_id+"?"+x.filename;
    if(!this.pdf_show[id]){ 
      this.updateLastViewed(x.id);
      this.show_detail[id]=false;
      this.talentService.updateCandidateLastViewedTime(id)
      .subscribe(result=>console.log(result));
      this.talentService.getPDF(x.file_id).subscribe(result=>{
        this.pdf[x.id] = new Uint8Array(result);
        this.pdf_show[id]=!this.pdf_show[id];
      })
    }
    else{
      this.pdf_show[id]=!this.pdf_show[id];
    }
  }
  updateLastViewed(id){
    this.talentService.updateCandidateLastViewedTime(id)
    .subscribe(result=>this.getCandidateLastViewedTimes());
  }
  
  cleanURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  cleanURL2(x) {
    var url="https://www.yodawe.com/pdf_viewer/web/viewer.html?file=/docs/resumes/"+x.file_id+"?"+x.filename;
    this.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  

  receiveMessage($event) {
    console.log("received event"+ event);
    for(var i=0;i<this.candidates.length;i++){
      if(this.candidates[i]){
        if(this.candidates[i].id == $event.id) { // not the best but
            this.candidates[i].first_name=$event.first_name;
            this.candidates[i].last_name=$event.last_name;
            this.candidates[i].phone_mobile=$event.phone_mobile;
            this.candidates[i].job_preference=$event.job_preference;
            this.candidates[i].rating=$event.rating;
            console.log(this.candidates[i].rating);
            this.candidates[i].email_address = $event.email_address;
            this.candidates[i].primary_skill = $event.primary_skill_string;
            this.candidates[i].working_status = $event.working_status;
            this.candidates[i].comp_expectation= $event.comp_expectation; 
        }
      } 
    }
  }
}
