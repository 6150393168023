import { Inject, Optional, Component, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TalentService } from "../talent.service";
import { CommunicationViewHistoryComponent } from "../communication-view-history/communication-view-history.component";

@Component({
  selector: "app-communication-history",
  templateUrl: "./communication-history.component.html",
  styleUrls: ["./communication-history.component.css"],
})
export class CommunicationHistoryComponent implements OnInit {
  user_id;
  communications: any = [];
  communications_email_view: any = [];
  has_permission_view: boolean = true;
  is_loading: boolean = true;
  is_thread_view: boolean = false;

  // TODO: WHEN SES BACK, SET IT TO TRUR
  email_code_view: boolean = false;
  email_code: string[] = ["", "", "", ""];
  email_code_error: string = "";
  constructor(
    private dialog: MatDialog,
    @Optional()
    private dialogRef: MatDialogRef<CommunicationHistoryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    private talentService: TalentService
  ) {
    this.user_id = data.user_id;
  }

  ngOnInit() {
    this.talentService.hasVerifiedEmailCode().subscribe((result) => {
      if (result["status"] == true) {
        this.loadCommunications();
      } else {
        this.email_code_view = true;
        this.talentService.sendEmailCode().subscribe((result) => {
          console.log("Email Code Sent");
        });
        this.is_loading = false;
      }
    });
    // this.loadCommunications();
  }

  loadCommunications(): void {
    console.log("Load Communications");
    this.is_loading = true;
    this.email_code_view = false;
    this.talentService
      .getCommunicationHistory(this.user_id)
      .subscribe((result) => {
        let has_permission = 0;
        if ("has_permission" in result) {
          this.has_permission_view = false;
        } else {
          this.communications = result;
          for (let thread of this.communications) {
            for (let message of thread) {
              this.communications_email_view.push(message);
            }
          }
          this.communications_email_view.sort((a, b) => {
            return (
              new Date(b["time"]).getTime() - new Date(a["time"]).getTime()
            );
          });
          console.log(this.communications_email_view);
          has_permission = 1;
        }
        this.talentService
          .updateCommunicationViewHistory(this.user_id, has_permission)
          .subscribe((result) => {
            console.log("Updated Communication View History");
          });

        this.is_loading = false;
      });
  }

  onKeyup(index: number, event: KeyboardEvent): void {
    this.email_code_error = "";
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Convert to uppercase and allow only alphanumeric characters
    input.value = value.toUpperCase().replace(/[^A-Z0-9]/gi, "");

    if (event.key !== "Backspace") {
      // Clear subsequent inputs
      let nextInput = input.nextElementSibling as HTMLInputElement;
      while (nextInput) {
        nextInput.value = ""; // Clear the input
        nextInput = nextInput.nextElementSibling as HTMLInputElement;
      }

      // Move focus to the next input
      if (index < 3) {
        let focusInput = (event.target as HTMLElement)
          .nextElementSibling as HTMLElement;
        if (focusInput) {
          focusInput.focus();
        }
      } else {
        this.submitCode(); // Submit the code if it's the last input
      }
    } else {
      // Move focus to previous input if backspace and current is empty
      if (!this.email_code[index]) {
        let prevInput = (event.target as HTMLElement)
          .previousElementSibling as HTMLElement;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  }

  submitCode(): void {
    this.talentService
      .verifyEmailCode(this.email_code.join(""))
      .subscribe((result) => {
        if (result["status"] == true) {
          this.email_code_view = false;
          this.loadCommunications();
        } else {
          this.email_code_error = "Invalid Code. Please try again.";
        }
      });
  }

  communicationViewHistory() {
    let dialogRef = this.dialog.open(CommunicationViewHistoryComponent, {
      height: "650px",
      width: "700px",
      data: { user_id: this.user_id },
    });
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");
    if (pastedText && pastedText.length <= 4) {
      const inputs = document.getElementsByClassName(
        "verification-input"
      ) as HTMLCollectionOf<HTMLInputElement>;
      for (let i = 0; i < inputs.length && i < pastedText.length; i++) {
        inputs[i].value = pastedText[i].toUpperCase(); // Fill and convert to uppercase
        this.email_code[i] = pastedText[i].toUpperCase(); // Update the model if using ngModel
      }
      if (pastedText.length < 4) {
        inputs[pastedText.length].focus(); // Focus on the next input after the last pasted
      } else {
        this.submitCode(); // Submit if full code pasted
      }
    }
  }
}
