import { Component, OnInit, Input } from "@angular/core";
import { TalentService } from "../talent.service";
import imageUpload from "quill-plugin-image-upload";
import { environment } from "../../environments/environment";
import { Output, EventEmitter } from "@angular/core";
@Component({
  selector: "help-content",
  templateUrl: "./help-content.component.html",
  styleUrls: ["./help-content.component.css"],
})
export class HelpContentComponent implements OnInit {
  @Input() id: string;
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() reload: EventEmitter<any> = new EventEmitter();
  content = "";
  edit = false;
  editContent;
  parent_id;
  sub_title;
  editorModules = {
    syntax: false,
    toolbar: [
      ["bold", "italic", "underline"], //, 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],
      // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: ["black", "red", "#336699"] }], // { 'background': [] }],          // dropdown with defaults from theme
      // [{ 'font': [] }],
      // [{ 'align': [] }],

      ["clean"], // remove formatting button

      ["link", "image"], //, 'image', 'video']                         // link and image, video
    ],
    imageUpload: {
      upload: (file) => {
        // return a Promise that resolves in a link to the uploaded image
        return new Promise((resolve, reject) => {
          this.talentService
            .saveHelpImage(file)
            .subscribe((data) => resolve(environment.API_BASE + data));
        });
      },
    },
  };
  sub_section_file = [];
  new_files = [];
  hasContent = false;

  constructor(private talentService: TalentService) {}

  ngOnChanges(changes) {
    this.talentService
      .getHelpSubSectionById(changes.id.currentValue)
      .subscribe((result) => {
        if (result) {
          this.parent_id = result["parent_id"];
          this.sub_title = result["sub_title"];
          this.content = result.content;
          this.editContent = this.content;
          this.sub_section_file = result["sub_section_file"];
          this.hasContent = true;
        }
      });
  }
  ngOnInit() {}

  getHelpSection() {
    this.talentService.getHelpSubSectionById(this.id).subscribe((result) => {
      if (result) {
        this.parent_id = result["parent_id"];
        this.sub_title = result["sub_title"];
        this.content = result.content;
        this.editContent = this.content;
        this.sub_section_file = result["sub_section_file"];
        this.hasContent = true;
      }
    });
  }

  openEdit() {
    this.edit = true;
  }
  cancelEdit() {
    this.edit = false;
  }

  deleteThisPage() {
    this.remove.emit(this.id);
  }

  fileUpload(event) {
    let file_list = event.target.files;
    for (const item of file_list) {
      item["filename"] = item["name"];
      this.new_files.push(item);
    }
  }

  fileDrop(event) {
    for (const item of event) {
      item["filename"] = item["name"];
      this.new_files.push(item);
    }
  }

  removeFile(i) {
    this.new_files.splice(i, 1);
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  formatTime(lastModified) {
    let modified_date = new Date(lastModified);
    var curr_date = modified_date.getDate();
    var curr_month = modified_date.getMonth() + 1; //Months are zero based
    var curr_year = modified_date.getFullYear();
    return curr_year + "-" + curr_month + "-" + curr_date;
  }

  saveEdit() {
    this.content = this.editContent;
    this.edit = false;
    this.talentService
      .updateHelpSubSections({
        id: this.id,
        sub_title: this.sub_title,
        content: this.content,
        parent_id: this.parent_id,
      })
      .subscribe((res) => {
        this.reload.emit(null);
      });
    if (this.new_files.length !== 0) {
      let fileUploadFormData = new FormData();
      for (const file of this.new_files) {
        fileUploadFormData.append("file", file);
      }
      fileUploadFormData.append("sub_section_id", this.id);
      this.talentService.subSectionFileUpload(fileUploadFormData).subscribe(
        (res) => {
          this.getHelpSection();
          this.new_files = [];
        },
        (error) => {
          window.alert("Error saving Resume");
        },
        () => {
          this.new_files = [];
        }
      );
    }
  }
}
