import { TalentService } from '../talent.service';
import { Optional,Inject,Input,Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute,Params } from '@angular/router';
import 'rxjs/Rx';


@Component({
  selector: 'interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css']
})
export class InterviewComponent implements OnInit {
  
  topic;
  showme: Object ={};
  showcard;
  data;
  constructor(private talentService: TalentService,private route: ActivatedRoute) {}

  initial;
  x;

  ngOnInit() {
    this.route.params.subscribe((params: Params) => { 
    this.add=false;
    this.enabled=false;
    this.topic=params.topic;
    this.getQandA(this.topic);
    });
    
 }

  question;
  answer;
  id;
  index;
  currentindex;
  
  getQandA(topic){
    this.initial=true;
    this.topic=topic;
    this.talentService.getQandAByTopic(this.topic)
    .subscribe(data => { 
      this.currentindex=1;
      this.data=data;
      this.question=this.data.question;
      this.answer=this.data.answer;
      this.id=this.data.id;
      for(var i=0;i<this.data.length;i++){
        this.data[i].index=i+1;
        this.currentindex=this.data.length+1;
      }
      console.log(this.data);
    });
 
  }

  new_question;
  new_answer;
  doneMessage;

  save(id,question,answer){
    this.new_question=question;
    this.new_answer=answer;
    this.id=id;
    this.talentService.editQandA(id,this.new_question,this.new_answer)
      .subscribe( result => { 
        console.log("result"+result); 
        this.getQandA(this.topic);
      });
      this.enabled=false;
      this.initial=true;
      this.showme[id]=false;
  }


  addQandA(question,answer){
    this.question=question;
    this.answer=answer;
    this.talentService.addTopicQandA(this.topic,this.question,this.answer)
      .subscribe( result => { 
        console.log("result"+result); 
        this.getQandA(this.topic);
      });
      this.add=false;
  }
  
  add;

  show(){
    this.add=true;
  }

  enabled;


  edit(id){
    this.id=id;
    this.showme[id]=true;
    this.initial=false;
    this.enabled=true;
  }

  Cancel(id){
    this.enabled=false;  
    this.initial=true;
    this.showme[id]=false;
  }

  Close(){
    this.add=false;
  }

}