// import { Component, OnInit, Input } from "@angular/core";
import { TalentService } from "../talent.service";
// import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import {
  Component,
  ViewChild,
  EventEmitter,
  Output,
  OnInit,
  AfterViewInit,
  Input,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { FormGroup, FormBuilder } from "@angular/forms";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";
@Component({
  selector: "app-address-list",
  templateUrl: "./address-list.component.html",
  styleUrls: ["./address-list.component.css"],
})
export class AddressListComponent implements OnInit {
  @Input() create_new: boolean = false;
  @Output() select_primary = new EventEmitter<any>();
  @Input() selected_id: number = 0;
  selected_address: object;
  @Input() client_id: number;
  @Input() parent_view: string;

  address_list = [];
  show_new: boolean = false;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  formated_location: string;

  address_components: object = {
    label: "",
    comment: "",
    street: "",
    street_no: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  };

  constructor(private talentService: TalentService, public dialog: MatDialog) {}

  ngOnInit() {
    this.zoom = 12;
    this.latitude = 40.7551279;
    this.longitude = -73.9883636;
    // this.setCurrentPosition();
    this.getAddressList();
  }

  getAddressList() {
    this.talentService.getAddress(this.client_id).subscribe((res) => {
      this.address_list = res;
      for (let a of res) {
        if (a["id"] == this.selected_id) {
          this.selected_address = a;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.create_new) {
      this.getAddressList();
      this.selected_address = null;
    }
  }

  onAutocompleteSelected(result) {
    let label = this.address_components["label"];
    let comment = this.address_components["comment"];
    this.address_components = {
      label: label,
      comment: comment,
      street: "",
      street_no: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    };
    for (let i of result["address_components"]) {
      if (i.types.includes("street_number")) {
        this.address_components["street_no"] = i["short_name"];
      } else if (i.types.includes("route")) {
        this.address_components["street"] = i["short_name"];
      } else if (
        i.types.includes("locality") ||
        i.types.includes("postal_town")
      ) {
        this.address_components["city"] = i["short_name"];
      } else if (i.types.includes("administrative_area_level_1")) {
        this.address_components["state"] = i["short_name"];
      } else if (i.types.includes("country")) {
        this.address_components["country"] = i["short_name"];
      } else if (i.types.includes("postal_code")) {
        this.address_components["zipcode"] = i["short_name"];
      }
    }
  }

  onLocationSelected(location: Location) {
    this.latitude = location["latitude"];
    this.longitude = location["longitude"];
  }

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    }
  }

  clickNewAddress() {
    this.show_new = !this.show_new;
    this.latitude = 40.75512;
    this.longitude = -73.99065;
    this.zoom = 13;
    this.address_components = {
      label: "",
      comment: "",
      street: "",
      street_no: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    };
  }

  addNewAddress() {
    let validate = [];
    if (this.address_components["label"] == "") {
      validate.push("Label filed missing, please fille it");
    }
    if (
      !this.address_components["country"] &&
      !this.address_components["state"]
    ) {
      validate.push(
        "Address field missing, Please fill the Address in the first line"
      );
    }
    if (validate.length != 0) {
      let confirm_data = {};

      confirm_data["type"] = "validate";
      confirm_data["title"] =
        "Please fix the following issue(s) to create a new address";
      confirm_data["validate"] = validate;

      this.dialog.open(CustomDialogComponent, {
        maxWidth: "800px",
        data: confirm_data,
      });
    } else {
      if (this.address_components["label"]) {
        this.address_components["client_id"] = this.client_id;
        this.address_components["latitude"] = this.latitude;
        this.address_components["longitude"] = this.longitude;
        this.talentService
          .addAddress(this.address_components)
          .subscribe((res) => {
            this.address_list.unshift(res);
            if (this.selected_id == 0) {
              this.selected_id = res["id"];
              this.selected_address = res;
            }
          });
        this.clickNewAddress();
      }
    }
  }

  removeAddress(address_id, label) {
    let title = "Are you sure you want to remove this Address?";
    let message = "Label: " + label;
    let confirm_data = { title: title, message: message, type: "confirm" };
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      maxWidth: "600px",
      data: confirm_data,
    });

    dialogRef.afterClosed().subscribe((dialogRef) => {
      if (dialogRef) {
        let next_primary_address = this.selected_id;
        if (address_id == this.selected_id) {
          if (this.address_list.length > 1) {
            for (let a of this.address_list) {
              if (a.id != address_id) {
                next_primary_address = a.id;
                break;
              }
            }
          } else {
            next_primary_address = 0;
          }
        }

        let data = {
          address_id: address_id,
          next_primary_address: next_primary_address,
          client_id: this.client_id,
        };
        this.talentService.removeAddress(data).subscribe((res) => {
          this.address_list = this.address_list.filter(
            (item) => item.id != address_id
          );
          if (address_id == this.selected_id) {
            this.selected_id = next_primary_address;
            this.selected_address = null;
            for (let a of this.address_list) {
              if (a["id"] == this.selected_id) {
                this.selected_address = a;
              }
            }
          }
        });
      }
    });
  }

  select_star(address_id) {
    this.select_primary.emit({
      client_id: this.client_id,
      address_id: address_id,
    });

    this.selected_id = address_id;

    for (let a of this.address_list) {
      if (a["id"] == this.selected_id) {
        this.selected_address = a;
      }
    }
  }
}
