import { Inject, Optional, Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material";
import { TalentService } from "../talent.service";

@Component({
  selector: "app-workflow-history",
  templateUrl: "./workflow-history.component.html",
  styleUrls: ["./workflow-history.component.css"],
})
export class WorkflowHistoryComponent implements OnInit {
  wf_id: number;
  states: any = [];
  sortType: string = "last_update";
  sortReverse: boolean = true;

  constructor(
    @Optional() private dialogRef: MatDialogRef<WorkflowHistoryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    private talentService: TalentService
  ) {
    console.log(data);
    this.wf_id = data.wf_id;
  }

  ngOnInit() {
    this.talentService.getWorkflowStates(this.wf_id).subscribe((result) => {
      console.log(result);
      this.states = result;
    });
  }
}
