import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TalentService }  from '../talent.service';

@Component({
  selector: 'app-compose-email',
  templateUrl: './compose-email.component.html',
  styleUrls: ['./compose-email.component.css']
})
export class ComposeEmailComponent implements OnInit {
  body;
  to;
  cc;
  bcc;
  subject;
  attachments: any = [];

  constructor(
    public dialogRef: MatDialogRef<ComposeEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private talentService: TalentService)  { }

  ngOnInit() {
    this.to = this.data['to'];
  }
  sendEmail(){
    let emailFormData = new FormData();
    for (const file of this.attachments) {
      emailFormData.append("file", file);
    }
    emailFormData.append("message", this.body);
    emailFormData.append("to", this.to);
    emailFormData.append("cc", this.cc);
    emailFormData.append("bcc", this.bcc);
    emailFormData.append("subject", this.subject);
    emailFormData.append("text_type", 'html');
    emailFormData.append("candidate_id", this.data['candidate_id']);
    emailFormData.append("is_client_contact", this.data['is_client_contact']);
    // var emailData = {
    //   message: this.body,
    //   to: this.to,
    //   cc: this.cc,
    //   bcc: this.bcc,
    //   subject: this.subject,
    //   text_type: 'html',
    //   candidate_id: this.data['candidate_id'],
    // }
    this.talentService.sendGmail(emailFormData);
    this.dialogRef.close();
  }
  removeattachment(i): void {
    this.attachments.splice(i, 1);
  }
  attachmentUpload(event): void {
    let attachmentList = event.target.files;
    for (const item of attachmentList) {
      this.attachments.push(item);
    }
  }
  

}