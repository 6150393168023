import { Component, Input,OnInit, ViewEncapsulation,EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StarRatingComponent implements OnInit {

  @Input() max:number;
  @Input() ro:boolean;
  @Input() value:number;
  stars = [];
  constructor() { }

  ngOnInit() {
    for (var  i = 0; i < this.max; i++) {
        this.stars.push({filled: i < this.value});
     } 
  }
  @Output() private ratingEvent = new EventEmitter();
  
  ratingClick(rating:number) {
    console.log(rating);
    console.log("emitting event "+ rating);
    this.ratingEvent.emit(rating);
  }

  updateStars(val) {
    this.stars=[];
    for (var  i = 0; i < this.max; i++) {
        this.stars.push({filled: i < val+1});
     }
     this.ratingClick(val);
  }

}
