import {
  Output,
  Component,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
} from "@angular/core";
import { environment } from "../../environments/environment";
import { TalentService } from "../talent.service";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private talentService: TalentService) {}

  loginUser(code: string): void {
    this.talentService.loginUser(code).subscribe((result: any) => {
      if (result.status == "success") {
        localStorage.setItem("isLoggedIn", "Yes");
        localStorage.setItem("name", result.name);
        localStorage.setItem("user_id", result.user_id);
        localStorage.setItem("api_token", result.api_token);
        localStorage.setItem("account_id", result.account_id);
        localStorage.setItem("user_name", result.user_name);
        localStorage.setItem("account_name", result.account_name);
        localStorage.setItem("account_abbr", result.account_abbr);
        let is_admin = result.is_admin ? "true" : "false";
        localStorage.setItem("is_admin", is_admin);
        this.talentService.updateHttpOption();
        this.router.navigate(["/"]);
      } else {
        window.alert("User not authorized for this application");
        window.location.href = "/login";
      }
    });
  }

  ngOnInit() {
    console.log(this.router.url);
    if (this.router.url.includes("code=")) {
      console.log("do not go to cognito");
      var code = this.router.url.split("=")[1];
      console.log(code);
      this.loginUser(code);
    } else {
      console.log("go to " + environment.LOGIN_URI);
      window.location.href = environment.LOGIN_URI;
    }
  }

  @Output() loginEvent = new EventEmitter();

  sendLoggedInMessage() {
    console.log("emitting login event");
    this.loginEvent.emit(true);
  }
}
