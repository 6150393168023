import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Inject, Optional, Component, OnInit } from "@angular/core";

@Component({
  selector: "app-custom-dialog",
  templateUrl: "./custom-dialog.component.html",
  styleUrls: ["./custom-dialog.component.css"],
})
export class CustomDialogComponent implements OnInit {
  type: string;
  message: string;
  title: string;
  data: any;
  selection: any;
  input1: any;
  input2: any;
  input3: any;

  constructor(
    public dialogRef: MatDialogRef<CustomDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data
  ) {
    // Update view with given values

    this.title = data.title;
    this.type = data.type;
    this.message = data.message;
    this.data = data;
  }

  ngOnInit() {}

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  saveRoleClose(): void {
    let notes = "Closed - " + this.selection;
    if (this.selection == "Filled by SEQ") {
      notes +=
        "\n( Number of Placement: " +
        this.input1 +
        " / " +
        this.input2 +
        " ) \n( Selected Candidate(s): " +
        this.input3 +
        " )";
    }
    this.dialogRef.close(notes);
  }
}
