import { Inject, Optional, Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material";
import { TalentService } from "../../talent.service";
import { ActivityHistoryComponent } from "../../contact-detail/activity-history/activity-history.component";

@Component({
  selector: "app-todo-contact-activity-notification",
  templateUrl: "./todo-contact-activity-notification.component.html",
  styleUrls: ["./todo-contact-activity-notification.component.css"],
})
export class TodoContactActivityNotificationComponent implements OnInit {
  notification_list;
  sortType: string = "activity_time";
  sortReverse: boolean = true;
  constructor(
    private dialog: MatDialog,
    @Optional() public dialogRef: MatDialogRef<ActivityHistoryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    private talentService: TalentService
  ) {
    this.notification_list = data.notification_list;
  }

  ngOnInit() {}

  changeContactActivityNeedToFollow(id, current_follow_up) {
    let update_selection = 1;
    if (current_follow_up == 1) {
      update_selection = 0;
    }
    let data = { id: id, value: update_selection };
    this.talentService
      .updateContactActivityFollowUp(data)
      .subscribe((res) => this.getContactNotification());
  }

  getContactNotification() {
    this.talentService.getContactNotification().subscribe((res) => {
      this.notification_list = res;
    });
  }

  openActivityHistory(activity_id) {
    let history_dialog_ref = this.dialog.open(ActivityHistoryComponent, {
      height: "900px",
      width: "800px",
      data: {
        activity_id: activity_id,
      },
    });
    history_dialog_ref.afterClosed().subscribe((data) => {
      if (data.has_add) {
        this.getContactNotification();
      }
    });
  }
}
