import { Inject, Optional, Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material";
import { TalentService } from "../../talent.service";

@Component({
  selector: "app-activity-history",
  templateUrl: "./activity-history.component.html",
  styleUrls: ["./activity-history.component.css"],
})
export class ActivityHistoryComponent implements OnInit {
  activity_id: number;
  notes: any;
  new_note: string = "";
  has_add: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ActivityHistoryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    private talentService: TalentService
  ) {
    this.activity_id = data.activity_id;
  }

  ngOnInit() {
    this.dialogRef
      .beforeClosed()
      .subscribe(() => this.dialogRef.close({ has_add: this.has_add }));
    this.talentService
      .getContactActivityNotesHistory(this.activity_id)
      .subscribe((result) => {
        this.notes = result;
      });
  }

  addNewNotes() {
    if (this.new_note) {
      let data = { id: this.activity_id, note: this.new_note };
      this.talentService.addContactActivityNote(data).subscribe((result) => {
        this.notes.unshift(result);
        this.has_add = true;
        this.new_note = "";
      });
    }
  }
}
