import { Input, OnInit, Component } from "@angular/core";
import { TalentService } from "./talent.service";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { DropdownBasicComponent } from "./dropdown-basic/dropdown-basic.component";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActiveCandidatesComponent } from "./active-candidates/active-candidates.component";
import { AiSummaryComponent } from "./ai-summary/ai-summary.component";
import * as AWS from "aws-sdk";
import { environment } from "../environments/environment";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "Talent Portal";
  displayName = "";
  dialog;
  notificationCount;
  logImgPath = "assets/images/seq-logo.svg";
  is_admin: boolean = false;
  is_communication_admin: boolean = false; // User who can manage the communication setting
  mobileView: boolean = false;
  googleProfileImage = "assets/images/google-logo.png";
  // "assets/images/google-logo.png";

  constructor(
    iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public router: Router,
    private talentService: TalentService,
    private route: ActivatedRoute,
    public dialogRef: MatDialog
  ) {
    AWS.config.update({
      region: "us-east-1",
      accessKeyId: environment.AWS_COGNITO_ACCESS_ID,
      secretAccessKey: environment.AWS_COGNITO_SECRET_ACCESS_KEY,
    });

    iconRegistry.addSvgIcon(
      "list",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-list-24px.svg"
      )
    );

    iconRegistry.addSvgIcon(
      "edit",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-edit-24px.svg"
      )
    );

    iconRegistry.addSvgIcon(
      "add",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-add_circle_outline-24px.svg"
      )
    );

    iconRegistry.addSvgIcon(
      "remove",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-remove_circle_outline-24px.svg"
      )
    );

    iconRegistry.addSvgIcon(
      "save",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-save-24px.svg"
      )
    );

    iconRegistry.addSvgIcon(
      "pause",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-pause_circle_outline-24px.svg"
      )
    );
    iconRegistry.addSvgIcon(
      "search",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/ic_search_24px.svg"
      )
    );
    iconRegistry.addSvgIcon(
      "star_border",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-star_border-24px.svg"
      )
    );
    iconRegistry.addSvgIcon(
      "star",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-star-24px.svg"
      )
    );
    iconRegistry.addSvgIcon(
      "warning",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-warning-24px.svg"
      )
    );
    this.displayName = this.talentService.displayName;
  }

  navDashbaord() {
    this.router.navigateByUrl("/daily-candidate-summary");
  }

  navActiveCandidates() {
    this.router.navigateByUrl("/active-candidates");
  }

  navOpenRoles() {
    this.router.navigateByUrl("/open-roles");
  }

  navClients() {
    this.router.navigateByUrl("/clients");
  }

  navContacts() {
    this.router.navigateByUrl("/contacts");
  }

  navAISummary() {
    this.router.navigateByUrl("/ai-summary");
  }

  navSearch() {
    this.router.navigateByUrl("/search");
  }

  addnew() {
    this.router.navigateByUrl("/add-new");
  }

  teamroles() {
    this.router.navigateByUrl("/team-roles");
  }
  shortlist() {
    this.router.navigateByUrl("/shortlist");
  }
  help() {
    this.router.navigateByUrl("/help");
  }
  settings() {
    this.router.navigateByUrl("/user-settings");
  }
  signout() {
    this.router.navigateByUrl("/logout");
  }

  communicationSettings() {
    this.router.navigateByUrl("/communication-management");
  }

  manageUsers() {
    this.router.navigateByUrl("/user-management");
  }

  topic;

  Python() {
    this.router.navigateByUrl("/interview/Python");
  }
  Java() {
    this.router.navigateByUrl("/interview/Java");
  }
  Angular() {
    this.router.navigateByUrl("/interview/Angular");
  }
  React() {
    this.router.navigateByUrl("/interview/React");
  }
  DevOps() {
    this.router.navigateByUrl("/interview/DevOps");
  }

  ngOnInit() {
    if (window.screen.width < 600) {
      this.mobileView = true;
    }
    this.talentService.checkUserCommunicationAdmin().subscribe((res) => {
      this.is_communication_admin = res["is_communication_admin"];
    });
    this.is_admin = this.talentService.is_admin;

    this.talentService.notificationCount.subscribe((notificationCount) => {
      this.notificationCount = notificationCount;
    });
    this.getGoogleUser();
    this.getSrcImg();
  }

  isLoggedIn() {
    if (localStorage.getItem("isLoggedIn") == "Yes") {
      return true;
    } else {
      return false;
    }
  }

  notification(): void {
    let dialogRef = this.dialogRef.open(ActiveCandidatesComponent, {
      height: "600px",
      width: "1200px",
      data: { activeCandidatesContext: "fromNotification" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.talentService
        .getNotificationCount()
        .subscribe((notificationCount) => {
          this.notificationCount = notificationCount;
          console.log(
            "Notification Count After close the dialog",
            this.notificationCount
          );
        });
    });
  }

  ai_version = "gpt-4";
  aiSummary(): void {
    let aiSummaryDialogRef = this.dialogRef.open(AiSummaryComponent, {
      height: "70vh",
      width: "95vw",
      data: {
        myFn: this.testTimeout,
        ai_version: this.ai_version,
      },
    });
    setTimeout(() => {
      aiSummaryDialogRef.componentInstance.ai_version = "gpt-3.5-turbo";
    }, 2000);
  }

  testTimeout() {
    setTimeout(() => {
      alert("Test Timeout");
    }, 3000);
  }

  // Function Get the Logo Image Path
  getSrcImg() {
    this.talentService.getAccountInfo().subscribe((res) => {
      let abbr = res["abbr"];
      let logoImgPath = "assets/images/" + abbr + "/logo.png";
      this.logImgPath = logoImgPath;
    }); // return "assets/images/seq_logo.svg";
  }

  getGoogleUser() {
    // this.talentService.awaitGapiInit().then(() => {
    //   let currentUser = this.talentService.getCurrentGoogleUser();
    //   currentUser.then((user) => {
    //     this.googleProfileImage = user.getImageUrl();
    //     if (user) {
    //       console.log("Current User", user);
    //     } else {
    //       console.log("No Current User");
    //     }
    //   });

    //   // this.talentService.getOauthRefreshToken().subscribe((res) => {
    //   //   console.log("Refresh Token", res);
    //   // });
    // });
    this.talentService.needOAuthRefreshToken().subscribe((res) => {
      console.log("No need to refresh token", res["has_OAuth"]);
      if (res["has_OAuth"] == false) {
        this.talentService.getOauthRefreshToken();
      }
    });
  }
}
