import { Component, OnInit } from '@angular/core';
import RingCentralSDK from 'ringcentral';

@Component({
  selector: 'app-ring-central-auth',
  templateUrl: './ring-central-auth.component.html',
  styleUrls: ['./ring-central-auth.component.css']
})
export class RingCentralAuthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    RingCentralSDK.handleLoginRedirect();
  }

}
