import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ComposeEmailComponent } from '../compose-email/compose-email.component';

@Component({
  selector: 'candidate-email-button',
  templateUrl: './candidate-email-button.component.html',
  styleUrls: ['./candidate-email-button.component.css']
})
export class CandidateEmailButtonComponent implements OnInit {
  @Input() candidate: any;
  @Input() sm: boolean = false;
  @Input() is_client_contact:boolean=false;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  openEmailDialog(event){
    event.stopPropagation();
    this.dialog.open(ComposeEmailComponent, {
      height: '600px',
      width: '800px',
      data: {to:this.candidate.email_address, candidate_id: this.candidate.id,is_client_contact:this.is_client_contact},
    });
  }

}
