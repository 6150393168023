import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TalentService }  from '../talent.service';

@Component({
  selector: 'app-compose-text-message',
  templateUrl: './compose-text-message.component.html',
  styleUrls: ['./compose-text-message.component.css']
})
export class ComposeTextMessageComponent implements OnInit {
  to;
  body;

  constructor(
    public dialogRef: MatDialogRef<ComposeTextMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private talentService: TalentService) { }

  ngOnInit() {
    this.to = this.data['to'];
  }

  sendMessage(){
    this.talentService.ringCentralSendSMS(this.to,this.body,this.data['candidate_id']);
    this.dialogRef.close();
  }
}
