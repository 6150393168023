import { Component, OnInit } from "@angular/core";
import { TalentService } from "../talent.service";
import "chart.js";
import "chartjs-plugin-labels";
import { Sort } from "@angular/material/sort";
import "chartjs-plugin-datalabels";
import { ActiveCandidatesFilter } from "../custom.pipe";

@Component({
  selector: "app-daily-candidate-summary",
  templateUrl: "./daily-candidate-summary.component.html",
  styleUrls: ["./daily-candidate-summary.component.css"],
  providers: [ActiveCandidatesFilter],
})
export class DailyCandidateSummaryComponent implements OnInit {
  showDisplayCandidates: boolean = false;
  showRolesPipeline: boolean = false;
  dcs_pending_test_submission = [];
  dcs_pending_scheduling = [];
  dcs_phone_interviews = [];
  dcs_inhouse_interviews = [];
  dcs_awaiting_feedback = [];
  dcs_selected = [];
  dcs_prospect = [];
  dcs_left_message = [];
  rps_prospect = [];
  rps_resume_submitted = [];
  rps_phone_interview = [];
  rps_inhouse_interview = [];
  rps_awaiting_feedback = [];
  rps_pending_scheduling = [];
  rps_closed = [];
  rps_start_date = [];
  rps_role_id = [];
  rps = [];
  rps_reset = [];
  showGraph = 0;
  showRPSGraph = 0;
  pieChartLabels;
  pieChartData;
  pieChartType;
  pieChartOptions;
  pieChartLegend;
  pieChartColors;
  barChartData;
  barChartLabels;
  barChartOptions;
  barChartTotals: any = [];
  barChartType;
  barChartColors;
  show_pending_test_submission = false;
  show_pending_scheduling = false;
  show_phone_interviews = false;
  show_inhouse_interviews = false;
  show_awaiting_feedback = false;
  show_selected = true;
  show_prospect = false;
  show_left_message = false;
  roles;
  rolesObj = {};
  role_title;
  client;
  user_drop_down_data = [];
  nameId_DCS;
  nameId_RPS;
  myActiveCandidates: any = [];
  showRoles: any = [];
  sortedRoles: any = [];
  allRoles: any = [];
  show_detail: Object = {};
  show_role_candidates: Object = {};
  currentSort: Sort = { active: "days_since_open", direction: "asc" };
  showActivePipelines: boolean = true;
  showOpenRoles: boolean = true;
  page: number = 1;
  rowsPerPage: number = 100;
  dateFilterStart;
  dateFilterEnd;
  totalsList;
  contractChecked = true;
  permanentChecked = true;
  userWorkflow = [];
  roleFilter: string;
  roleCandidatesInitialFilter: string = undefined;
  pipelineCategories = [
    // {name:"Prospect",color:"#4CAF50"},
    { name: "Urgent Prospect", color: "#00acc1" },
    { name: "Recruiter Screen", color: "#808080" },
    { name: "Resume Submitted", color: "#424242" },
    { name: "First Interview", color: "#795548" },
    { name: "Second Interview", color: "#ff781e" },
    { name: "Additional Interview", color: "#fa4b74" },
    { name: "Selected", color: "#43719E" },
    { name: "Started", color: "#FFC107" },
    // {name:"Recruiter Screen",color:"#808080"},
    // {name:"Left Message - 2",color:"#424242"},
    // {name:"Left Message - 3",color:"#795548"},
    // {name:"Submitted",color:"#ff781e"},
    // {name:"Pending Scheduling",color:"#fa4b74"},
    // {name:"Phone Interview",color:"#43719E"},
    // {name:"Awaiting Feedback",color:"#FFC107"},
  ];

  startTime: any = new Date();
  endTime: any;

  public chartClicked(e: any, legendItem): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        // console.log(clickedElementIndex, label, value)
        if (label == "Pending Test Submission") {
          this.show_pending_test_submission = true;
          this.show_pending_scheduling = false;
          this.show_phone_interviews = false;
          this.show_inhouse_interviews = false;
          this.show_awaiting_feedback = false;
          this.show_selected = false;
          this.show_prospect = false;
          this.show_left_message = false;
        } else if (label == "Pending Scheduling") {
          this.show_pending_scheduling = true;
          this.show_pending_test_submission = false;
          this.show_phone_interviews = false;
          this.show_inhouse_interviews = false;
          this.show_awaiting_feedback = false;
          this.show_selected = false;
          this.show_prospect = false;
          this.show_left_message = false;
        } else if (label == "Phone Interview") {
          this.show_phone_interviews = true;
          this.show_pending_test_submission = false;
          this.show_pending_scheduling = false;
          this.show_inhouse_interviews = false;
          this.show_awaiting_feedback = false;
          this.show_selected = false;
          this.show_prospect = false;
          this.show_left_message = false;
        } else if (label == "InHouse Interview") {
          this.show_inhouse_interviews = true;
          this.show_pending_test_submission = false;
          this.show_pending_scheduling = false;
          this.show_phone_interviews = false;
          this.show_awaiting_feedback = false;
          this.show_selected = false;
          this.show_prospect = false;
          this.show_left_message = false;
        } else if (label == "Awaiting Feedback") {
          this.show_awaiting_feedback = true;
          this.show_pending_test_submission = false;
          this.show_pending_scheduling = false;
          this.show_phone_interviews = false;
          this.show_inhouse_interviews = false;
          this.show_selected = false;
          this.show_prospect = false;
          this.show_left_message = false;
        } else if (label == "Selected") {
          this.show_selected = true;
          this.show_pending_test_submission = false;
          this.show_pending_scheduling = false;
          this.show_phone_interviews = false;
          this.show_inhouse_interviews = false;
          this.show_awaiting_feedback = false;
          this.show_prospect = false;
          this.show_left_message = false;
        } else if (label == "Prospect") {
          this.show_selected = false;
          this.show_pending_test_submission = false;
          this.show_pending_scheduling = false;
          this.show_phone_interviews = false;
          this.show_inhouse_interviews = false;
          this.show_awaiting_feedback = false;
          this.show_prospect = true;
          this.show_left_message = false;
        } else if (label == "Left Message") {
          this.show_selected = false;
          this.show_pending_test_submission = false;
          this.show_pending_scheduling = false;
          this.show_phone_interviews = false;
          this.show_inhouse_interviews = false;
          this.show_awaiting_feedback = false;
          this.show_prospect = false;
          this.show_left_message = true;
        }
      }
    }
  }

  public chartHovered(e: any): void {}

  constructor(
    private talentService: TalentService,
    private filterPipe: ActiveCandidatesFilter
  ) {}

  ngOnInit() {
    this.talentService.getYodaweUsersData().subscribe((yodawe_users) => {
      this.user_drop_down_data = yodawe_users;
      // for (let x of yodawe_users){
      //   if (x['name'] == this.talentService.displayName){
      //     this.nameId_DCS = +x['id'];
      //     this.nameId_RPS = +x['id'];
      //     break;
      //   }
      //   else{
      //     this.nameId_DCS = 999;
      //     this.nameId_RPS = 999;
      //   }
      // }
      this.nameId_RPS = 999;
      if (this.nameId_RPS == 1 || this.nameId_RPS == 22) {
        this.nameId_RPS = 999;
      }
      if (this.nameId_DCS == 1) {
        this.nameId_DCS = 999;
      }
      // this.updateRolesPipelineTable();
    });
    this.startTime = new Date();

    this.talentService.getUserWorkflow().subscribe((workflow) => {
      this.userWorkflow = workflow;
      this.endTime = new Date();
      console.log("Loading workflow", this.startTime - this.endTime);
      this.displayRolesPipeline();
      this.rolesPipeline();
    });
  }

  getDailyCandidateData() {
    this.talentService.getWorkflowTotals().subscribe((totals) => {
      this.barChartTotals = totals;
    });
  }

  displayDailyCandidate() {
    this.getDailyCandidateData();
    this.dailyCandidate();
    this.showRolesPipeline = false;
    this.showDisplayCandidates = true;
  }

  displayRolesPipeline() {
    this.showDisplayCandidates = false;
    this.showRolesPipeline = true;
  }

  dailyCandidate() {
    let startTime2: any = new Date();
    this.talentService.getOpenRolesNew("all").subscribe((roles) => {
      this.roles = roles;
      for (let i of this.roles) {
        this.rolesObj[i["role_id"]] = i["title"];
      }

      this.talentService.getMyActiveCandidates().subscribe((resp) => {
        // console.log(resp);
        this.myActiveCandidates = Array.from(resp);
        this.filterDCSummary(resp);

        this.pieChartData = [
          this.dcs_pending_scheduling.length,
          this.dcs_phone_interviews.length,
          this.dcs_inhouse_interviews.length,
          this.dcs_pending_test_submission.length,
          this.dcs_awaiting_feedback.length,
          this.dcs_selected.length,
          this.dcs_prospect.length,
          this.dcs_left_message.length,
        ];
        this.showGraph = 1;
        this.pieChartLabels = [
          "Pending Scheduling",
          "Phone Interview",
          "InHouse Interview",
          "Pending Test Submission",
          "Awaiting Feedback",
          "Selected",
          "Prospect",
          "Left Message",
        ];
        this.pieChartType = "pie";
        this.pieChartOptions = {
          elements: { arc: { borderWidth: 0.2 } },
          legend: { position: "left" },
          plugins: {
            labels: {
              render: "value",
              position: "border",
              textMargin: 4,
              fontColor: "#ffffff",
              fontSize: 15,
            },
            datalabels: { display: false },
          },
        };
        this.pieChartLegend = true;
        this.pieChartColors = [
          {
            backgroundColor: [
              "#ff781e",
              "#FF6600",
              "#db5902",
              "#43719e",
              "#1f66ad",
              "#0050a0",
              "lightgray",
              "gray",
            ],
          },
        ];
        // this.pieChartColors = [{backgroundColor: ["#00b2ff", "#00ffcb", "#00ff3f",'#b2ff00', "#FF6600", "#ff00aa"]}];
      });
      let endTime2: any = new Date();
      console.log("Get Daily Candidate", endTime2 - startTime2);
    });
  }

  rolesPipeline() {
    let startTime3: any = new Date();
    let loadAllRoles = "open";
    if (!this.showOpenRoles) {
      loadAllRoles = "all";
    }
    this.talentService
      .getRolesPipelineSummary(loadAllRoles)
      .subscribe((resp) => {
        this.allRoles = Array.from(resp["roles"]);
        this.allRoles = this.allRoles.map((role) => {
          {
            let usermap = [];
            this.userWorkflow.map((entry) => {
              if (entry.role_id == role.role_id) {
                usermap.push({
                  id: entry.user_id,
                  last_update: entry.last_update,
                });
              }
            });
            return { ...role, user: usermap };
          }
        });

        if (this.nameId_RPS !== 999) {
          this.showRoles = this.allRoles.filter((n) => {
            return n.users.includes(this.nameId_RPS);
          });
        } else {
          this.showRoles = this.allRoles;
        }
        if (this.showOpenRoles) {
          this.showRoles = this.showRoles.filter((n) => n.open);
        }
        if (this.showActivePipelines) {
          this.showRoles = this.showRoles.map((n) => {
            return {
              ...n,
              show_pipelines: n.total_pipelines,
              show_pipeline_summary: n.active_pipeline_summary,
            };
          });
        } else {
          this.showRoles = this.showRoles.map((n) => {
            return {
              ...n,
              show_pipelines: n.total_pipelines,
              show_pipeline_summary: n.pipeline_summary,
            };
          });
        }
        // this.sortedRoles = this.showRoles.slice();
        this.sortRoles();

        var outer_this = this;
        let totals = this.barChartTotals;

        this.barChartOptions = {
          onClick: function (e) {
            var element = this.getElementAtEvent(e);
            if (element.length) {
              outer_this.toggle_show_role_candidates(
                e.role_id,
                element[0]._datasetIndex
              );
            } else {
              outer_this.toggle_show_role_candidates(e.role_id);
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false,
                gridLines: {
                  drawBorder: false,
                  display: false,
                },
                stacked: true,
                ticks: {
                  // max : Math.max.apply(Math,this.sortedRoles.map(n=>n.show_pipelines).slice((this.page-1) * this.rowsPerPage, this.page * this.rowsPerPage)),
                  // min: 0,
                  display: true,
                  beginAtZero: true,
                  autoSkip: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  display: false,
                },
                stacked: true,
              },
            ],
          },
          tooltips: {
            mode: "single",
            callbacks: {
              title: function () {},
              label: function (tooltipItem, data) {
                if (data.datasets[tooltipItem.datasetIndex].label == "Reset") {
                  return 0;
                } else {
                  return (
                    data.datasets[tooltipItem.datasetIndex].label +
                    ": " +
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ] +
                    " | " +
                    data.datasets[tooltipItem.datasetIndex].summary
                  );
                }
              },
            },
          },
          legend: {
            display: false,
          },
          plugins: {
            datalabels: {
              color: "white",

              // color: function(context) {
              //   var color = context.dataset.backgroundColor;
              //   return (parseInt(color.substring(1,3), 16) * 0.299 + parseInt(color.substring(3,5), 16) * 0.587 + parseInt(color.substring(5,7), 16) *0.114) > 155 ? 'black':'white'
              // },
              display: function (context) {
                return (
                  (context.dataset.data[context.dataIndex] /
                    context.dataset.total) *
                    100 >
                  5
                );
              },
              font: {
                weight: "bold",
                size: 10,
              },
              formatter: function (value, context) {
                if (value !== 0) {
                  if (context.dataset.label == "Recruiter Screen") {
                    return value + " | " + context.dataset.summary;
                  } else if (context.dataset.label == "Resume Submitted") {
                    return value + " | " + context.dataset.summary;
                  } else if (context.dataset.label == "First Interview") {
                    return value + " | " + context.dataset.summary;
                  } else if (context.dataset.label == "Second Interview") {
                    return value + " | " + context.dataset.summary;
                  } else if (context.dataset.label == "Additional Interview") {
                    return value + " | " + context.dataset.summary;
                  } else if (context.dataset.label == "Selected") {
                    return value + " | " + context.dataset.summary;
                  } else if (context.dataset.label == "Started") {
                    return value + " | " + context.dataset.summary;
                  } else if (context.dataset.label == "Prospect") {
                    return value + " | " + context.dataset.summary;
                  } else if (context.dataset.label == "Urgent Prospect") {
                    return value + " | " + context.dataset.summary;
                  } else {
                    return value;
                  }
                } else {
                  return "";
                }
              },
            },
          },
        };
        this.barChartType = "horizontalBar";
        this.barChartLabels = [Array.from(this.rps_role_id)];
        this.showRPSGraph = 1;
        this.barChartColors = [
          { backgroundColor: "#00acc1" },
          { backgroundColor: "#808080" },
          { backgroundColor: "#424242" },
          { backgroundColor: "#795548" },
          { backgroundColor: "#ff781e" },
          { backgroundColor: "#fa4b74" },
          { backgroundColor: "#43719E" },
          { backgroundColor: "#FFC107" },
        ];

        let endTime3: any = new Date();
        console.log("Loading Roles Pipelines ", endTime3 - startTime3);
      });
  }

  selectUserDCS() {
    this.dcs_pending_test_submission = [];
    this.dcs_pending_scheduling = [];
    this.dcs_phone_interviews = [];
    this.dcs_inhouse_interviews = [];
    this.dcs_awaiting_feedback = [];
    this.dcs_selected = [];
    this.dcs_prospect = [];
    this.dcs_left_message = [];
    this.filterDCSummary(this.myActiveCandidates);
    this.pieChartData = [
      this.dcs_pending_scheduling.length,
      this.dcs_phone_interviews.length,
      this.dcs_inhouse_interviews.length,
      this.dcs_pending_test_submission.length,
      this.dcs_awaiting_feedback.length,
      this.dcs_selected.length,
      this.dcs_prospect.length,
      this.dcs_left_message.length,
    ];
    this.showGraph = 1;
    this.pieChartLabels = [
      "Pending Scheduling",
      "Phone Interview",
      "InHouse Interview",
      "Pending Test Submission",
      "Awaiting Feedback",
      "Selected",
      "Prospect",
      "Left Message",
    ];
    this.pieChartType = "pie";
    this.pieChartOptions = {
      elements: { arc: { borderWidth: 0.2 } },
      legend: { position: "left" },
      plugins: {
        labels: {
          render: "value",
          position: "border",
          textMargin: 4,
          fontColor: "#ffffff",
          fontSize: 15,
        },
      },
    };
    this.pieChartLegend = true;
    this.pieChartColors = [
      {
        backgroundColor: [
          "#ff781e",
          "#FF6600",
          "#db5902",
          "#43719e",
          "#1f66ad",
          "#0050a0",
          "lightgray",
          "gray",
        ],
      },
    ];
    // this.pieChartColors = [{backgroundColor: ["#00b2ff", "#00ffcb", "#00ff3f",'#b2ff00', "#FF6600", "#ff00aa"]}];
  }

  toggleOpenRoles() {
    if (!this.showOpenRoles) {
      this.rolesPipeline();
    }
  }

  updateRolesPipelineTable() {
    // console.log(this.allRoles);
    if (this.nameId_RPS !== 999) {
      this.showRoles = this.allRoles.filter((n) => {
        return n.user.some((n) => {
          return n.id == this.nameId_RPS;
        });
      });
    } else {
      this.showRoles = this.allRoles;
    }
    if (this.showOpenRoles) {
      this.showRoles = this.showRoles.filter((n) => n.open);
    }
    if (this.showActivePipelines) {
      this.showRoles = this.showRoles.map((n) => {
        return {
          ...n,
          show_pipelines: n.total_pipelines,
          show_pipeline_summary: n.active_pipeline_summary,
        };
      });
    } else {
      this.showRoles = this.showRoles.map((n) => {
        return {
          ...n,
          show_pipelines: n.total_pipelines,
          show_pipeline_summary: n.pipeline_summary,
        };
      });
    }
    if (this.dateFilterStart && this.nameId_RPS !== 999) {
      this.showRoles = this.showRoles.filter((role) => {
        return (
          role.user.filter((n) => {
            let d1 = new Date(n.last_update).toISOString().slice(0, 10);
            let d2 = new Date(this.dateFilterStart).toISOString().slice(0, 10);
            return d1 >= d2 && n.id == this.nameId_RPS;
          }).length > 0
        );
      });
      // this.showRoles = this.showRoles.filter((role)=>{
      //   let d1 = new Date(role.open_date)
      //   let d2 = new Date (this.dateFilterStart)
      //   return d1 >= d2
      // })
    }
    if (this.dateFilterEnd && this.nameId_RPS !== 999) {
      this.showRoles = this.showRoles.filter((role) => {
        return (
          role.user.filter((n) => {
            let d1 = new Date(n.last_update).toISOString().slice(0, 10);
            let d2 = new Date(this.dateFilterEnd).toISOString().slice(0, 10);
            return d1 <= d2 && n.id == this.nameId_RPS;
          }).length > 0
        );
      });
    }
    if (this.contractChecked && this.permanentChecked) {
      this.showRoles = this.showRoles;
    } else if (this.contractChecked) {
      this.showRoles = this.showRoles.filter((role) => {
        return role.type_string == "Contract";
      });
    } else if (this.permanentChecked) {
      this.showRoles = this.showRoles.filter((role) => {
        return role.type_string == "Permanent";
      });
    } else {
      this.showRoles = [];
    }

    this.showRoles = this.filterPipe.transform(this.showRoles, this.roleFilter);

    this.sortRoles();
    this.updateBarChart();
  }
  updateBarChart() {
    var outer_this = this;
    let totals = this.barChartTotals;
    this.barChartOptions = {
      onClick: function (e) {
        var element = this.getElementAtEvent(e);
        if (element.length) {
          outer_this.toggle_show_role_candidates(
            e.role_id,
            element[0]._datasetIndex
          );
        } else {
          outer_this.toggle_show_role_candidates(e.role_id);
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            display: false,
            gridLines: {
              drawBorder: false,
              display: false,
            },
            stacked: true,
            ticks: {
              // max : Math.max.apply(Math,this.sortedRoles.map(n=>n.show_pipelines).slice((this.page-1) * this.rowsPerPage, this.page * this.rowsPerPage)),
              // min : 0
              beginAtZero: true,
              autoSkip: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              display: false,
            },
            stacked: true,
          },
        ],
      },
      tooltips: {
        mode: "single",
        callbacks: {
          title: function () {},
          label: function (tooltipItem, data) {
            if (data.datasets[tooltipItem.datasetIndex].label == "Reset") {
              return 0;
            } else {
              return (
                data.datasets[tooltipItem.datasetIndex].label +
                ": " +
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] +
                " | " +
                data.datasets[tooltipItem.datasetIndex].summary
              );
            }
          },
        },
      },
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          color: "white",
          // color: function(context) {
          //   var color = context.dataset.backgroundColor;
          //   return (parseInt(color.substring(1,3), 16) * 0.299 + parseInt(color.substring(3,5), 16) * 0.587 + parseInt(color.substring(5,7), 16) *0.114) > 155 ? 'black':'white'
          // },
          display: function (context) {
            return (
              (context.dataset.data[context.dataIndex] /
                context.dataset.total) *
                100 >
              5
            );
          },
          font: {
            weight: "bold",
            size: 10,
          },
          formatter: function (value, context) {
            if (value !== 0) {
              if (context.dataset.label == "Recruiter Screen") {
                return value + " | " + context.dataset.summary;
              } else if (context.dataset.label == "Resume Submitted") {
                return value + " | " + context.dataset.summary;
              } else if (context.dataset.label == "First Interview") {
                return value + " | " + context.dataset.summary;
              } else if (context.dataset.label == "Second Interview") {
                return value + " | " + context.dataset.summary;
              } else if (context.dataset.label == "Additional Interview") {
                return value + " | " + context.dataset.summary;
              } else if (context.dataset.label == "Selected") {
                return value + " | " + context.dataset.summary;
              } else if (context.dataset.label == "Started") {
                return value + " | " + context.dataset.summary;
              } else if (context.dataset.label == "Prospect") {
                return value + " | " + context.dataset.summary;
              } else if (context.dataset.label == "Urgent Prospect") {
                return value + " | " + context.dataset.summary;
              } else {
                return value;
              }
            } else {
              return "";
            }
          },
        },
      },
    };
  }
  filterDCSummary(candidate_list) {
    for (let x of candidate_list) {
      if (this.nameId_DCS != 999 || this.nameId_DCS == 1) {
        if (x["user_id"].indexOf(this.nameId_DCS) > -1) {
          for (let y of x["workflow"]) {
            if (y["latest_status"] == "Pending Scheduling") {
              this.dcs_pending_scheduling.push({
                id: x["id"],
                client: y["wf_client"],
                first_name: x["first_name"],
                last_name: x["last_name"],
                notes: y["wf_notes"],
                role_id: this.rolesObj[y["wf_role_id"]],
              });
            } else if (y["latest_status"] == "Written Test Pending") {
              this.dcs_pending_test_submission.push({
                id: x["id"],
                client: y["wf_client"],
                first_name: x["first_name"],
                last_name: x["last_name"],
                notes: y["wf_notes"],
                role_id: this.rolesObj[y["wf_role_id"]],
              });
            } else if (y["latest_status"] == "Phone Interview") {
              this.dcs_phone_interviews.push({
                id: x["id"],
                client: y["wf_client"],
                first_name: x["first_name"],
                last_name: x["last_name"],
                notes: y["wf_notes"],
                role_id: this.rolesObj[y["wf_role_id"]],
              });
            } else if (y["latest_status"] == "In-house Interview") {
              this.dcs_inhouse_interviews.push({
                id: x["id"],
                client: y["wf_client"],
                first_name: x["first_name"],
                last_name: x["last_name"],
                notes: y["wf_notes"],
                role_id: this.rolesObj[y["wf_role_id"]],
              });
            } else if (y["latest_status"] == "Awaiting Feedback") {
              this.dcs_awaiting_feedback.push({
                id: x["id"],
                client: y["wf_client"],
                first_name: x["first_name"],
                last_name: x["last_name"],
                notes: y["wf_notes"],
                role_id: this.rolesObj[y["wf_role_id"]],
              });
            } else if (
              y["latest_status"] == "Selected" ||
              y["latest_status"] == "Start Date"
            ) {
              this.dcs_selected.push({
                id: x["id"],
                client: y["wf_client"],
                first_name: x["first_name"],
                last_name: x["last_name"],
                latest_status: y["latest_status"],
                notes: y["wf_notes"],
                role_id: this.rolesObj[y["wf_role_id"]],
              });
            } else if (
              y["latest_status"] == "Prospect" ||
              y["latest_status"] == "Urgent Prospect"
            ) {
              this.dcs_prospect.push({
                id: x["id"],
                client: y["wf_client"],
                first_name: x["first_name"],
                last_name: x["last_name"],
                latest_status: y["latest_status"],
                notes: y["wf_notes"],
                role_id: this.rolesObj[y["wf_role_id"]],
              });
            } else if (
              y["latest_status"] == "Left Message - 1" ||
              y["latest_status"] == "Left Message - 2" ||
              y["latest_status"] == "Left Message - 3"
            ) {
              this.dcs_left_message.push({
                id: x["id"],
                client: y["wf_client"],
                first_name: x["first_name"],
                last_name: x["last_name"],
                latest_status: y["latest_status"],
                notes: y["wf_notes"],
                role_id: this.rolesObj[y["wf_role_id"]],
              });
            }
          }
        }
      } else {
        for (let y of x["workflow"]) {
          if (y["latest_status"] == "Pending Scheduling") {
            this.dcs_pending_scheduling.push({
              id: x["id"],
              client: y["wf_client"],
              first_name: x["first_name"],
              last_name: x["last_name"],
              notes: y["wf_notes"],
              role_id: this.rolesObj[y["wf_role_id"]],
            });
          } else if (y["latest_status"] == "Written Test Pending") {
            this.dcs_pending_test_submission.push({
              id: x["id"],
              client: y["wf_client"],
              first_name: x["first_name"],
              last_name: x["last_name"],
              notes: y["wf_notes"],
              role_id: this.rolesObj[y["wf_role_id"]],
            });
          } else if (y["latest_status"] == "Phone Interview") {
            this.dcs_phone_interviews.push({
              id: x["id"],
              client: y["wf_client"],
              first_name: x["first_name"],
              last_name: x["last_name"],
              notes: y["wf_notes"],
              role_id: this.rolesObj[y["wf_role_id"]],
            });
          } else if (y["latest_status"] == "In-house Interview") {
            this.dcs_inhouse_interviews.push({
              id: x["id"],
              client: y["wf_client"],
              first_name: x["first_name"],
              last_name: x["last_name"],
              notes: y["wf_notes"],
              role_id: this.rolesObj[y["wf_role_id"]],
            });
          } else if (y["latest_status"] == "Awaiting Feedback") {
            this.dcs_awaiting_feedback.push({
              id: x["id"],
              client: y["wf_client"],
              first_name: x["first_name"],
              last_name: x["last_name"],
              notes: y["wf_notes"],
              role_id: this.rolesObj[y["wf_role_id"]],
            });
          } else if (
            y["latest_status"] == "Selected" ||
            y["latest_status"] == "Start Date"
          ) {
            this.dcs_selected.push({
              id: x["id"],
              client: y["wf_client"],
              first_name: x["first_name"],
              last_name: x["last_name"],
              latest_status: y["latest_status"],
              notes: y["wf_notes"],
              role_id: this.rolesObj[y["wf_role_id"]],
            });
          } else if (
            y["latest_status"] == "Prospect" ||
            y["latest_status"] == "Urgent Prospect"
          ) {
            this.dcs_prospect.push({
              id: x["id"],
              client: y["wf_client"],
              first_name: x["first_name"],
              last_name: x["last_name"],
              latest_status: y["latest_status"],
              notes: y["wf_notes"],
              role_id: this.rolesObj[y["wf_role_id"]],
            });
          } else if (
            y["latest_status"] == "Left Message - 1" ||
            y["latest_status"] == "Left Message - 2" ||
            y["latest_status"] == "Left Message - 3"
          ) {
            this.dcs_left_message.push({
              id: x["id"],
              client: y["wf_client"],
              first_name: x["first_name"],
              last_name: x["last_name"],
              latest_status: y["latest_status"],
              notes: y["wf_notes"],
              role_id: this.rolesObj[y["wf_role_id"]],
            });
          }
        }
      }
    }
  }
  detail_toggle(id): void {
    this.show_detail[id] = !this.show_detail[id];
    if (this.show_detail[id]) {
      this.show_role_candidates[id] = false;
    }
  }
  toggle_show_role_candidates(role_id, initialFilter = undefined) {
    const filters = [
      "Urgent Prospect",
      "Recruiter Screen",
      "Resume Submitted",
      "First Interview",
      "Second Interview",
      "Additional Interview",
      "Selected",
      "Started",
    ];
    this.roleCandidatesInitialFilter = filters[initialFilter];
    this.show_role_candidates[role_id] = !this.show_role_candidates[role_id];
    if (this.show_role_candidates[role_id]) {
      this.show_detail[role_id] = false;
    }
    // window.open("/my-roles/savedCandidates/"+e,'_blank');
    // if (e.active.length > 0) {
    //   const chart = e.active[0]._chart;
    //   const activePoints = chart.getElementAtEvent(e.event);
    //   if ( activePoints.length > 0) {
    //     // get the internal index of slice in pie chart
    //     const clickedElementIndex = activePoints[0]._index;
    //     const label1 = chart.data.labels[clickedElementIndex];
    //     // get value by index
    //     // const value1 = chart.data.datasets[0].data[clickedElementIndex];
    //     var xyz = label1.split(",");
    //     var abc = (xyz[0]).replace(/ /g,'');
    //     window.open("/my-roles/savedCandidates/"+abc,'_blank');
    //   }
    // }
  }
  changePage(e: any) {
    this.page = e;
    this.updateRolesPipelineTable();
  }
  updateSort(sort: Sort) {
    this.currentSort = sort;
    this.updateRolesPipelineTable();
  }
  sortRoles() {
    const data = this.showRoles.slice();
    if (!this.currentSort.active || this.currentSort.direction === "") {
      this.sortedRoles = data;
      return;
    }

    this.sortedRoles = data.sort((a, b) => {
      const isAsc = this.currentSort.direction === "asc";
      switch (this.currentSort.active) {
        case "priority":
          return compare(a.priority, b.priority, isAsc);
        case "show_pipelines":
          return compare(a.show_pipelines, b.show_pipelines, isAsc);
        case "days_since_open":
          return compare(a.days_since_open, b.days_since_open, isAsc);
        case "practice_group":
          return compare(a.practice_group, b.practice_group, isAsc);
        default:
          return 0;
      }
    });
  }
  addRoleIdToEvent(e, role_id) {
    e.role_id = role_id;
  }

  ngOnDestroy() {}
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  if (a === b) return 0;
  if (a === null) return 1;
  if (b === null) return -1;
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
