import { Component, OnInit, ElementRef,ViewChild } from '@angular/core';
import {trigger, animate, state, style, transition} from '@angular/animations';
import { TalentService } from '../talent.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
  animations: [
    trigger('bodyExpansion', [
      state('collapsed', style({height: '0px', display: 'none'})),
      state('expanded', style({height: '*', display: 'block'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),]
})
export class HelpComponent implements OnInit {

  expansions: {[key: string]: boolean} = {};
  categories: any =  [{id: 'developers', 
    name: 'Developers',
    items: [{id: 'frontend', name: 'Front End Developers'},
      {id: 'backend', name: 'Back End Developers'},
      {id: 'frontendvsbackend', name: 'Front End vs. Back End'},
      {id: 'fullstack', name: 'Full Stack Developers'},
    ]
  },{id: 'developers_advanced', 
    name: 'Developers - Advanced',
    items: [{id: 'productionsupport', name: 'Production Support'},
      {id: 'devops', name: 'DevOps'},
      {id: 'businessanalysts', name: 'Business Analysts'},
      {id: 'pmtypes', name: 'Project vs Product vs Program'},
    ]
  },{id: 'sponsorship_offer', 
    name: 'Offers and Sponsorship',
    items: [{id: 'contracts', name: 'Types of Contracts'},
      {id: 'sponsorship', name: 'Sponsorship Process/Visas'},
    ]
  },
  {id: "pre_post_interview", 
  name: "Pre & Post Interview",
  items: [
  {id: 'comingsoon', name: 'Coming Soon'},]
  },
  {id: 'clients', 
    name: 'Clients',
    items: [{id: 'seqclients', name: 'SEQ Clients'},
      {id: 'buyvssell', name: 'Buy vs Sell Side'},
      {id: 'hedgefund', name: 'Hedge Funds'},
      {id: 'clientstories', name: 'Client "Short Stories"'},
      {id: 'partnersvendors', name: 'Partners and Vendors'},
    ]
  },{id: 'seq', 
    name: 'SEQ',
    items: [{id: 'candidatelifecycle', name: 'Candidate Lifecycle'},
      {id: 'bestpractices', name: 'Best Practices'},
      {id: 'yodawe', name: 'Yodawe'},
    ]
  },{id: 'glossary', 
    name: 'Glossary',
    items: [{id: 'finance', name: 'Finance'},
    ]
  },
  ]
  selectedId = 1
  sections = [];
  subSectionTitles:any[] = [];
  newSectionName = "";
  showNewSectionInput = false;
  newSubTitle = "";
  showNewSubInput = 0;
  @ViewChild('input1', {static: false}) inputSection: ElementRef;

  constructor(private talentService: TalentService, private dialog: MatDialog) { 
    
  }

  ngOnInit() {
    this.talentService.getHelpSections().subscribe(res => {
      this.sections = res
    });

    this.talentService.getHelpSubSectionTitles().subscribe(titles => {
      this.subSectionTitles = titles;
    });
  }

  setSelectedPage(id){
    this.selectedId = id;
  }

  filterSubtitle(parent_id) {
    let res = this.subSectionTitles.filter(n => n["parent_id"] === parent_id)
    return res
  }

  saveSections() {
    if (this.newSectionName != "") {
      if (this.sections.includes(this.newSectionName)) {
        // TODO: add error 
        console.log("Error")
      }
      this.talentService.addHelpSections(this.newSectionName).subscribe(res =>{
        // TODO: add successful text
        this.newSectionName = ""
        this.sections.push(res)
        console.log(res)
        this.showNewSectionInput = false
      })
    }
  }

  toggleShowNewSectionInput() {
    this.showNewSectionInput = !this.showNewSectionInput;
    if (this.showNewSectionInput) {
      setTimeout(() => {this.inputSection.nativeElement.focus()}, 0);
    }
  }

  toggleShowNewSubInput(id) {
    console.log(this.showNewSubInput)
    this.showNewSubInput = id
  }

  saveSubTitle(parent_id) {
    if (this.newSubTitle != "") {
      this.talentService.addHelpSubSections(parent_id, this.newSubTitle).subscribe(res => {
        this.subSectionTitles.push(res)
        this.showNewSubInput = 0
        this.newSubTitle = "",
        this.selectedId = res["id"]
      })
    }
  }

  reloadSection() {
    this.talentService.getHelpSubSectionTitles().subscribe(titles => {
      this.subSectionTitles = titles;
    });
  }

  removeSubSection(id) {

    const message = "Are you sure you want delete this page?";

    const dialogData = {title: "Confirm Action", message: message};

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });
    let selection  = true;
    dialogRef.afterClosed().subscribe(dialogResult => {
      selection = dialogResult;
    });
    
  
    if(selection) {
      let removed_id = id 
      this.talentService.removeHelpSubSections(removed_id).subscribe(res => {
        this.subSectionTitles = this.subSectionTitles.filter(n => n.id !== removed_id)
        this.selectedId = 1
      })
    }
    
  }
}
