import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ComposeTextMessageComponent } from '../compose-text-message/compose-text-message.component';

@Component({
  selector: 'candidate-sms-button',
  templateUrl: './candidate-sms-button.component.html',
  styleUrls: ['./candidate-sms-button.component.css']
})
export class CandidateSmsButtonComponent implements OnInit {
  @Input() candidate: any;
  @Input() sm: boolean = false;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }
  ringCentralSMS(event){
    event.stopPropagation();
    this.dialog.open(ComposeTextMessageComponent, {
      height: '600px',
      width: '800px',
      data: {to:this.candidate.phone_mobile, candidate_id: this.candidate.id},
    });
  }

}
