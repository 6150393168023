import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  ViewChild,
  Optional,
  Inject,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { TalentService } from "../talent.service";
import { ActivatedRoute } from "@angular/router";
import { FormControl } from "@angular/forms";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material/select";
import { take, takeUntil, map, startWith } from "rxjs/operators";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivityHistoryComponent } from "./activity-history/activity-history.component";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";
import { CandidateCommunicationHistoryComponent } from "../candidate-communication-history/candidate-communication-history.component";
import { CommunicationHistoryComponent } from "../communication-history/communication-history.component";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
@Component({
  selector: "app-contact-detail",
  templateUrl: "./contact-detail.component.html",
  styleUrls: ["./contact-detail.component.css"],
})
export class ContactDetailComponent implements OnInit {
  @Input() id;
  @Input() create_new: boolean = false;
  @Input() contact;
  @Input() clients: any;
  client_name: string;

  has_primary_address_load: boolean = false;

  // For Client Selection
  clientCtrl: FormControl = new FormControl("");
  clientFilterCtrl: FormControl = new FormControl();
  filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild("singleSelect", { static: false }) singleSelect: MatSelect;
  client_id: number;
  clients_name: Array<string>;

  init_client_id: number;
  init_primary_address_id: Array<object> = [];
  protected _onDestroy = new Subject<void>();
  clients_lookup_id: Object = {};
  clients_lookup_name: Object = {};

  sortType: string = "activity_time";
  sortReverse: boolean = true;
  sortType1: string = "activity_time";
  sortReverse1: boolean = true;

  new_phone: string = "";
  new_note: string = "";
  new_activity: string = "";

  roles: any = [];

  past_activity = [];
  todo_activity = [];
  new_activity_date: Date;
  new_activity_time: string;
  show_activity_add: boolean = true;

  new_activity_object = {
    type: null,
    activity_time: null,
    need_follow_up: false,
    note: "",
  };

  contacts_in_client: Array<any> = [];
  prev_report_to: number;

  activity_types = [
    "Phone",
    "Email",
    "Meeting (in person)",
    "Meeting (Online)",
    "Misc.",
  ];

  hasChanged = false;
  // If the component not open in a single page, it is a child
  isChildComponent: Boolean = false;
  @Output() onCloseEvent = new EventEmitter<any>();

  // Add communication view list to this section
  is_communication_admin = false;
  all_active_users = [];
  all_active_users_obj = {};
  userViewerCtrl = new FormControl();
  filteredActiveUsers: any = [];
  allowed_viewers = [];
  @ViewChild("userViewerInput", { static: false })
  userViewerInput: ElementRef<HTMLInputElement>;

  todo_pagina;
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private talentService: TalentService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.create_new) {
      this.create_new = data.create_new;
      this.client_name = data.client_name;
      if (data.client_name) {
        this.clientCtrl.setValue(data.client_name);
      }
    }
  }

  ngOnInit() {
    this.resetNewActivityObject();
    if (this.id && this.contact) {
      this.isChildComponent = true;
      this.communicationAdminLoad();
      this.talentService.getContactDetail(this.id).subscribe((res) => {
        this.contact = res;
        this.getPrimaryaddress(this.id);
        this.prev_report_to = this.contact.report_to;
        this.init_client_id = this.contact["client_id"];

        this.createClientLookUp();
        this.splitActivities();
        this.client_id = this.contact["client_id"];
        this.getContactsInClient(this.client_id);
        this.getContactRoles(this.id);
        this.clients_name = Array.from(
          this.clients,
          (client) => client["company_name"]
        );
        this.filteredClient.next(this.clients_name.slice());
        this.clientCtrl.setValue(
          this.clients_lookup_id[this.contact["client_id"]]
        );
        this.contact.report_to = this.prev_report_to;
        this.monitorClientChange();
      });
    }

    if (!this.create_new) {
      if (!this.id) {
        const id = +this.route.snapshot.paramMap.get("id");
        this.id = id;
        this.communicationAdminLoad();
        this.getContactRoles(id);
        this.talentService.getContactDetail(id).subscribe(
          (res) => {
            this.contact = res;
            this.getPrimaryaddress(id);
            this.prev_report_to = res["report_to"];
            this.init_client_id = this.contact["client_id"];
            this.init_primary_address_id = this.contact["primary_address"];
            this.splitActivities();
            this.getClients();
            this.client_id = this.contact["client_id"];
            this.getContactsInClient(this.client_id);
          },
          (error) => console.log(error)
        );
      }
    } else {
      this.isChildComponent = true;
      this.contact = {
        name: null,
        client_id: null,
        title: null,
        phone: null,
        primary_email: null,
        secondary_email: null,
        activities: null,
        notes: null,
        linkedin: null,
        phones: [],
        primary_address: [],
        report_to: 0,
      };
      this.init_primary_address_id = [];
      this.getClients();
      this.has_primary_address_load = true;
    }

    this.clientFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterClients();
      });
  }

  communicationAdminLoad() {
    this.talentService.checkUserCommunicationAdmin().subscribe((res) => {
      if (res["is_communication_admin"]) {
        this.talentService.getAllActiveUsers().subscribe((res) => {
          this.all_active_users = res;
          for (let user of this.all_active_users) {
            this.all_active_users_obj[user["id"]] = user;
          }

          // console.log("all_active_users", this.all_active_users);
          // this.filteredActiveUsers = this.userViewerCtrl.valueChanges.pipe(
          //   startWith(""),
          //   map((user_name: any) =>
          //     typeof user_name === "string"
          //       ? this._userViewerFilter(user_name)
          //       : []
          //   )
          // );
          // console.log("filteredActiveUsers", this.filteredActiveUsers);
          this.talentService.getContactViewer(this.id).subscribe((res) => {
            this.allowed_viewers = res;
            this.is_communication_admin = true;
          });
        });
      }
    });
  }

  compareWithFn(item, selected) {
    return item === selected;
  }

  monitorClientChange() {
    this.clientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.client_id = this.clients_lookup_name[this.clientCtrl.value];
        if (this.client_id !== this.init_client_id) {
          this.contact["primary_address"] = [];
          this.updateHasChanged();
        } else {
          this.contact["primary_address"] = this.init_primary_address_id;
        }
        this.contact.report_to = 0;
        this.getContactsInClient(this.client_id);
      });
  }

  getContactsInClient(client_id) {
    this.talentService.getContactsBasic(client_id).subscribe((res) => {
      this.contacts_in_client = res;
    });
  }

  getPrimaryaddress(contact_id) {
    this.talentService.getContactPrimaryAddress(contact_id).subscribe((res) => {
      let id_list = res.map((a) => a["address_id"]);
      this.contact["primary_address"] = id_list;
      this.init_primary_address_id = id_list;
      this.has_primary_address_load = true;
    });
  }

  splitActivities() {
    let current_time = new Date();
    for (let a of this.contact.activities) {
      this.addActivityToDiffList(a, current_time);
    }
  }

  addActivityToDiffList(a, current_time) {
    let activity_time = new Date(Date.parse(a.activity_time));
    if (activity_time > current_time || a.need_follow_up == 1) {
      this.todo_activity.push(a);
    } else {
      this.past_activity.push(a);
    }
  }

  getClients() {
    this.talentService.getYodaweClients().subscribe((yodawe_client) => {
      this.clients = yodawe_client;
      this.createClientLookUp();
      if (!this.create_new) {
        this.clientCtrl.setValue(
          this.clients_lookup_id[this.contact["client_id"]]
        );
        this.contact.report_to = this.prev_report_to;
      }
      this.clients_name = Array.from(
        yodawe_client,
        (client) => client["company_name"]
      );

      this.filteredClient.next(this.clients_name.slice());

      this.monitorClientChange();
    });
  }

  getContactRoles(id) {
    this.talentService.getRolesByContactId(id).subscribe((roles) => {
      this.roles = roles;
    });
  }

  onSelectionChange(event: any) {
    this.allowed_viewers = event.value;
    this.updateHasChanged();
  }

  // Save Contact Infomation or Create a new Contact change function
  save() {
    this.contact["client_id"] = this.clients_lookup_name[this.clientCtrl.value];
    let validate = [];
    if (!this.contact["name"] || this.contact["name"] == "") {
      validate.push("Please enter a contact name");
    }
    if (!this.contact["client_id"]) {
      validate.push("Please select a client name");
    }
    if (
      this.contact["primary_email"] &&
      !this.contact["primary_email"].includes("@")
    ) {
      validate.push("Primary Email Address format is incorrect");
    }
    if (
      this.contact["secondary_email"] &&
      !this.contact["secondary_email"].includes("@")
    ) {
      validate.push("Secondary Email Address format is incorrect");
    }
    if (
      this.contact["linkedin"] &&
      !this.contact["linkedin"].includes("https:") &&
      !this.contact["linkedin"].includes("linkedin")
    ) {
      validate.push(
        "Linkedin Address format is incorrect, please include the https as prefix"
      );
    }

    if (validate.length != 0) {
      let confirm_data = {};

      confirm_data["type"] = "validate";
      confirm_data["title"] =
        "Please fix the following issue(s) to create a new address";
      confirm_data["validate"] = validate;

      this.dialog.open(CustomDialogComponent, {
        maxWidth: "800px",
        data: confirm_data,
      });
    } else {
      if (this.new_phone != "") {
        this.addPhone();
      }
      let data = {
        contact: this.contact,
        note: this.new_note,
        activity: this.new_activity,
        create_new: this.create_new,
      };
      this.talentService.saveContact(data).subscribe((res) => {
        const parsedRes = JSON.parse(res);

        if (parsedRes["status"] == "error") {
          let confirm_data = {};
          confirm_data["type"] = "info";
          confirm_data["title"] =
            "Please fix the following issue(s) to create a new contact";
          confirm_data["message"] = parsedRes.message;

          this.dialog.open(CustomDialogComponent, {
            maxWidth: "800px",
            data: confirm_data,
          });
        } else {
          if (this.create_new) {
            this.openConfirmationBox("Contact has been saved successfully");
          } else {
            this.talentService
              .updateContactViewer(this.id, this.allowed_viewers)
              .subscribe((res) => {
                this.openConfirmationBox("Contact has been saved successfully");
              });
          }
        }
      });
    }
  }

  openConfirmationBox(message) {
    let title = "Confirmation";
    let confirm_data = { title: title, type: "info", message: message };
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      maxWidth: "600px",
      data: confirm_data,
    });
    dialogRef.afterClosed().subscribe((dialogRef) => {
      if (dialogRef) {
        window.location.reload();
      }
    });
  }

  // Functions for client selection
  createClientLookUp() {
    for (let c of this.clients) {
      this.clients_lookup_id[c["id"]] = c["company_name"];
      this.clients_lookup_name[c["company_name"]] = c["id"];
    }
    if (this.client_name) {
      this.client_id = this.clients_lookup_name[this.client_name];
    }
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredClients are loaded initially
   */
  protected setInitialValue() {
    this.filteredClient
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a, b) => a && b && a === b;
      });
  }

  protected filterClients() {
    if (!this.clients_name) {
      return;
    }
    // get the search keyword
    let search = this.clientFilterCtrl.value;
    if (!search) {
      this.filteredClient.next(this.clients_name.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredClient.next(
      this.clients_name.filter(
        (client) => client.toLowerCase().indexOf(search) > -1
      )
    );
  }

  addPhone() {
    if (this.new_phone && /^[0-9\-\+\(\) ]+$/.test(this.new_phone)) {
      this.contact.phones.push({ phone: this.new_phone, active: 2 });
      this.new_phone = "";
    } else {
      window.alert("Please Input a Valid Phone number");
    }
    this.updateHasChanged();
  }

  removePhone(phone) {
    let new_array = [];
    for (let p of this.contact.phones) {
      if (p["phone"] == phone) {
        if (p["active"] == 1) {
          p["active"] = 0;
          new_array.push(p);
        }
      } else {
        new_array.push(p);
      }
    }
    this.contact.phones = new_array;
    this.updateHasChanged();
  }

  openActivityHistory(activity_id) {
    let history_dialog_ref = this.dialog.open(ActivityHistoryComponent, {
      height: "900px",
      width: "800px",
      data: {
        activity_id: activity_id,
      },
    });
    history_dialog_ref.afterClosed().subscribe((data) => {
      if (data.has_add) {
        this.refreshContactActivity();
      }
    });
  }

  resetNewActivityObject() {
    this.show_activity_add = !this.show_activity_add;
    this.new_activity_object = {
      type: null,
      activity_time: null,
      need_follow_up: false,
      note: "",
    };
    let now = new Date();
    this.new_activity_date = new Date();
    this.new_activity_time =
      ("0" + now.getHours()).slice(-2) +
      ":" +
      ("0" + now.getMinutes()).slice(-2);
  }

  addNewActivity() {
    this.new_activity_object.activity_time =
      this.new_activity_date.toISOString().slice(0, 10) +
      " " +
      this.new_activity_time +
      ":00";

    if (!this.new_activity_object.type) {
      window.alert("Please select the type of activity");
    } else {
      this.new_activity_object["contact_id"] = this.contact.id;
      this.talentService
        .createNewContactActivity(this.new_activity_object)
        .subscribe(
          (result) => {
            this.new_activity_object["contact_id"];
            this.addActivityToDiffList(result, new Date());
            this.resetNewActivityObject();
          },
          (err) => {
            window.alert("Error occured");
          }
        );
    }
  }

  refreshContactActivity() {
    this.talentService.getContactActivity(this.contact.id).subscribe((res) => {
      this.contact.activities = res;
      this.todo_activity = [];
      this.past_activity = [];
      this.splitActivities();
    });
  }

  changeContactActivityNeedToFollow(id, value) {
    let item;
    let pos;
    let current_seletion;
    if (value == "todo") {
      pos = this.todo_activity.findIndex((x) => x.id == id);
      item = this.todo_activity[pos];
    } else {
      pos = this.past_activity.findIndex((x) => x.id == id);
      item = this.past_activity[pos];
    }
    current_seletion = item["need_follow_up"];
    let update_selection;
    if (current_seletion == 0) {
      update_selection = 1;
    } else {
      update_selection = 0;
    }

    let data = { id: id, value: update_selection };
    this.talentService.updateContactActivityFollowUp(data).subscribe((res) => {
      // this.refreshContactActivity();
      if (current_seletion == 0) {
        item["need_follow_up"] = 1;
      } else {
        item["need_follow_up"] = 0;
      }
      if (value == "todo") {
        this.todo_activity.splice(pos, 1);
      } else {
        this.past_activity.splice(pos, 1);
      }
      this.addActivityToDiffList(item, new Date());
    });
  }

  select_primary_address_create_new(eventData: {
    selected_id: Array<object>;
    address_id: number;
    is_select: boolean;
  }) {
    this.contact["primary_address"] = eventData.selected_id;
  }

  select_primary_address(eventData: {
    selected_id: Array<object>;
    address_id: number;
    is_select: boolean;
  }) {
    eventData["contact_id"] = this.contact.id;
    console.log(eventData);
    this.talentService
      .updateContactPrimaryAddress(eventData)
      .subscribe((res) => {
        console.log("Set new primary address to contact");
        this.init_primary_address_id = eventData.selected_id;
        this.contact["primary_address"] = eventData.selected_id;
      });
  }

  updateHasChanged() {
    this.hasChanged = true;
  }

  handleClose() {
    if (this.create_new) {
      if (this.hasChanged) {
        let title = "Seems like you have unsaved change.";
        let message = "Do you still want to close?";
        let confirm_data = { title: title, type: "confirm", message: message };
        const dialogRef = this.dialog.open(CustomDialogComponent, {
          maxWidth: "600px",
          data: confirm_data,
        });
        dialogRef.afterClosed().subscribe((dialogRef) => {
          if (dialogRef) {
            this.dialog.closeAll();
          }
        });
      } else {
        this.dialog.closeAll();
      }
    } else {
      this.onCloseEvent.emit();
    }
  }
  communicationHistory(contact_id) {
    let dialogRef = this.dialog.open(CommunicationHistoryComponent, {
      height: "700px",
      width: "1000px",
      data: { user_id: contact_id },
    });
  }

  selectViewerForUser(event: MatAutocompleteSelectedEvent) {
    let user_id = event.option.value;
    let user = this.all_active_users_obj[user_id];
    this.allowed_viewers.push(user);
    this.userViewerCtrl.setValue("");
    this.userViewerInput.nativeElement.value = "";
  }

  removeUserViewer(viewer_id) {
    this.allowed_viewers = this.allowed_viewers.filter(
      (viewer) => viewer.id != viewer_id
    );
  }

  private _userViewerFilter(value: string): any[] {
    let filterValue = "";
    if (typeof value === "string") {
      filterValue = value.toLowerCase();
    }
    console.log("Inside", this.all_active_users);
    return this.all_active_users.filter(
      (user) =>
        !this.allowed_viewers.some((v) => v.id === user["id"]) &&
        (user["first_name"].toLowerCase().includes(filterValue) ||
          user["last_name"].toLowerCase().includes(filterValue))
    );
  }
}
