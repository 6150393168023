import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-dashbaord",
  templateUrl: "./dashbaord.component.html",
  styleUrls: ["./dashbaord.component.css"],
})
export class DashbaordComponent implements OnInit {
  @Input() user_drop_down_data;
  @Input() user_id;
  selected: any;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  // multi: any[];
  view: any[] = [700, 300];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "Country";
  yAxisLabel: string = "Year";

  colorScheme = {
    domain: ["#5AA454", "#E44D25", "#CFC0BB", "#7aa3e5", "#a8385d", "#aae3f5"],
  };
  saleData = [
    { name: "Mobiles", value: 105000 },
    { name: "Laptop", value: 55000 },
    { name: "AC", value: 15000 },
    { name: "Headset", value: 150000 },
    { name: "Fridge", value: 20000 },
  ];
  multi = [
    {
      name: "Germany",
      series: [
        {
          name: "1990",
          value: 62000000,
        },
        {
          name: "2010",
          value: 73000000,
        },
        {
          name: "2011",
          value: 89400000,
        },
      ],
    },

    {
      name: "USA",
      series: [
        {
          name: "1990",
          value: 250000000,
        },
        {
          name: "2010",
          value: 309000000,
        },
        {
          name: "2011",
          value: 311000000,
        },
      ],
    },

    {
      name: "France",
      series: [
        {
          name: "1990",
          value: 58000000,
        },
        {
          name: "2010",
          value: 50000020,
        },
        {
          name: "2011",
          value: 58000000,
        },
      ],
    },
    {
      name: "UK",
      series: [
        {
          name: "1990",
          value: 57000000,
        },
        {
          name: "2010",
          value: 62000000,
        },
        {
          name: "2011",
          value: 72000000,
        },
      ],
    },
  ];

  constructor() {}
  ngOnInit(): void {
    console.log(this.ranges);
  }
  onSelect(data): void {
    console.log("Item clicked", JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log("Activate", JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log("Deactivate", JSON.parse(JSON.stringify(data)));
  }
}
