import { Input, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TalentService } from "../talent.service";
@Component({
  selector: "app-my-roles",
  templateUrl: "./my-roles.component.html",
  styleUrls: ["./my-roles.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MyRolesComponent implements OnInit {
  @Input() filter: string;
  roles: any = [];
  users: any = [];
  unfiltered_roles: any = [];
  master_roles: any[];
  saved_count = [];
  show_all_roles = false;
  id;
  name;
  show_detail: Object = {};
  show_saved_candidates: Object = {};
  show_closed = false;

  constructor(private talentService: TalentService) {}

  ngOnInit() {
    this.talentService.getYodaweUsers().subscribe((result) => {
      this.users = result;
      console.log(this.users);
    });
    this.id = this.talentService.displayID;
    // this.name = "Shash";
    this.getUserRoles();
  }

  getUserRoles(): void {
    this.talentService.getUserRoles().subscribe(
      (roles) => {
        this.master_roles = roles;

        this.filterRoles(roles);
      },
      (err) => {},
      () => {
        this.getRolesCount();
      }
    );
  }

  filterRoles(roles) {
    this.unfiltered_roles = Array.from(roles);

    if (!this.show_closed) {
      this.unfiltered_roles = this.unfiltered_roles.filter((n) => n.open === 1);
    }
    var j = this.unfiltered_roles.length;
    console.log(roles, this.id);
    while (j--) {
      if (
        this.id &&
        (this.unfiltered_roles[j]["recruiters"].includes(parseInt(this.id)) ||
          this.unfiltered_roles[j]["sourcers"].includes(parseInt(this.id)) ||
          this.unfiltered_roles[j]["account_manager"] == parseInt(this.id))
      ) {
        continue;
      } else {
        this.unfiltered_roles.splice(j, 1);
      }
    }
    this.roles = Array.from(this.unfiltered_roles);
  }

  Checkbox() {
    console.log(this.show_all_roles);
    console.log(this.show_closed);
    if (this.show_all_roles) {
      if (!this.show_closed) {
        this.roles = Array.from(this.master_roles).filter((n) => n.open === 1);
      } else {
        this.roles = Array.from(this.master_roles);
      }
    } else {
      this.filterRoles(this.master_roles);
    }
  }

  getRolesCount(): void {
    // this.saved_count= [];
    // for (var i=0;i<this.roles.length;i++){
    //   this.talentService.savedCandidatesCount(this.roles[i].role_id).subscribe(result=>{
    //         this.saved_count[result.role_id]=result.count;
    //   }
    //   );
    // }
  }
  removeRoleForUser(role_id): void {
    this.talentService.removeRoleForUser(role_id).subscribe(
      (result) => {
        console.log(result);
        this.getUserRoles();
      },
      (err) => {},
      () => {}
    );
  }
  detail_toggle(id): void {
    this.show_detail[id] = !this.show_detail[id];
    if (this.show_detail[id]) {
      this.show_saved_candidates[id] = false;
    }
  }
  toggle_saved_candidates(id) {
    this.show_saved_candidates[id] = !this.show_saved_candidates[id];
    if (this.show_saved_candidates[id]) {
      this.show_detail[id] = false;
    }
  }
}
