import { Input, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TalentService } from '../talent.service';

@Component({
  selector: 'app-team-roles',
  templateUrl: './team-roles.component.html',
  styleUrls: ['./team-roles.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TeamRolesComponent implements OnInit {
  sortType;
  sortReverse;
  
  @Input() filter:string;
  roles:any = [];
  saved_count:any = [];
  constructor(private talentService: TalentService) { }

  

  ngOnInit() {
    this.getTeamRoles();
  }

  getTeamRoles(): void {
    this.talentService.getTeamRoles()
      .subscribe(roles => { 
         this.roles = roles},
      (error) => {},
    () => { this.getRolesCount();} );
  }

  getRolesCount():void {
    console.log(this.roles);
    this.saved_count= [];
    for (var i=0;i<this.roles.length;i++){
      this.talentService.savedCandidatesCount(this.roles[i].role_id).subscribe(result=>{        
            this.saved_count[result.role_id]=result.count;
      }
      );
    }

  }
}
