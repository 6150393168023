import { Component, OnInit, Input } from '@angular/core';
import { TalentService } from '../talent.service';

@Component({
  selector: 'candidate-phone-button',
  templateUrl: './candidate-phone-button.component.html',
  styleUrls: ['./candidate-phone-button.component.css']
})
export class CandidatePhoneButtonComponent implements OnInit {
  @Input() candidate: any;
  @Input() sm: boolean = false;

  constructor(private talentService: TalentService) { }

  ngOnInit() {
  }
  ringCentralPhone(event){
    event.stopPropagation();
    this.talentService.ringCentralCallPhone(this.candidate.phone_mobile,this.candidate.id);
  }

}

