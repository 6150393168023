import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { ActiveCandidatesComponent } from "./active-candidates/active-candidates.component";
import {
  CandidateDetailComponent,
  DateAndTimeComponent,
} from "./candidate-detail/candidate-detail.component";
import { TalentService } from "./talent.service";
import { MessagesComponent } from "./messages/messages.component";
import { MessageService } from "./message.service";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { ActiveCandidatesFilter, OrderBySort, GetCount } from "./custom.pipe";
import { SearchComponent } from "./search/search.component";
import { StarRatingComponent } from "./star-rating/star-rating.component";
import { NgxPaginationModule } from "ngx-pagination";
import { AddNewComponent } from "./add-new/add-new.component";
import { OpenRolesComponent } from "./open-roles/open-roles.component";
import { MyRolesComponent } from "./my-roles/my-roles.component";
import { TeamRolesComponent } from "./team-roles/team-roles.component";
import { LoginComponent } from "./login/login.component";
import { NotFound404Component } from "./not-found-404/not-found-404.component";
import { AuthGuardService } from "./auth-guard/auth-guard.component";
import { LogoutComponent } from "./logout/logout.component";
import { ShortlistComponent } from "./shortlist/shortlist.component";
import { ShortlistedComponent } from "./shortlisted/shortlisted.component";
import { InterviewComponent } from "./interview/interview.component";
import { DropdownBasicComponent } from "./dropdown-basic/dropdown-basic.component";
import { MatDialog } from "@angular/material/dialog";
import { NotesHistoryComponent } from "./noteshistory/noteshistory.component";
import { HelpComponent } from "./help/help.component";
import { DailyCandidateSummaryComponent } from "./daily-candidate-summary/daily-candidate-summary.component";
import { ChartsModule } from "ng2-charts";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import "hammerjs";

// import {Chart} from 'chart.js'
// let totalizer = {
//     id: 'totalizer',
//     beforeUpdate: chart => {
//       let totals = {}
//       let utmost = 0
//       chart.data.datasets.forEach((dataset, datasetIndex) => {
//         if (chart.isDatasetVisible(datasetIndex)) {
//           utmost = datasetIndex
//           dataset.data.forEach((value, index) => {
//             totals[index] = (totals[index] || 0) + value
//           })
//         }
//       })
//       chart.$totalizer = {
//         totals: totals,
//         utmost: utmost
//       }
//     }
//   }
// Chart.pluginService.register(totalizer);

// import {DateAndTimeComponent } from './dateandtime/dateandtime.component';
import { Component } from "@angular/core";

import { SavedCandidatesComponent } from "./saved-candidates/saved-candidates.component";
import { WorkflowHistoryComponent } from "./workflow-history/workflow-history.component";

import { DuplicateOverrideDialogComponent } from "./duplicate-override-dialog/duplicate-override-dialog.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { QuillModule } from "ngx-quill";
import Quill from "quill";
import imageUpload from "quill-plugin-image-upload";
Quill.register("modules/imageUpload", imageUpload);
var BlotsInline = Quill.import("blots/inline");
Quill.register("format/blotsInline", BlotsInline);
Quill.register(BlotsInline, true);
// var DirectionAttribute = Quill.import('attributors/attribute/direction');
// Quill.register(DirectionAttribute, true);
var AlignClass = Quill.import("attributors/class/align");
Quill.register(AlignClass, true);
var AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatDatepicker,
} from "@angular/material";
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { RoleDetailComponent } from "./role-detail/role-detail.component";
import { RoleDetailEditComponent } from "./role-detail-edit/role-detail-edit.component";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { RecommendationComponent } from "./recommendation/recommendation.component";
import { RoleRecommendationEditComponent } from "./role-recommendation-edit/role-recommendation-edit.component";
import { RoleRecommendationsComponent } from "./role-recommendations/role-recommendations.component";
import { ComposeEmailComponent } from "./compose-email/compose-email.component";
import { RingCentralAuthComponent } from "./ring-central-auth/ring-central-auth.component";
import { ComposeTextMessageComponent } from "./compose-text-message/compose-text-message.component";
import { CandidateSmsButtonComponent } from "./candidate-sms-button/candidate-sms-button.component";
import { CandidateEmailButtonComponent } from "./candidate-email-button/candidate-email-button.component";
import { CandidatePhoneButtonComponent } from "./candidate-phone-button/candidate-phone-button.component";
import { CandidateCommunicationHistoryComponent } from "./candidate-communication-history/candidate-communication-history.component";
import { HelpContentComponent } from "./help-content/help-content.component";
import { DndDirective } from "./candidate-detail/dnd.directive";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { CustomMaterialModule } from "./custom-material/custom-material.module";
import { ClientsComponent } from "./clients/clients.component";
import { ClientDetailComponent } from "./client-detail/client-detail.component";
import { ContactComponent } from "./contact/contact.component";
import { ContactDetailComponent } from "./contact-detail/contact-detail.component";
import { ActivityHistoryComponent } from "./contact-detail/activity-history/activity-history.component";
import { CandidatesWorkflowComponent } from "./candidates-workflow/candidates-workflow.component";
import { TodoContactActivityNotificationComponent } from "./contact/todo-contact-activity-notification/todo-contact-activity-notification.component";
import { AddressListComponent } from "./address-list/address-list.component";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { AgmCoreModule } from "@agm/core";
import { CustomDialogComponent } from "./custom-dialog/custom-dialog.component";
import { DashbaordComponent } from "./daily-candidate-summary/dashbaord/dashbaord.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { RoleTemplateGeneratorComponent } from "./role-detail/role-template-generator/role-template-generator.component";
import { ContactAddressListComponent } from "./contact-address-list/contact-address-list.component";
import { UserManagementComponent } from "./user-management/user-management.component";
import { AiSummaryComponent } from "./ai-summary/ai-summary.component";
import { CommunicationHistoryComponent } from "./communication-history/communication-history.component";
import { CommunicationManagementComponent } from "./communication-management/communication-management.component";
import { CommunicationViewHistoryComponent } from "./communication-view-history/communication-view-history.component";
@NgModule({
  declarations: [
    AppComponent,
    ActiveCandidatesComponent,
    CandidateDetailComponent,
    MessagesComponent,
    ActiveCandidatesFilter,
    OrderBySort,
    GetCount,
    SearchComponent,
    StarRatingComponent,
    AddNewComponent,
    OpenRolesComponent,
    MyRolesComponent,
    DateAndTimeComponent,
    TeamRolesComponent,
    LoginComponent,
    NotFound404Component,
    LogoutComponent,
    ShortlistComponent,
    ShortlistedComponent,
    DropdownBasicComponent,
    SavedCandidatesComponent,
    WorkflowHistoryComponent,
    StarRatingComponent,
    DuplicateOverrideDialogComponent,
    HelpComponent,
    InterviewComponent,
    NotesHistoryComponent,
    DailyCandidateSummaryComponent,
    RoleDetailComponent,
    RoleDetailEditComponent,
    UserSettingsComponent,
    PrivacyPolicyComponent,
    RecommendationComponent,
    RoleRecommendationEditComponent,
    RoleRecommendationsComponent,
    ComposeEmailComponent,
    RingCentralAuthComponent,
    ComposeTextMessageComponent,
    CandidateSmsButtonComponent,
    CandidateEmailButtonComponent,
    CandidatePhoneButtonComponent,
    CandidateCommunicationHistoryComponent,
    HelpContentComponent,
    DndDirective,
    ConfirmDialogComponent,
    ClientsComponent,
    ClientDetailComponent,
    ContactComponent,
    ContactDetailComponent,
    ActivityHistoryComponent,
    CandidatesWorkflowComponent,
    TodoContactActivityNotificationComponent,
    AddressListComponent,
    CustomDialogComponent,
    DashbaordComponent,
    RoleTemplateGeneratorComponent,
    ContactAddressListComponent,
    UserManagementComponent,
    AiSummaryComponent,
    CommunicationHistoryComponent,
    CommunicationManagementComponent,
    CommunicationViewHistoryComponent,
  ],
  imports: [
    CustomMaterialModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    NgxPaginationModule,
    NgbModule,
    MatAutocompleteModule,
    MatInputModule,
    MatChipsModule,
    MatDialogModule,
    MatSelectModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatMenuModule,
    MatCardModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatListModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    ChartsModule,
    ReactiveFormsModule,
    MatSortModule,
    PdfViewerModule,
    GooglePlaceModule,
    NgxChartsModule,
    NgxDaterangepickerMd.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ["bold", "italic", "underline"], //, 'strike'],        // toggled buttons
          // ['blockquote', 'code-block'],
          // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          // [{ 'direction': 'rtl' }],                         // text direction

          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: ["black", "red", "#336699"] }], // { 'background': [] }],          // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ["clean"], // remove formatting button

          ["link"], //, 'image', 'video']                         // link and image, video
        ],
      },
    }),
    PdfJsViewerModule,
    NgxMatSelectSearchModule,
    CdkAccordionModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCBCoOcPlKRoaiqkN4Vuhdp_QBssaSQ3Ns",
      libraries: ["places"],
    }),
  ],
  providers: [
    TalentService,
    MessageService,
    AuthGuardService,
    MatDialog,
    StarRatingComponent,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ShortlistComponent,
    OpenRolesComponent,
    WorkflowHistoryComponent,
    DuplicateOverrideDialogComponent,
    CandidateDetailComponent,
    DateAndTimeComponent,
    NotesHistoryComponent,
    RoleDetailEditComponent,
    ComposeEmailComponent,
    ComposeTextMessageComponent,
    CandidateCommunicationHistoryComponent,
    CommunicationHistoryComponent,
    CommunicationViewHistoryComponent,
    ConfirmDialogComponent,
    ActivityHistoryComponent,
    TodoContactActivityNotificationComponent,
    AddressListComponent,
    ContactAddressListComponent,
    CustomDialogComponent,
    RoleTemplateGeneratorComponent,
    CandidatesWorkflowComponent,
  ],
})
export class AppModule {}
