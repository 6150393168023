import { Component, OnInit } from '@angular/core';
import { TalentService }  from '../talent.service';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable, merge} from 'rxjs';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.css']
})
export class RecommendationComponent implements OnInit {
  skills: any = [];
  businessAreas: any = [];
  technicalFrameworks: any = [];
  functionalKnowledge: any = [];
  technicalLibraries: any = [];
  vendorProducts: any = [];
  workplaceTypes: any = [];
  filteredSkills: Observable<Object[]>;
  filteredSkills2: Observable<Object[]>;
  filteredSkills3: Observable<Object[]>;
  filteredBusinessAreas: Observable<Object[]>;
  filteredBusinessAreas2: Observable<Object[]>;
  filteredBusinessAreas3: Observable<Object[]>;
  filteredTechnicalFrameworks: Observable<Object[]>;
  filteredTechnicalFrameworks2: Observable<Object[]>;
  filteredTechnicalFrameworks3: Observable<Object[]>;
  filteredFunctionalKnowledge: Observable<Object[]>;
  filteredFunctionalKnowledge2: Observable<Object[]>;
  filteredFunctionalKnowledge3: Observable<Object[]>;
  filteredTechnicalLibraries: Observable<Object[]>;
  filteredTechnicalLibraries2: Observable<Object[]>;
  filteredTechnicalLibraries3: Observable<Object[]>;
  filteredVendorProducts: Observable<Object[]>;
  filteredVendorProducts2: Observable<Object[]>;
  filteredVendorProducts3: Observable<Object[]>;
  selectedOptions: any = {};
  selectedWorkplaceTypes: any = {};
  
  recommendations: any = [];

  skillsCtrl = new FormControl();
  skillsCtrl2 = new FormControl();
  skillsCtrl3 = new FormControl();
  technicalFrameworksCtrl = new FormControl();
  technicalFrameworksCtrl2 = new FormControl();
  technicalFrameworksCtrl3 = new FormControl();
  functionalKnowledgeCtrl = new FormControl();
  functionalKnowledgeCtrl2 = new FormControl();
  functionalKnowledgeCtrl3 = new FormControl();
  businessAreasCtrl = new FormControl();
  businessAreasCtrl2 = new FormControl();
  businessAreasCtrl3 = new FormControl();
  technicalLibraryCtrl = new FormControl();
  technicalLibraryCtrl2 = new FormControl();
  technicalLibraryCtrl3 = new FormControl();
  vendorProductCtrl = new FormControl();
  vendorProductCtrl2 = new FormControl();
  vendorProductCtrl3 = new FormControl();
  
  selectedSkillCtrl = new FormControl();
  selectedSkillCtrl2 = new FormControl();
  selectedSkillCtrl3 = new FormControl();
  selectedTechnicalFrameworkCtrl = new FormControl();
  selectedTechnicalFrameworkCtrl2 = new FormControl();
  selectedTechnicalFrameworkCtrl3 = new FormControl();
  selectedFunctionalKnowledgeCtrl = new FormControl();
  selectedFunctionalKnowledgeCtrl2 = new FormControl();
  selectedFunctionalKnowledgeCtrl3 = new FormControl();
  selectedBusinessAreaCtrl = new FormControl();
  selectedBusinessAreaCtrl2 = new FormControl();
  selectedBusinessAreaCtrl3 = new FormControl();
  selectedTechnicalLibraryCtrl = new FormControl();
  selectedTechnicalLibraryCtrl2 = new FormControl();
  selectedTechnicalLibraryCtrl3 = new FormControl();
  selectedVendorProductCtrl = new FormControl();
  selectedVendorProductCtrl2 = new FormControl();
  selectedVendorProductCtrl3 = new FormControl();


  constructor(private talentService: TalentService) { }

  ngOnInit() {
    var noneChoice: Object[] = [{id: null, name: 'None'}]
    this.talentService.getTechnicalSkills()
    .subscribe(result=>{
      this.skills = noneChoice.concat(result);
      this.filteredSkills = this.skillsCtrl.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.skillFilter(name) : this.skills.slice())
      )
      this.filteredSkills2 = this.skillsCtrl2.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.skillFilter(name) : this.skills.slice())
      )
      this.filteredSkills3 = this.skillsCtrl3.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.skillFilter(name) : this.skills.slice())
      );
    });
    this.talentService.getTechnicalFrameworks()
    .subscribe(result=>{
      this.technicalFrameworks = noneChoice.concat(result);
      this.filteredTechnicalFrameworks = this.technicalFrameworksCtrl.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.frameworkFilter(name) : this.technicalFrameworks.slice())
      )
      this.filteredTechnicalFrameworks2 = this.technicalFrameworksCtrl2.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.frameworkFilter(name) : this.technicalFrameworks.slice())
      )
      this.filteredTechnicalFrameworks3 = this.technicalFrameworksCtrl3.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.frameworkFilter(name) : this.technicalFrameworks.slice())
      );
    });
    this.talentService.getTechnicalLibraries()
    .subscribe(result=>{
      this.technicalLibraries = noneChoice.concat(result);
      this.filteredTechnicalLibraries = this.technicalLibraryCtrl.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.technicalLibraryFilter(name) : this.technicalLibraries.slice())
      )
      this.filteredTechnicalLibraries2 = this.technicalLibraryCtrl2.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.technicalLibraryFilter(name) : this.technicalLibraries.slice())
      )
      this.filteredTechnicalLibraries3 = this.technicalLibraryCtrl3.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.technicalLibraryFilter(name) : this.technicalLibraries.slice())
      );
    });
    this.talentService.getBusinessAreas()
    .subscribe(result=>{
      this.businessAreas = noneChoice.concat(result);
      this.filteredBusinessAreas = this.businessAreasCtrl.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.businessAreaFilter(name) : this.businessAreas.slice())
      )
      this.filteredBusinessAreas2 = this.businessAreasCtrl2.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.businessAreaFilter(name) : this.businessAreas.slice())
      )
      this.filteredBusinessAreas3 = this.businessAreasCtrl3.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.businessAreaFilter(name) : this.businessAreas.slice())
      );
    });
    this.talentService.getFunctionalKnowledge()
    .subscribe(result=>{
      this.functionalKnowledge = noneChoice.concat(result);
      this.filteredFunctionalKnowledge = this.functionalKnowledgeCtrl.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.functionalKnowledgeFilter(name) : this.functionalKnowledge.slice())
      )
      this.filteredFunctionalKnowledge2 = this.functionalKnowledgeCtrl2.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.functionalKnowledgeFilter(name) : this.functionalKnowledge.slice())
      )
      this.filteredFunctionalKnowledge3 = this.functionalKnowledgeCtrl3.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.functionalKnowledgeFilter(name) : this.functionalKnowledge.slice())
      );
    });
    this.talentService.getVendorProducts()
    .subscribe(result=>{
      this.vendorProducts = noneChoice.concat(result);
      this.filteredVendorProducts = this.vendorProductCtrl.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.vendorProductFilter(name) : this.vendorProducts.slice())
      )
      this.filteredVendorProducts2 = this.vendorProductCtrl2.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.vendorProductFilter(name) : this.vendorProducts.slice())
      )
      this.filteredVendorProducts3 = this.vendorProductCtrl3.valueChanges.pipe(
        startWith(null),
        map(name => name ? this.vendorProductFilter(name) : this.vendorProducts.slice())
      );
    });
    this.talentService.getWorkplaceTypes()
    .subscribe(result=>{
      this.workplaceTypes = result;
    });
  }
  displayFn(option?): string | undefined {
    return option ? option.name : undefined;
  }
  private skillFilter(name): Object[] {
    if(typeof name === 'string'){
      const filterValue = name.toLowerCase();
      return this.skills.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    return this.skills.slice();
  }
  private frameworkFilter(name): Object[] {
    if(typeof name === 'string'){
      const filterValue = name.toLowerCase();
      return this.technicalFrameworks.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    return this.technicalFrameworks.slice();
  }
  private functionalKnowledgeFilter(name): Object[] {
    if(typeof name === 'string'){
      const filterValue = name.toLowerCase();
      return this.functionalKnowledge.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    return this.functionalKnowledge.slice();
  }
  private businessAreaFilter(name): Object[] {
    if(typeof name === 'string'){
      const filterValue = name.toLowerCase();
      return this.businessAreas.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    return this.businessAreas.slice();
  }
  private technicalLibraryFilter(name): Object[] {
    if(typeof name === 'string'){
      const filterValue = name.toLowerCase();
      return this.technicalLibraries.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    return this.technicalLibraries.slice();
  }
  private vendorProductFilter(name): Object[] {
    if(typeof name === 'string'){
      const filterValue = name.toLowerCase();
      return this.vendorProducts.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    return this.vendorProducts.slice();
  }
  toggleWorkplaceType(option){
    this.selectedWorkplaceTypes[option] = !this.selectedWorkplaceTypes[option];
  }
  search(){
    console.log(this.selectedOptions)
    const role = {
      technical_skill_1: this.selectedSkillCtrl.value?this.selectedSkillCtrl.value.id:null,
      technical_skill_2: this.selectedSkillCtrl2.value?this.selectedSkillCtrl2.value.id:null,
      technical_skill_3: this.selectedSkillCtrl3.value?this.selectedSkillCtrl3.value.id:null,
      technical_framework_1: this.selectedTechnicalFrameworkCtrl.value?this.selectedTechnicalFrameworkCtrl.value.id:null,
      technical_framework_2: this.selectedTechnicalFrameworkCtrl2.value?this.selectedTechnicalFrameworkCtrl2.value.id:null,
      technical_framework_3: this.selectedTechnicalFrameworkCtrl3.value?this.selectedTechnicalFrameworkCtrl3.value.id:null,
      functional_knowledge_1: this.selectedFunctionalKnowledgeCtrl.value?this.selectedFunctionalKnowledgeCtrl.value.id:null,
      functional_knowledge_2: this.selectedFunctionalKnowledgeCtrl2.value?this.selectedFunctionalKnowledgeCtrl2.value.id:null,
      functional_knowledge_3: this.selectedFunctionalKnowledgeCtrl3.value?this.selectedFunctionalKnowledgeCtrl3.value.id:null,
      business_area_1: this.selectedBusinessAreaCtrl.value?this.selectedBusinessAreaCtrl.value.id:null,
      business_area_2: this.selectedBusinessAreaCtrl2.value?this.selectedBusinessAreaCtrl2.value.id:null,
      business_area_3: this.selectedBusinessAreaCtrl3.value?this.selectedBusinessAreaCtrl3.value.id:null,
      technical_library_1: this.selectedTechnicalLibraryCtrl.value?this.selectedTechnicalLibraryCtrl.value.id:null,
      technical_library_2: this.selectedTechnicalLibraryCtrl2.value?this.selectedTechnicalLibraryCtrl2.value.id:null,
      technical_library_3: this.selectedTechnicalLibraryCtrl3.value?this.selectedTechnicalLibraryCtrl3.value.id:null,
      vendor_product_1: this.selectedVendorProductCtrl.value?this.selectedVendorProductCtrl.value.id:null,
      vendor_product_2: this.selectedVendorProductCtrl2.value?this.selectedVendorProductCtrl2.value.id:null,
      vendor_product_3: this.selectedVendorProductCtrl3.value?this.selectedVendorProductCtrl3.value.id:null,
      workplace_experience: Object.entries(this.selectedWorkplaceTypes).filter(([key,value])=>value).map(([key,value])=>key)
    }
    console.log(role)
    this.talentService.getRecommendations(role).subscribe(result => {
      this.recommendations = result;
      console.log(result)
    })
  }
  checkInput(){
    console.log(this.selectedSkillCtrl.value)
  }
}
