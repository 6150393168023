import {
  Component,
  OnInit,
  Input,
  Optional,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { TalentService } from "../talent.service";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material";
import { ClientDetailComponent } from "../client-detail/client-detail.component";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";
import { ContactDetailComponent } from "../contact-detail/contact-detail.component";
@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.css"],
})
export class ClientsComponent implements OnInit {
  @Input() filter_input: string = "";
  @ViewChild(ClientDetailComponent, { static: false })
  clientDetail: ClientDetailComponent;
  @ViewChild(ContactDetailComponent, { static: false })
  contactDetail: ContactDetailComponent;
  clients: any = [];
  current_clients: any = [];
  show_detail: Array<Boolean> = [];
  role_name_to_id: Object = {};
  roles: Object = {};
  all_roles: any = [];
  show_open_roles: Boolean = true;
  hide_roles: Boolean = false;
  hide_contacts: Boolean = false;
  filter_lookup: Object = {};
  sortType: string = "contact_update_time";
  sortReverse: boolean = false;
  contacts_by_client_id: Object = {};
  user_drop_down_data: any;
  nameId: number = 0;
  show_contact_detail: Array<Boolean> = [];

  constructor(
    private talentService: TalentService,
    public dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<ClientDetailComponent>
  ) {}

  ngOnInit() {
    this.talentService.getYodaweClients().subscribe((yodawe_client) => {
      this.clients = yodawe_client;

      this.show_detail = new Array(yodawe_client.length + 1).fill(false);
      let i = 0;
      for (let i = 0; i < yodawe_client.length; i++) {
        this.role_name_to_id[this.clients[i]["company_name"]] = i + 1;
        this.clients[i]["roles"] = [];

        if (this.clients[i]["id"] in this.contacts_by_client_id) {
          this.clients[i]["contact_update_time"] =
            this.contacts_by_client_id[this.clients[i]["id"]][0][
              "last_modified"
            ];
        }
      }
      for (let c of yodawe_client) {
        let filter_string = "";
        for (let key in c) {
          filter_string += " ";
          filter_string += String(c[key]).toLowerCase();
        }
        this.filter_lookup[c["company_name"]] = filter_string;
      }
      this.getContacts();
    });

    this.talentService.getOpenRolesNew("all").subscribe((roles) => {
      this.all_roles = roles;
      for (let role of this.all_roles) {
        let client_id = this.role_name_to_id[role["client"]];
        if (client_id) {
          if (this.clients[client_id - 1]["roles"].length == 0) {
            this.clients[client_id - 1]["last_open_date"] = role["open_date"];
          }
          this.clients[client_id - 1]["roles"].push(role);
        }
      }
      this.current_clients = [...this.clients];

      for (let c of this.current_clients) {
        let filter_string = "";
        for (let key in c.roles) {
          filter_string += " ";
          filter_string += String(c.roles[key]['search_string']).toLowerCase();
        }
        this.filter_lookup[c["company_name"]] += filter_string;
      }
      this.getYodaweUsersData();
    });

    // this.roles = TempRoles
    // this.allRoles = TempRoles
  }

  getYodaweUsersData() {
    this.talentService.getYodaweUsersData().subscribe((yodawe_users) => {
      this.user_drop_down_data = yodawe_users;
      this.user_drop_down_data.shift();
      this.user_drop_down_data.unshift({ id: 0, name: "ALL" });
      for (let x of yodawe_users) {
        if (
          x["name"].toLowerCase() ==
          this.talentService.displayName.toLowerCase()
        ) {
          this.nameId = +x["id"];
          break;
        } else {
          this.nameId = 0;
        }
      }
      if (this.nameId == 1 || this.nameId == 55) {
        this.nameId = 0;
      }
      this.applyFilter();
    });
  }

  getContacts() {
    this.talentService.getContacts(0).subscribe((contacts) => {
      for (let c of contacts) {
        if (!(c["client_id"] in this.contacts_by_client_id)) {
          this.contacts_by_client_id[c["client_id"]] = [];
        }
        this.contacts_by_client_id[c["client_id"]].push(c);
      }
      for (let i = 0; i < this.clients.length; i++) {
        if (this.clients[i]["id"] in this.contacts_by_client_id) {
          this.clients[i]["contact_update_time"] =
            this.contacts_by_client_id[this.clients[i]["id"]][0][
              "last_modified"
            ];
        }
      }
    });
  }

  applyFilter() {
    let value = this.filter_input;
    if ((value = "")) {
      this.current_clients = this.clients;
    } else {
      let new_clients = [];
      for (let c of this.clients) {
        if (
          this.filter_lookup[c["company_name"]].includes(
            this.filter_input.toLowerCase()
          )
        ) {
          if (this.nameId == 0 || c["owner"] == this.nameId) {
            new_clients.push(c);
          }
        }
      }
      this.current_clients = [...new_clients];
    }
  }

  switch_show_open_roles(): void {
    this.show_open_roles = !this.show_open_roles;
  }

  detail_toggle(id): void {
    if (this.show_detail[id] && this.clientDetail.hasChanged) {
      let title = "Seems like you have unsaved change.";
      let message = "Do you still want to close?";
      let confirm_data = { title: title, type: "confirm", message: message };
      const dialogRef = this.dialog.open(CustomDialogComponent, {
        maxWidth: "600px",
        data: confirm_data,
      });
      dialogRef.afterClosed().subscribe((dialogRef) => {
        if (dialogRef) {
          this.show_detail[id] = !this.show_detail[id];
        }
      });
    } else {
      this.show_detail[id] = !this.show_detail[id];
    }
  }

  contact_detail_toggle(id): void {
    if (this.show_contact_detail[id]) {
      if (this.contactDetail.hasChanged) {
        let title = "Seems like you have unsaved change.";
        let message = "Do you still want to close?";
        let confirm_data = { title: title, type: "confirm", message: message };
        const dialogRef = this.dialog.open(CustomDialogComponent, {
          maxWidth: "600px",
          data: confirm_data,
        });
        dialogRef.afterClosed().subscribe((dialogRef) => {
          if (dialogRef) {
            this.show_contact_detail[id] = !this.show_contact_detail[id];
          }
        });
      } else {
        this.show_contact_detail[id] = !this.show_contact_detail[id];
      }
    } else {
      this.show_contact_detail[id] = !this.show_contact_detail[id];
    }
  
  }

  createClient(): void {
    const dialogRef = this.dialog.open(ClientDetailComponent, {
      width: "1150px",
      height: "600px",
      data: {
        create_new: true,
      },
    });
    const sub = dialogRef.componentInstance.doneWithSave.subscribe((save) => {
      dialogRef.close();
      if (save) {
        window.location.reload();
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  openNewTab(link): void {
    window.open(link, "_blank");
  }

  findCountOpen(roles): number {
    return roles.filter((r) => r.status == 1 || r.status == 3).length;
  }
}
