// import { Component, OnInit, Input } from "@angular/core";
import { TalentService } from "../talent.service";
// import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import {
  Component,
  ViewChild,
  EventEmitter,
  Output,
  OnInit,
  AfterViewInit,
  Input,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { FormGroup, FormBuilder } from "@angular/forms";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";
@Component({
  selector: "app-contact-address-list",
  templateUrl: "./contact-address-list.component.html",
  styleUrls: ["./contact-address-list.component.css"],
})
export class ContactAddressListComponent implements OnInit {
  @Input() create_new: boolean = false;
  @Output() select_primary = new EventEmitter<any>();
  @Input() selected_id;
  @Input() client_id: number;
  // Parent Component View Name
  @Input() parent_view: string;

  // List of selected address
  selected_address: Array<object> = [];
  no_selected_address: Array<object> = [];
  address_list = [];
  edit_mode: boolean = false;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  formated_location: string;

  address_components: object = {
    label: "",
    comment: "",
    street: "",
    street_no: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  };

  constructor(private talentService: TalentService, public dialog: MatDialog) {}

  ngOnInit() {
    this.zoom = 12;
    this.latitude = 40.7551279;
    this.longitude = -73.9883636;
    this.getAddressList();
  }

  getAddressList() {
    this.talentService.getAddress(this.client_id).subscribe((res) => {
      this.address_list = res;
      this.selected_address = [];
      this.no_selected_address = [];
      for (let a of res) {
        if (this.selected_id.includes(a["id"])) {
          this.selected_address.push(a);
        } else {
          this.no_selected_address.push(a);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.create_new) {
      this.getAddressList();
      this.selected_address = [];
    }
  }

  onLocationSelected(location: Location) {
    this.latitude = location["latitude"];
    this.longitude = location["longitude"];
  }

  toggleStar(address_id, is_select) {
    if (is_select) {
      this.selected_id.push(address_id);
      this.select_primary.emit({
        selected_id: this.selected_id,
        address_id: address_id,
        is_select: is_select,
      });
    } else {
      this.selected_id = this.selected_id.filter((x) => x != address_id);
      this.select_primary.emit({
        selected_id: this.selected_id,
        address_id: address_id,
        is_select: is_select,
      });
    }
    this.selected_address = [];
    this.no_selected_address = [];
    for (let a of this.address_list) {
      if (this.selected_id.includes(a["id"])) {
        this.selected_address.push(a);
      } else {
        this.no_selected_address.push(a);
      }
    }
  }
}
