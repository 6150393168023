import { Component, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ActiveCandidatesComponent } from "./active-candidates/active-candidates.component";
import { CandidateDetailComponent } from "./candidate-detail/candidate-detail.component";
import { SearchComponent } from "./search/search.component";

import { ClientsComponent } from "./clients/clients.component";
import { ClientDetailComponent } from "./client-detail/client-detail.component";
import { ContactComponent } from "./contact/contact.component";
import { ContactDetailComponent } from "./contact-detail/contact-detail.component";
import { AddNewComponent } from "./add-new/add-new.component";
import { OpenRolesComponent } from "./open-roles/open-roles.component";
import { MyRolesComponent } from "./my-roles/my-roles.component";
import { TeamRolesComponent } from "./team-roles/team-roles.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuardService } from "./auth-guard/auth-guard.component";
import { ShortlistComponent } from "./shortlist/shortlist.component";
import { ShortlistedComponent } from "./shortlisted/shortlisted.component";
import { NotFound404Component } from "./not-found-404/not-found-404.component";
import { LogoutComponent } from "./logout/logout.component";
import { SavedCandidatesComponent } from "./saved-candidates/saved-candidates.component";
import { HelpComponent } from "./help/help.component";
import { InterviewComponent } from "./interview/interview.component";
import { DailyCandidateSummaryComponent } from "./daily-candidate-summary/daily-candidate-summary.component";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { RecommendationComponent } from "./recommendation/recommendation.component";
import { RoleRecommendationsComponent } from "./role-recommendations/role-recommendations.component";
import { RingCentralAuthComponent } from "./ring-central-auth/ring-central-auth.component";
import { UserManagementComponent } from "./user-management/user-management.component";
import { AiSummaryComponent } from "./ai-summary/ai-summary.component";
import { CommunicationManagementComponent } from "./communication-management/communication-management.component";
const routes: Routes = [
  // Home
  { path: "", redirectTo: "/active-candidates", pathMatch: "full" },
  { path: "user-management", component: UserManagementComponent },
  {
    path: "communication-management",
    component: CommunicationManagementComponent,
  },
  // Login
  { path: "login", component: LoginComponent },
  //Logout
  { path: "interview/:topic", component: InterviewComponent },

  { path: "help", component: HelpComponent, canActivate: [AuthGuardService] },
  { path: "logout", component: LogoutComponent },
  // Candidates
  {
    path: "active-candidates",
    component: ActiveCandidatesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "add-new",
    component: AddNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "daily-candidate-summary",
    component: DailyCandidateSummaryComponent,
    canActivate: [AuthGuardService],
  },

  // Clients
  {
    path: "clients",
    component: ClientsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "client/:id",
    component: ClientDetailComponent,
    canActivate: [AuthGuardService],
  },

  // Contact
  {
    path: "contacts",
    component: ContactComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "contact/:id",
    component: ContactDetailComponent,
    canActivate: [AuthGuardService],
  },
  // AI Generate the Candidate Summary by the Resume
  {
    path: "ai-summary",
    component: AiSummaryComponent,
    canActivate: [AuthGuardService],
  },
  // Roles
  {
    path: "open-roles",
    component: OpenRolesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "open-roles/:id",
    component: OpenRolesComponent,
    canActivate: [AuthGuardService],
  },

  // {
  //   path: "my-roles",
  //   component: MyRolesComponent,
  //   canActivate: [AuthGuardService],
  // },

  // {
  //   path: "team-roles",
  //   component: TeamRolesComponent,
  //   canActivate: [AuthGuardService],
  // },

  // Search and Detail
  {
    path: "search",
    component: SearchComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "candidate-detail/:id",
    component: CandidateDetailComponent,
    canActivate: [AuthGuardService],
  },
  // {
  //   path: "my-roles/search/:role_id",
  //   component: SearchComponent,
  //   canActivate: [AuthGuardService],
  // },

  {
    path: "shortlist",
    component: ShortlistComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "shortlisted/:shortlist_id",
    component: ShortlistedComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "savedCandidates/:role_id",
    component: SavedCandidatesComponent,
    canActivate: [AuthGuardService],
  },
  // {
  //   path: "my-roles/savedCandidates/:role_id",
  //   component: SavedCandidatesComponent,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: "team-roles/savedCandidates/:role_id",
  //   component: SavedCandidatesComponent,
  //   canActivate: [AuthGuardService],
  // },
  {
    path: "user-settings",
    component: UserSettingsComponent,
    canActivate: [AuthGuardService],
  },
  { path: "privacy-policy", component: PrivacyPolicyComponent },

  {
    path: "recommendation",
    component: RecommendationComponent,
    canActivate: [AuthGuardService],
  },
  // {
  //   path: "my-roles/recommendations/:role_id",
  //   component: RoleRecommendationsComponent,
  //   canActivate: [AuthGuardService],
  // },
  { path: "ringcentral/oauth2Callback", component: RingCentralAuthComponent },

  // 404 Not Found
  { path: "**", component: NotFound404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
