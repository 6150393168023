import { Inject,Optional,Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material'
import { TalentService } from '../talent.service';

@Component({
  selector: 'noteshistory',
  templateUrl: './noteshistory.component.html',
  styleUrls: ['./noteshistory.component.css']
})
export class NotesHistoryComponent implements OnInit {
  candidate_id;
  first_name;
  last_name;
  notes:any = [];

  constructor(@Optional() private dialogRef: MatDialogRef<NotesHistoryComponent>,  @Optional() @Inject(MAT_DIALOG_DATA) data, private talentService: TalentService) {
    console.log(data);
    this.candidate_id=data.candidate_id;
    this.first_name=data.first_name;
    this.last_name=data.last_name;
  }

 date;
 dateStr;
 update_time;
  ngOnInit() {
    this.talentService.getNotesHistory(this.candidate_id).subscribe((result) => {
      this.notes = result;
      for(var i=0;i<this.notes.length;i++){
       this.date=this.notes[i].update_time;
       this.dateStr=String(this.date);
       this.update_time=this.dateStr.replace('T', ' '); 
       console.log(this.update_time);
       this.notes[i].update_time=this.update_time;
      }
    });
    
  }
  
}