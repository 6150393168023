// import { environment } from './../environments/environment.prod';
import { Injectable, EventEmitter } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { MessageService } from "./message.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../environments/environment";
import * as io from "socket.io-client";
import RingCentralSDK from "ringcentral";
declare var google: any;

@Injectable()
export class TalentService {
  jobPreference = [
    {
      id: "0",
      name: "Perm",
      value: "Perm",
    },
    {
      id: "1",
      name: "C2C",
      value: "C2C",
    },
    {
      id: "2",
      name: "W2",
      value: "W2",
    },
  ];

  workingStatus = [
    {
      id: "0",
      name: "US Citizen",
      value: "US Citizen/Green Card",
      country: "US",
    },
    {
      id: "1",
      name: "Green Card",
      value: "Green Card",
      country: "US",
    },
    {
      id: "2",
      name: "H1-B Visa",
      value: "H1-B Visa",
      country: "US",
    },
    {
      id: "3",
      name: "OPT",
      value: "OPT",
      country: "US",
    },
    {
      id: "4",
      name: "EAD",
      value: "EAD",
      country: "US",
    },
    {
      id: "5",
      name: "I-140",
      value: "I-140",
      country: "US",
    },
    {
      id: "6",
      name: "TN",
      value: "TN",
      country: "US",
    },
    {
      id: "7",
      name: "Work Permit",
      value: "Work Permit",
      country: "Canada",
    },
    {
      id: "8",
      name: "Open Work Permit",
      value: "Open Work Permit",
      country: "Canada",
    },
    {
      id: "9",
      name: "Permanent Resident",
      value: "Permanent Resident",
      country: "Canada",
    },
    {
      id: "10",
      name: "Canadian Citizen",
      value: "Canadian Citizen",
      country: "Canada",
    },
  ];

  titles = [
    {
      id: "0",
      name: "Developer",
      value: "Developer",
    },
    {
      id: "1",
      name: "BA",
      value: "BA",
    },
    {
      id: "2",
      name: "PM",
      value: "PM",
    },
    {
      id: "3",
      name: "Lead Developer",
      value: "Lead Developer",
    },
    {
      id: "4",
      name: "Data Analyst",
      value: "Data Analyst",
    },
    {
      id: "5",
      name: "Quality Assurance",
      value: "Quality Assurance",
    },
    {
      id: "6",
      name: "Infrastructure Manager",
      value: "Infrastructure Manager",
    },
    {
      id: "7",
      name: "System Admin",
      value: "System Admin",
    },
    {
      id: "8",
      name: "Database Admin",
      value: "Database Admin",
    },
    {
      id: "9",
      name: "Manager",
      value: "Manager",
    },
    {
      id: "10",
      name: "Senior Manager",
      value: "Senior Manager",
    },
    {
      id: "11",
      name: "DevOps Engineer",
      value: "DevOps Engineer",
    },
    {
      id: "12",
      name: "Financial Analyst",
      value: "Financial Analyst",
    },
    {
      id: "13",
      name: "Quantitative Analyst",
      value: "Quantitative Analyst",
    },
  ];
  userSettings;
  gapiInitialized: boolean = false;
  pickerApiLoaded = false;
  rcsdk;
  gapiInitPromise;

  displayName = localStorage.getItem("name");
  displayID = localStorage.getItem("user_id");
  accountId = localStorage.getItem("account_id");
  UserName = localStorage.getItem("user_name");
  accountName = localStorage.getItem("account_name");
  accountAbbr = localStorage.getItem("account_abbr");
  is_admin = localStorage.getItem("is_admin") == "true" ? true : false;
  userId;
  createAuthorizationHeader() {
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
      // 'Content-Type': 'application/json'
    };
    let headers = new HttpHeaders(headerLogin);
    return { headers: headers };
  }

  httpOptions = this.createAuthorizationHeader();

  updateHttpOption() {
    this.httpOptions = this.createAuthorizationHeader();
    this.displayName = localStorage.getItem("name");
    if (localStorage.getItem("api_token")) {
      var base64Url = localStorage.getItem("api_token").split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var tokenPayload = JSON.parse(window.atob(base64));
      this.userId = tokenPayload ? tokenPayload.app_user_id : undefined;
    }
    console.log("display name is = " + this.displayName);
  }

  notificationCount = new EventEmitter();

  sendNotificationCount(notificationCount: string) {
    this.notificationCount.emit(notificationCount);
  }

  socket;
  soc_observable;

  socketConnection() {
    this.socket = io.connect("https://yodawe.com:49160");
    this.soc_observable = new Observable((observer) => {
      this.socket.on("socket_msg_ac", (socket_msg) => {
        console.log("Message = " + socket_msg);
        if (socket_msg == "Send Latest Active Candidates") {
          observer.next("new_active_candidates");
        }
      });
      this.socket.on("updateSavedCandidates", (n) => {
        observer.next("updateSavedCandidates");
      });
      return () => {
        this.socket.disconnect();
      };
    });
    return this.soc_observable;
  }

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {
    if (localStorage.getItem("api_token")) {
      var base64Url = localStorage.getItem("api_token").split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var tokenPayload = JSON.parse(window.atob(base64));
      this.userId = tokenPayload ? tokenPayload.app_user_id : undefined;
    }
    this.gapiInitPromise = new Promise((resolve, reject) => {
      gapi.load("client:auth2", () => this.initgapiClient(resolve, reject));
    });
    this.rcsdk = new RingCentralSDK({
      server: "https://platform.ringcentral.com",
      appKey: "Io0yzEyZRk6nN3gkyazYzg",
      appSecret: "Rcv9SR45QyaGXH5MPoGlYgcLghVxReRmWp-B1qVT6CLg",
      redirectUri: `${window.location.origin}/ringcentral/oauth2Callback`,
    });
  }
  initgapiClient = (resolve, reject) => {
    var CLIENT_ID =
      "558225817537-kmojf0f0btjk5dsk2n48300sdmfi89t5.apps.googleusercontent.com";
    var API_KEY = "AIzaSyCBCoOcPlKRoaiqkN4Vuhdp_QBssaSQ3Ns";
    var DISCOVERY_DOCS = [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
      "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
      "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
    ];
    var SCOPES =
      "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly";
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        () => {
          this.gapiInitialized = true;
          gapi.load("picker", { callback: this.onPickerApiLoad.bind(this) });
          // Listen for sign-in state changes.
          // gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
          if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
            gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse();
          }
          resolve(gapi);
        },
        function (error) {
          console.log(error);
        }
      );
  };

  async awaitGapiInit() {
    await this.gapiInitPromise;
  }

  // To check whether user needs to obtain the refresh token at this moment
  // If user is the organization user and no refresh token
  needOAuthRefreshToken() {
    let url = environment.API_BASE + "OAuth/hasToekn";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getCurrentGoogleUser() {
    let promise = new Promise(function (resolve, reject) {
      if (gapi.auth2 && gapi.auth2.getAuthInstance().isSignedIn.get()) {
        let currentUser = gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .getBasicProfile();

        var authInstance = gapi.auth2.getAuthInstance();
        var accessToken = authInstance.currentUser
          .get()
          .getAuthResponse().access_token;

        resolve(currentUser);
      } else {
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then(
            (n) => {
              let currentUser = gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getBasicProfile();
              resolve(currentUser);
            },
            (error) => {
              console.log(error);
              if (error.result && error.result.error) {
                reject(error.result.error.message);
              }
              reject(error.error);
            }
          );
      }
    });
    return promise;
  }

  getOauthRefreshToken() {
    const url = environment.API_BASE + "OAuth/refreshToken";
    this.http.get<Object[]>(url, this.httpOptions).subscribe((res) => {
      if (res["redirect_url"]) {
        window.location.href = res["redirect_url"];
      }
    });
    // return this.http.get<Object[]>(url, this.httpOptions);
  }

  onPickerApiLoad() {
    this.pickerApiLoaded = true;
  }
  saveSMS(recipient, sender, message, candidate_id) {
    var url = environment.API_BASE + "saveSMS";
    this.http
      .post(url, { recipient, sender, message, candidate_id }, this.httpOptions)
      .subscribe();
  }
  ringCentralSendSMS(phone, SMSText, candidate_id) {
    var platform = this.rcsdk.platform();
    this.getUserSettings().subscribe((result) => {
      this.userSettings = result;
      var fromPhone = this.userSettings.phone_work;
      var outerThis = this;
      platform.loggedIn().then((status) => {
        if (status) {
          platform
            .post("/account/~/extension/~/sms", {
              from: { phoneNumber: fromPhone },
              to: [{ phoneNumber: phone }],
              text: SMSText,
            })
            .then(function (response) {
              outerThis.saveSMS(phone, fromPhone, SMSText, candidate_id);
            })
            .catch(function (e) {
              alert(e.message);
            });
        } else {
          this.ringCentralLogin().then((n) => {
            platform
              .post("/account/~/extension/~/sms", {
                from: { phoneNumber: fromPhone },
                to: [{ phoneNumber: phone }],
                text: SMSText,
              })
              .then(function (response) {
                outerThis.saveSMS(phone, fromPhone, SMSText, candidate_id);
              })
              .catch(function (e) {
                alert(e.message);
              });
          });
        }
      });
    });
  }
  savePhoneCall(recipient, caller, candidate_id) {
    var url = environment.API_BASE + "savePhoneCall";
    this.http
      .post(url, { recipient, caller, candidate_id }, this.httpOptions)
      .subscribe();
  }
  ringCentralCallPhone(phone, candidate_id) {
    var platform = this.rcsdk.platform();
    this.getUserSettings().subscribe((result) => {
      this.userSettings = result;
      var fromPhone = this.userSettings.phone_work;
      var outerThis = this;
      platform.loggedIn().then((status) => {
        if (status) {
          platform
            .post("/account/~/extension/~/ring-out", {
              from: { phoneNumber: fromPhone },
              to: { phoneNumber: phone },
              playPrompt: false,
            })
            .then(function (response) {
              outerThis.savePhoneCall(phone, fromPhone, candidate_id);
            })
            .catch(function (e) {
              alert(e.message);
            });
        } else {
          this.ringCentralLogin().then((n) => {
            platform
              .post("/account/~/extension/~/ring-out", {
                from: { phoneNumber: fromPhone },
                to: { phoneNumber: phone },
                playPrompt: false,
              })
              .then(function (response) {
                outerThis.savePhoneCall(phone, fromPhone, candidate_id);
              })
              .catch(function (e) {
                alert(e.message);
              });
          });
        }
      });
    });
  }
  ringCentralLogin() {
    var outerThis = this;
    let promise = new Promise(function (resolve, reject) {
      var platform = outerThis.rcsdk.platform();
      var loginUrl = platform.loginUrl();
      platform
        .loginWindow({ url: loginUrl })
        .then((n) => {
          resolve(platform.login(n));
        })
        .catch((err) => {
          reject("Error");
        });
    });
    return promise;
  }

  googleDrivePicker() {
    let src;
    let doc;
    let promise = new Promise(function (resolve, reject) {
      if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
        gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .reloadAuthResponse()
          .then(
            (data) => {
              console.log(data);
              let view = new google.picker.View(google.picker.ViewId.DOCS);
              // view.setMimeTypes("image/png,image/jpeg,image/jpg,video/mp4");
              let pickerBuilder = new google.picker.PickerBuilder();
              let picker = pickerBuilder
                // enableFeature(google.picker.Feature.NAV_HIDDEN).
                .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                .setOAuthToken(data.access_token)
                .addView(view)
                .addView(new google.picker.DocsUploadView())
                .setCallback(function (e) {
                  if (
                    e[google.picker.Response.ACTION] ==
                    google.picker.Action.PICKED
                  ) {
                    doc = e[google.picker.Response.DOCUMENTS];
                    src = doc[google.picker.Document.URL];
                    resolve(doc);
                    console.log(
                      "Document selected is",
                      doc,
                      "and URL is ",
                      src
                    );
                  }
                })
                .build();
              picker.setVisible(true);
              // var elements= document.querySelectorAll<HTMLElement>('.picker-dialog') ;
              (<HTMLElement>(
                document.querySelector(".picker-dialog")
              )).style.position = "fixed";
            },
            (error) => {
              reject("reloadAuthResponse failed, " + error.result.error);
            }
          );
      } else {
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then(
            (n) => {
              // gapi.client["calendar"].events
              //   .insert({
              //     calendarId:
              //       "seq-technology.com_qia7rfu3ouipfh9k694m305o9o@group.calendar.google.com",
              //     sendUpdates: "all",
              //     resource: event,
              //   })
              //   .then(function (response) {
              //     console.log("Event Added");
              //     resolve("calendar updated");
              //   });
            },
            (error) => {
              console.log(error);
              if (error.result && error.result.error) {
                reject(error.result.error.message);
              }
              reject(error.error);
            }
          );
      }
    });
    return promise;
  }
  addGoogleCalendarEvent(event) {
    let promise = new Promise(function (resolve, reject) {
      if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
        gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .reloadAuthResponse()
          .then(
            () => {
              gapi.client["calendar"].events
                .insert({
                  calendarId:
                    "seq-technology.com_qia7rfu3ouipfh9k694m305o9o@group.calendar.google.com",
                  sendUpdates: "all",
                  resource: event,
                  supportsAttachments: true,
                })
                .then(
                  function (response) {
                    console.log("calend");
                    console.log("calendar updated");
                    resolve("calendar updated");
                  },
                  (error) => {
                    console.log(error);
                    if (error.result && error.result.error) {
                      reject(error.result.error.message);
                    }
                    reject(error.error);
                  }
                );
            },
            (error) => {
              reject("reloadAuthResponse failed, " + error.result.error);
            }
          );
      } else {
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then(
            (n) => {
              gapi.client["calendar"].events
                .insert({
                  calendarId:
                    "seq-technology.com_qia7rfu3ouipfh9k694m305o9o@group.calendar.google.com",
                  sendUpdates: "all",
                  resource: event,
                  supportsAttachments: true,
                })
                .then(function (response) {
                  console.log("Event Added");
                  resolve("calendar updated");
                });
            },
            (error) => {
              console.log(error);
              if (error.result && error.result.error) {
                reject(error.result.error.message);
              }
              reject(error.error);
            }
          );
      }
    });
    return promise;
  }

  addRecruiterCalendarEvent(event) {
    let promise = new Promise(function (resolve, reject) {
      if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
        gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .reloadAuthResponse()
          .then(
            () => {
              gapi.client["calendar"].events
                .insert({
                  calendarId:
                    "c_n1d68in8dh8isc3rgm39kgqbr0@group.calendar.google.com",
                  // 'c_i8tu6g1da83fnob8vrv4a4ejgc@group.calendar.google.com',
                  sendUpdates: "all",
                  resource: event,
                  supportsAttachments: true,
                })
                .then(
                  function (response) {
                    console.log("data", response);
                    resolve("calendar updated");
                  },
                  (error) => {
                    console.log(error);
                    if (error.result && error.result.error) {
                      reject(error.result.error.message);
                    }
                    reject(error.error);
                  }
                );
            },
            (error) => {
              reject("reloadAuthResponse failed, " + error.result.error);
            }
          );
      } else {
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then(
            (n) => {
              gapi.client["calendar"].events
                .insert({
                  calendarId:
                    "c_n1d68in8dh8isc3rgm39kgqbr0@group.calendar.google.com",
                  // 'c_i8tu6g1da83fnob8vrv4a4ejgc@group.calendar.google.com',
                  sendUpdates: "all",
                  resource: event,
                  supportsAttachments: true,
                })
                .then(function (response) {
                  console.log("Event Added");
                  resolve("calendar updated");
                });
            },
            (error) => {
              console.log(error);
              if (error.result && error.result.error) {
                reject(error.result.error.message);
              }
              reject(error.error);
            }
          );
      }
    });
    return promise;
  }

  addStartDateCalendarEvent(event) {
    let promise = new Promise(function (resolve, reject) {
      if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
        gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .reloadAuthResponse()
          .then(
            () => {
              gapi.client["calendar"].events
                .insert({
                  calendarId:
                    "seq-technology.com_ucuhmq6nsc2osknnfjlrl9dgoc@group.calendar.google.com",
                  sendUpdates: "all",
                  resource: event,
                  supportsAttachments: true,
                })
                .then(
                  function (response) {
                    console.log("calendar updated");
                    resolve("calendar updated");
                  },
                  (error) => {
                    console.log(error);
                    if (error.result && error.result.error) {
                      reject(error.result.error.message);
                    }
                    reject(error.error);
                  }
                );
            },
            (error) => {
              reject("reloadAuthResponse failed, " + error.result.error);
            }
          );
      } else {
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then(
            (n) => {
              gapi.client["calendar"].events
                .insert({
                  calendarId:
                    "seq-technology.com_ucuhmq6nsc2osknnfjlrl9dgoc@group.calendar.google.com",
                  sendUpdates: "all",
                  resource: event,
                  supportsAttachments: true,
                })
                .then(function (response) {
                  console.log("Event Added");
                  resolve("calendar updated");
                });
            },
            (error) => {
              console.log(error);
              if (error.result && error.result.error) {
                reject(error.result.error.message);
              }
              reject(error.error);
            }
          );
      }
    });
    return promise;
  }
  sendGmail(emailData) {
    var outerThis = this;
    if (
      gapi.auth2.getAuthInstance().isSignedIn.get() &&
      gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .hasGrantedScopes(
          "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly"
        )
    ) {
      gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .reloadAuthResponse()
        .then(() => {
          gapi.client["gmail"].users.settings.sendAs
            .list({ userId: "me" })
            .then(function (response) {
              var primarySendAs = response.result.sendAs.find(
                (n) => n.isPrimary
              );
              var primarySignature = primarySendAs && primarySendAs.signature;
              if (primarySignature) {
                emailData.message += primarySignature;
              }
              outerThis.createEmail(emailData).subscribe((message) => {
                gapi.client["gmail"].users.messages
                  .send({
                    userId: "me",
                    resource: message,
                  })
                  .then(function (response) {
                    console.log("Email Sent");
                  });
              });
            });
        });
    } else {
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(
          (n) => {
            gapi.client["gmail"].users.settings.sendAs
              .list({ userId: "me" })
              .then(function (response) {
                var primarySendAs = response.result.sendAs.find(
                  (n) => n.isPrimary
                );
                var primarySignature = primarySendAs && primarySendAs.signature;
                if (primarySignature) {
                  emailData.message += primarySignature;
                }
                outerThis.createEmail(emailData).subscribe((message) => {
                  gapi.client["gmail"].users.messages
                    .send({
                      userId: "me",
                      resource: message,
                    })
                    .then(function (response) {
                      console.log("Email Sent");
                    });
                });
              });
          },
          (error) => console.log(error)
        );
    }
  }

  updateSocket(): Observable<Object> {
    var url = environment.API_BASE + "updateSocket";
    return this.http.get<Object>(url, this.httpOptions);
  }

  viewValue = 0;
  setViewValue(value) {
    this.viewValue = value;
  }
  getViewValue() {
    return this.viewValue;
  }

  getYodaweUsersData(): Observable<Object[]> {
    const url = environment.API_BASE + "getYodaweUsersData";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getYodaweUsers(): Observable<Object[]> {
    const url = environment.API_BASE + "getYodaweUsers";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getYodaweUsersFullData(): Observable<Object[]> {
    const url = environment.API_BASE + "getYodaweUsersFullData";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  updateYodaweUser(data): Observable<Object> {
    const url = environment.API_BASE + "updateYodaweUser";
    return this.http.post<Object>(url, data, this.httpOptions);
  }

  createNewYodaweUser(data): Observable<Object> {
    const url = environment.API_BASE + "createNewYodaweUser";
    return this.http.post<Object>(url, data, this.httpOptions);
  }

  getUserRoleTypes(): Observable<Object[]> {
    const url = environment.API_BASE + "getUserRoleTypes";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  hasDuplicatedUserEmail(email): Observable<Object> {
    const url = environment.API_BASE + `hasDuplicatedUserEmail/${email}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getSourcer(): Observable<Object[]> {
    const url = environment.API_BASE + "getSourcer";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  /** Log a TalentService message with the MessageService */
  private log(message: string) {
    this.messageService.add("TalentService: " + message);
  }

  loginUser(code: string): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    var url = environment.API_BASE + "loginUser";
    var x = { code: code, app_name: environment.APP_NAME };
    return this.http.post(url, x, httpOptions);
  }

  getCandidateDetail(id: number): Observable<Object> {
    var url = environment.API_BASE + `candidateDetail/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }
  getCandidateWorkflow(id: number): Observable<any> {
    var url = environment.API_BASE + `listWorkflows/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }
  getCandidateCompensation(id: number): Observable<Object> {
    var url = environment.API_BASE + `getCompensation/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }
  getCandidateContractRate(id: number): Observable<Object> {
    var url = environment.API_BASE + `getContractRate/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }
  getCandidateWorkflowStates(id: number): Observable<Object> {
    var url = environment.API_BASE + `getWorkflowStates/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  getShortlistedCandidates(id): Observable<Object[]> {
    const url = environment.API_BASE + "getShortlistedCandidates/" + String(id);
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getMyActiveCandidates(): Observable<Object[]> {
    const url = environment.API_BASE + "myActiveCandidates";
    // console.log(this.http.get<Object[]>(url, this.httpOptions))
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getMyActiveCandidateResume(candidate_id): Observable<Object[]> {
    const url =
      environment.API_BASE + "myActiveCandidateResume/" + String(candidate_id);
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getActiveCandidateIds(): Observable<Object[]> {
    const url = environment.API_BASE + "getActivateCandidateIds";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getUserId(): Observable<Object[]> {
    const url = environment.API_BASE + "getUserId";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getRegions(): Observable<Object[]> {
    const url = environment.API_BASE + "listStates";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getClients(): Observable<Object[]> {
    const url = environment.API_BASE + "listClients";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getSkills(): Observable<Object[]> {
    const url = environment.API_BASE + "listSkills";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getResumeSource(): Observable<Object[]> {
    const url = environment.API_BASE + "listResumeJobBoardSource/1";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getBusinessKnowledge(): Observable<Object[]> {
    const url = environment.API_BASE + "listBusinessKnowledge";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getVendorSource(): Observable<Object[]> {
    const url = environment.API_BASE + "listVendorSource";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getWorkflowStatus(): Observable<Object[]> {
    const url = environment.API_BASE + "listWorkflowStatus";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getNotificationCount(): Observable<Object[]> {
    const url = environment.API_BASE + "getNotificationCount";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  searchCandidates(role_id: number, kwstring: string): Observable<any> {
    var x = { account_id: 1, kwstring: kwstring, role_id: role_id };
    const url = environment.API_BASE + "searchCandidates";
    return this.http.post(url, x, this.httpOptions);
  }

  getCandidatesByRolesId(role_id: number): Observable<any> {
    const url = environment.API_BASE + `getCandidatesByRoleId/${role_id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  searchCandidatesByPage(
    role_id: number,
    kwstring: string,
    pagenum: number,
    pagelen: number
  ): Observable<any> {
    var x = {
      role_id: role_id,
      kwstring: kwstring,
      pagenum: pagenum,
      pagelen: pagelen,
    };
    const url = environment.API_BASE + "searchCandidatesByPage";
    console.log(kwstring);
    return this.http.post(url, x, this.httpOptions);
  }

  saveCandidateDetail(candidate: Object): Observable<any> {
    var url = environment.API_BASE + "saveCandidate";
    var x = Object(candidate);

    if (x.secondary_skills) {
      if (x.secondary_skills.isArray) {
        x.secondary_skills = x.secondary_skills.join();
      }
    }
    return this.http.post(url, x, this.httpOptions);
  }

  saveRoleOwners(roleOwners: Object): Observable<any> {
    var url = environment.API_BASE + "saveRoleOwners";
    var x = Object(roleOwners);
    return this.http.post(url, x, this.httpOptions);
  }

  saveRoleSourcer(roleSourcer: Object): Observable<any> {
    var url = environment.API_BASE + "saveRoleSourcer";
    var x = Object(roleSourcer);
    return this.http.put(url, x, this.httpOptions);
  }
  saveRolePriority(rolePriority: Object): Observable<any> {
    var url = environment.API_BASE + "saveRolePriority";
    var x = Object(rolePriority);
    return this.http.put(url, x, this.httpOptions);
  }

  saveRoleRecruiter(rolePriority: Object): Observable<any> {
    var url = environment.API_BASE + "saveRoleRecruiter";
    var x = Object(rolePriority);
    return this.http.put(url, x, this.httpOptions);
  }

  saveRoleManager(rolePriority: Object): Observable<any> {
    var url = environment.API_BASE + "saveRoleManager";
    var x = Object(rolePriority);
    return this.http.put(url, x, this.httpOptions);
  }

  saveWorkPermission(roleWorkPermission: Object): Observable<any> {
    var url = environment.API_BASE + "saveRoleWorkPermission";
    var x = Object(roleWorkPermission);
    return this.http.put(url, x, this.httpOptions);
  }

  checkDuplicateCandidate(candidate: Object): Observable<any> {
    var url = environment.API_BASE + "checkDuplicateCandidateNew";
    var x = Object(candidate);
    return this.http.post(url, x, this.httpOptions);
  }

  saveWorkflow(workflow: Object): Observable<any> {
    var url = environment.API_BASE + "saveWorkflow";
    var x = Object(workflow);

    return this.http.post(url, x, this.httpOptions);
  }

  getShortlist(): Observable<any> {
    var url = environment.API_BASE + `getShortlist`;
    return this.http.get(url, this.httpOptions);
  }

  getTeamRoles(): Observable<any> {
    var url = environment.API_BASE + "getTeamRoles";
    return this.http.get(url, this.httpOptions);
  }

  getUserRoles(): Observable<any> {
    var url = environment.API_BASE + "getUserRoles";
    return this.http.get(url, this.httpOptions);
  }

  getRolesPipelineSummary(all_or_open): Observable<any> {
    var url = environment.API_BASE + "getRolesPipelineSummary/" + all_or_open;
    return this.http.get(url, this.httpOptions);
  }

  // getOpenRoles(all_or_open): Observable<any> {
  //   var url=environment.API_BASE+'getOpenRoles/'+all_or_open;
  //   return this.http.get(url, this.httpOptions);
  // }

  getOpenRolesNew(all_or_open): Observable<any> {
    var url = environment.API_BASE + "getOpenRolesNew/" + all_or_open;
    return this.http.get(url, this.httpOptions);
  }

  addWorkflow(workflow: Object): Observable<any> {
    var url = environment.API_BASE + "addWorkflow";
    var x = Object(workflow);
    return this.http.post(url, x, this.httpOptions);
  }

  addCandidateToRole(detail: Object): Observable<any> {
    var url = environment.API_BASE + "addCandidateToRole";
    var x = Object(detail);
    return this.http.post(url, x, this.httpOptions);
  }

  addRoleToUser(role_id: number): Observable<any> {
    var url = environment.API_BASE + "addRoleToUser";
    var x = Object();
    x.role_id = role_id;
    return this.http.post(url, x, this.httpOptions);
  }

  getShortlistInfo(shortlist_id: number): Observable<any> {
    var url = environment.API_BASE + `getShortlistInfo/${shortlist_id}`;
    return this.http.get(url, this.httpOptions);
  }

  getCandidateShortlists(candidate_id: number): Observable<any> {
    var url = environment.API_BASE + `getCandidateShortlists/${candidate_id}`;
    return this.http.get(url, this.httpOptions);
  }

  createShortlist(data): Observable<any> {
    var url = environment.API_BASE + "createShortlist";
    return this.http.post(url, data, this.httpOptions);
  }

  createNewShortlistFolder(data): Observable<any> {
    var url = environment.API_BASE + "createNewShortlistFolder";
    var x = Object();
    return this.http.post(url, data, this.httpOptions);
  }

  isShortlistNameExists(label): Observable<any> {
    var url = environment.API_BASE + "isShortlistNameExists";
    return this.http.post(url, label, this.httpOptions);
  }

  addCandidateToShortlist(
    label: String,
    candidate_id: number
  ): Observable<any> {
    var url = environment.API_BASE + "addCandidateToShortlist";
    var x = Object();
    x.label = label;
    x.candidate_id = candidate_id;
    return this.http.post(url, x, this.httpOptions);
  }

  updateShortlistName(old_label: String, new_label: String): Observable<any> {
    var url = environment.API_BASE + "updateShortlistName";
    var x = Object();
    x.old_label = old_label;
    x.new_label = new_label;
    return this.http.post(url, x, this.httpOptions);
  }

  editShortlistFolderInfo(data): Observable<any> {
    var url = environment.API_BASE + "editShortlistFolderInfo";
    return this.http.post(url, data, this.httpOptions);
  }

  updateShortlistFolder(data): Observable<any> {
    var url = environment.API_BASE + "updateShortlistFolder";
    return this.http.post(url, data, this.httpOptions);
  }

  getNotesHistory(id: number): Observable<Object> {
    var url = environment.API_BASE + `getNotesHistory/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  getAllTopicData(): Observable<any> {
    const url = environment.API_BASE + "getAllTopicData";
    return this.http.get(url, this.httpOptions);
  }

  getQandAByTopic(topic: String): Observable<Object> {
    var url = environment.API_BASE + `getQandAByTopic/${topic}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  addTopicQandA(
    topic: String,
    question: String,
    answer: String
  ): Observable<any> {
    var url = environment.API_BASE + "addTopicQandA";
    var x = Object();
    x.topic = topic;
    x.question = answer;
    x.answer = question;
    return this.http.post(url, x, this.httpOptions);
  }

  addTopic(topic: String): Observable<any> {
    var url = environment.API_BASE + "addTopic";
    var x = Object();
    x.topic = topic;
    return this.http.post(url, x, this.httpOptions);
  }

  updateTopicName(old_topic: String, new_topic: String): Observable<any> {
    var url = environment.API_BASE + "updateTopicName";
    var x = Object();
    x.old_topic = old_topic;
    x.new_topic = new_topic;
    return this.http.post(url, x, this.httpOptions);
  }

  editQandA(id: Number, question: String, answer: String): Observable<any> {
    var url = environment.API_BASE + "editQandA";
    var x = Object();
    x.id = id;
    x.question = question;
    x.answer = answer;
    return this.http.post(url, x, this.httpOptions);
  }

  removeCandidateFromShortlist(
    id: number,
    candidate_id: number
  ): Observable<any> {
    var url = environment.API_BASE + "removeCandidateFromShortlist";
    var x = Object();
    x.id = id;
    x.candidate_id = candidate_id;
    return this.http.post(url, x, this.httpOptions);
  }

  removeCandidateFromRole(
    role_id: number,
    candidate_id: number
  ): Observable<any> {
    var url = environment.API_BASE + "removeCandidateFromRole";
    var x = Object();
    x.role_id = role_id;
    x.candidate_id = candidate_id;
    return this.http.post(url, x, this.httpOptions);
  }

  savedCandidatesCount(role_id: number): Observable<any> {
    var url = environment.API_BASE + "savedCandidatesCount";
    var x = Object();
    x.role_id = role_id;
    return this.http.post(url, x, this.httpOptions);
  }

  removeRoleForUser(role_id: number): Observable<any> {
    var url = environment.API_BASE + "removeRoleForUser";
    var x = Object();
    x.role_id = role_id;
    return this.http.post(url, x, this.httpOptions);
  }

  getSavedCandidates(role_id: number): Observable<any> {
    var url = environment.API_BASE + "savedCandidates";
    var x = Object();
    x.role_id = role_id;
    return this.http.post(url, x, this.httpOptions);
  }

  getSavedCandidatesAll(role_id: number): Observable<any> {
    var url = environment.API_BASE + "savedCandidatesAll";
    var x = Object();
    x.role_id = role_id;
    return this.http.post(url, x, this.httpOptions);
  }

  getWorkflowStates(wf_id: number): Observable<any> {
    var url = environment.API_BASE + "getWorkflowStates/" + String(wf_id);
    return this.http.get(url, this.httpOptions);
  }

  fileUpload(formData: FormData): Observable<any> {
    const url = environment.API_BASE + "uploadFile";
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);

    /** No need to include Content-Type in Angular 4 */
    headers.append("Content-Type", "multipart/form-data");
    // headers.append('Accept', 'application/json');
    const httpOptions = { headers: headers };
    return this.http.post(url, formData, httpOptions);
  }
  otherFileUpload(formData: FormData): Observable<any> {
    const url = environment.API_BASE + "uploadOtherFile";
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);

    /** No need to include Content-Type in Angular 4 */
    headers.append("Content-Type", "multipart/form-data");
    // headers.append('Accept', 'application/json');
    const httpOptions = { headers: headers };
    return this.http.post(url, formData, httpOptions);
  }

  subSectionFileUpload(formData: FormData): Observable<any> {
    const url = environment.API_BASE + "uploadSubSectionFile";
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);

    /** No need to include Content-Type in Angular 4 */
    headers.append("Content-Type", "multipart/form-data");
    // headers.append('Accept', 'application/json');
    const httpOptions = { headers: headers };
    return this.http.post(url, formData, httpOptions);
  }

  addAlert(candidate_id: number, alert_date: string): Observable<any> {
    var url = environment.API_BASE + "addAlert";
    var x = Object();
    x.candidate_id = candidate_id;
    x.alert_date = alert_date;
    return this.http.post(url, x, this.httpOptions);
  }
  resetRoleRemovedCandidates(role_id: number): Observable<any> {
    var url = environment.API_BASE + "resetRoleRemovedCandidates";
    var x = Object();
    x.role_id = role_id;
    return this.http.post(url, x, this.httpOptions);
  }
  updateCandidateLastViewedTime(candidate_id) {
    var url = environment.API_BASE + "updateCandidateLastViewedTime";
    var x = Object();
    x.candidate_id = candidate_id;
    return this.http.post(url, x, this.httpOptions);
  }
  getCandidateLastViewedTimes() {
    var url = environment.API_BASE + "getCandidateLastViewedTimes";
    return this.http.get(url, this.httpOptions);
  }
  getCandidateLastViewedTime(id) {
    var url = environment.API_BASE + `getCandidateLastViewedTime/${id}`;
    return this.http.get(url, this.httpOptions);
  }
  getRoleStatuses() {
    var url = environment.API_BASE + "getRoleStatuses";
    return this.http.get(url, this.httpOptions);
  }
  getRoleTypes() {
    var url = environment.API_BASE + "getRoleTypes";
    return this.http.get(url, this.httpOptions);
  }
  getRoleCategories() {
    var url = environment.API_BASE + "getRoleCategories";
    return this.http.get(url, this.httpOptions);
  }
  getDisplayYesNo() {
    var url = environment.API_BASE + "getDisplayYesNo";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getLocationStates() {
    var url = environment.API_BASE + "getLocationStates";
    return this.http.get(url, this.httpOptions);
  }
  getCurrencyTypes() {
    var url = environment.API_BASE + "getCurrencyTypes";
    return this.http.get(url, this.httpOptions);
  }
  saveRole(role) {
    var url = environment.API_BASE + "saveRole";
    return this.http.post(url, role, this.httpOptions);
  }
  getUserSettings() {
    if (this.userSettings) {
      return of(this.userSettings);
    } else {
      var url = environment.API_BASE + "userSettings";
      return this.http.get(url, this.httpOptions);
    }
  }
  getSimilarRoles(role_id): Observable<any> {
    var url = environment.API_BASE + `similarRoles/${role_id}`;
    return this.http.get(url, this.httpOptions);
  }
  updateUserSettings(newSettings) {
    console.log(newSettings);
    var url = environment.API_BASE + "updateUserSettings";
    this.userSettings = newSettings;
    return this.http.post(url, newSettings, this.httpOptions);
  }
  getPDF(id) {
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
      "Content-Type": "application/json",
    };
    let headers = new HttpHeaders(headerLogin);
    var url = environment.API_BASE + "getPDF";
    return this.http.post(
      url,
      { filename: id },
      { headers: headers, responseType: "arraybuffer" }
    );
  }

  DownloadPDFFromHTML(data) {
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);
    var url = environment.API_BASE + "DownloadPDFFromHTML";
    return this.http.post(url, data, {
      headers: headers,
      responseType: "blob",
    });
  }

  getShortlistFilterOptions(): Observable<any> {
    var url = environment.API_BASE + "shortlistFilterOptions";
    return this.http.get(url, this.httpOptions);
  }
  updateFavoriteShortlist(id, favorite, type): Observable<any> {
    var url = environment.API_BASE + "updateFavoriteShortlist";
    return this.http.post(
      url,
      { shortlist_id: id, favorite, type_id: type },
      this.httpOptions
    );
  }
  getCandidateFlags(): Observable<Object[]> {
    const url = environment.API_BASE + "candidateFlags";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getClosedReasons(): Observable<Object[]> {
    const url = environment.API_BASE + "closedReasons";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  saveWorkflowClosed(workflow, reasons): Observable<any> {
    const url = environment.API_BASE + "saveWorkflowClosed";
    return this.http.post(url, { workflow, reasons }, this.httpOptions);
  }
  getTechnicalSkills(): Observable<Object[]> {
    const url = environment.API_BASE + "technicalSkills";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getBusinessAreas(): Observable<Object[]> {
    const url = environment.API_BASE + "businessAreas";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getTechnicalFrameworks(): Observable<Object[]> {
    const url = environment.API_BASE + "technicalFrameworks";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getFunctionalKnowledge(): Observable<Object[]> {
    const url = environment.API_BASE + "functionalKnowledge";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getTechnicalLibraries(): Observable<Object[]> {
    const url = environment.API_BASE + "technicalLibraries";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getVendorProducts(): Observable<Object[]> {
    const url = environment.API_BASE + "vendorProducts";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getWorkplaceTypes(): Observable<Object[]> {
    const url = environment.API_BASE + "workplaceTypes";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getRecommendations(role): Observable<Object> {
    const url = environment.API_BASE + "recommendations";
    return this.http.post(url, role, this.httpOptions);
  }
  saveRoleRecommendationParams(role): Observable<Object> {
    const url = environment.API_BASE + "saveRoleRecommendationParams";
    return this.http.post(url, role, this.httpOptions);
  }
  getRoleRecommendationParams(role_id): Observable<any> {
    var url = environment.API_BASE + `roleRecommendationParams/${role_id}`;
    return this.http.get(url, this.httpOptions);
  }
  getRoleRecommendedCandidates(role_id): Observable<any> {
    var url = environment.API_BASE + `roleRecommendedCandidates/${role_id}`;
    return this.http.get(url, this.httpOptions);
  }
  getRoleAddedOrRemovedCandidates(role_id): Observable<any> {
    var url = environment.API_BASE + `roleAddedOrRemovedCandidates/${role_id}`;
    return this.http.get(url, this.httpOptions);
  }
  getRole(role_id): Observable<any> {
    var url = environment.API_BASE + `role/${role_id}`;
    return this.http.get(url, this.httpOptions);
  }
  // createEmail(data): Observable<Object> {
  //   const url = environment.API_BASE + "createEmail";
  //   return this.http.post(url, data, this.httpOptions);
  // }
  createEmail(formData: FormData): Observable<any> {
    const url = environment.API_BASE + "createEmail";
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);
    headers.append("Content-Type", "multipart/form-data");
    const httpOptions = { headers: headers };
    return this.http.post(url, formData, httpOptions);
  }
  getCandidateCommunicationHistory(
    id: number,
    is_client_contact: number
  ): Observable<Object> {
    var url =
      environment.API_BASE +
      `candidateCommunicationHistory/${id}/${is_client_contact}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  getCommunicationHistory(id: number): Observable<Object> {
    var url = environment.API_BASE + `getCommunicationHistory/${id}/`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  getHelpSections(): Observable<any> {
    var url = environment.API_BASE + "helpSections";
    let res = this.http.get(url, this.httpOptions);
    return res;
  }

  getHelpSubSectionTitles(): Observable<any> {
    var url = environment.API_BASE + "helpSubSectionTitles";
    return this.http.get(url, this.httpOptions);
  }

  getHelpSubSectionById(id): Observable<any> {
    var url = environment.API_BASE + `helpSubSection/${id}`;
    return this.http.get<any>(url, this.httpOptions);
  }

  addHelpSections(title): Observable<Object> {
    var url = environment.API_BASE + "addHelpSections";
    return this.http.post(url, { title: title }, this.httpOptions);
  }

  addHelpSubSections(parent_id, title): Observable<Object> {
    var url = environment.API_BASE + "addHelpSubSections";
    return this.http.post(
      url,
      { parent_id: parent_id, title: title },
      this.httpOptions
    );
  }

  updateHelpSections(title): Observable<Object> {
    var url = environment.API_BASE + "updateHelpSections";
    return this.http.post(url, { title: title }, this.httpOptions);
  }

  updateHelpSubSections(data): Observable<Object> {
    var url = environment.API_BASE + "updateHelpSubSections";
    return this.http.post(url, data, this.httpOptions);
  }

  removeHelpSections(id): Observable<Object> {
    var url = environment.API_BASE + "removeHelpSections";
    return this.http.post(url, { id: id }, this.httpOptions);
  }

  removeHelpSubSections(id): Observable<Object> {
    var url = environment.API_BASE + "removeHelpSubSections";
    return this.http.post(url, { id: id }, this.httpOptions);
  }

  getAutoFillInfo(formData: FormData): Observable<any> {
    const url = environment.API_BASE + "getAutoFillInfo";
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);

    /** No need to include Content-Type in Angular 4 */
    headers.append("Content-Type", "multipart/form-data");
    // headers.append('Accept', 'application/json');
    const httpOptions = { headers: headers };
    return this.http.post(url, formData, httpOptions).timeout(50000);
  }

  generateAIResumeSummary(formData): Observable<any> {
    const url = environment.API_BASE + "generateAIResumeSummary";
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);

    headers.append("Content-Type", "multipart/form-data");
    const httpOptions = { headers: headers };
    return this.http.post(url, formData, httpOptions).timeout(60000);
  }
  checkResumeStatus(): Observable<any> {
    var url = environment.API_BASE + "checkResumeStatus";
    return this.http.post(url, {}, this.httpOptions);
  }
  udpateHtmlContent(data): Observable<any> {
    var url = environment.API_BASE + "udpateHtmlContent";
    return this.http.post(url, data, this.httpOptions);
  }

  getHtmlContent(data): Observable<any> {
    var url = environment.API_BASE + "getHtmlContent";
    return this.http.post(url, data, this.httpOptions);
  }
  getPdfContent(data): Observable<any> {
    const url = environment.API_BASE + "getPdfContent";
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);
    const httpOptions = { headers: headers, responseType: "blob" as "json" };
    return this.http.post(url, data, httpOptions);
  }
  generateAIResumeSummaryPdf(data): Observable<any> {
    const url = environment.API_BASE + "generateAIResumeSummaryPdf";
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);

    const httpOptions = { headers: headers, responseType: "blob" as "json" };
    return this.http.post(url, data, httpOptions);
  }
  getHelpPageContent(id): Observable<any> {
    var url = environment.API_BASE + `helpPageContent/${id}`;
    return this.http.get<any>(url, this.httpOptions);
  }
  saveHelpPageContent(data): Observable<Object> {
    const url = environment.API_BASE + "saveHelpPageContent";
    return this.http.post(url, data, this.httpOptions);
  }
  saveHelpImage(file): Observable<Object> {
    let formData = new FormData();
    formData.append("file", file);
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);

    /** No need to include Content-Type in Angular 4 */
    headers.append("Content-Type", "multipart/form-data");
    const url = environment.API_BASE + "saveHelpImage";
    const httpOptions = { headers: headers };
    return this.http.post(url, formData, httpOptions);
  }

  getYodaweClients() {
    const url = environment.API_BASE + "getYodaweClients";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getContactNotification() {
    const url = environment.API_BASE + "getContactNotification";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getYodaweClient(id) {
    const url = environment.API_BASE + `getYodaweClient/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  getClientNoteDetail(id) {
    const url = environment.API_BASE + `getClientNote/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  getClientFiles(id) {
    const url = environment.API_BASE + `getClientFiles/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  getRolesByClientName(name) {
    const url = environment.API_BASE + `getRolesByClientName/${name}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  getRolesByContactId(id) {
    const url = environment.API_BASE + `getRolesByContactId/${id}`;
    return this.http.get<Object>(url, this.httpOptions);
  }

  saveYodaweClient(data, create_new) {
    const url = environment.API_BASE + "saveYodaweClinet";
    data["create_new"] = create_new;
    let res = this.http.post(url, data, this.httpOptions);
    console.log(res);
    return res;
  }

  saveClientNote(id, new_note) {
    const url = environment.API_BASE + "saveClientNotes";
    let data = { id: id, new_note: new_note };
    return this.http.post(url, data, this.httpOptions);
  }

  getContacts(client_id) {
    // client_id: 0 means get all of clients, since the id in the db start from 1
    const url = environment.API_BASE + `getContacts/${client_id}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getContactsBasic(client_id) {
    // client_id: 0 means get all of clients, since the id in the db start from 1
    const url = environment.API_BASE + `getContactsBasic/${client_id}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getContactDetail(id) {
    const url = environment.API_BASE + `getContactDetail/${id}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getContactPrimaryAddress(id) {
    const url = environment.API_BASE + `getContactPrimaryAddress/${id}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getContactActivity(id) {
    const url = environment.API_BASE + `getContactActivity/${id}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  updateContactActivityFollowUp(data) {
    const url = environment.API_BASE + "updateContactActivityFollowUp";
    return this.http.post<Object[]>(url, data, this.httpOptions);
  }

  saveContact(data) {
    const url = environment.API_BASE + "saveContact";
    return this.http.post<any>(url, data, this.httpOptions);
  }

  createNewContactActivity(data) {
    const url = environment.API_BASE + "createNewContactActivity";
    return this.http.post<Object[]>(url, data, this.httpOptions);
  }

  getContactActivityNotesHistory(id) {
    const url = environment.API_BASE + `getContactActivityNotesHistory/${id}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  addContactActivityNote(data) {
    const url = environment.API_BASE + "addContactActivityNote";
    return this.http.post<Object[]>(url, data, this.httpOptions);
  }

  clientFileUpload(formData: FormData): Observable<any> {
    const url = environment.API_BASE + "uploadClientFile";
    const headerLogin = {
      Authorization: `Bearer ${localStorage.getItem("api_token")}`,
    };
    let headers = new HttpHeaders(headerLogin);

    /** No need to include Content-Type in Angular 4 */
    headers.append("Content-Type", "multipart/form-data");
    // headers.append('Accept', 'application/json');
    const httpOptions = { headers: headers };
    return this.http.post(url, formData, httpOptions);
  }

  addAddress(data) {
    const url = environment.API_BASE + "addAddress";
    return this.http.post<Object[]>(url, data, this.httpOptions);
  }

  getAddress(client_id) {
    const url = environment.API_BASE + `getAddress/${client_id}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  removeAddress(data) {
    const url = environment.API_BASE + "removeAddress";
    return this.http.post<Object[]>(url, data, this.httpOptions);
  }

  updateClientPrimaryAddress(data) {
    const url = environment.API_BASE + "updateClientPrimaryAddress";
    return this.http.post<Object[]>(url, data, this.httpOptions);
  }

  updateContactPrimaryAddress(data) {
    const url = environment.API_BASE + "updateContactPrimaryAddress";
    console.log(data);
    return this.http.post<Object[]>(url, data, this.httpOptions);
  }

  getWorkflowTotals() {
    const url = environment.API_BASE + "getWorkflowTotals";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getUserWorkflow() {
    const url = environment.API_BASE + "getUserWorkflow";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getAccountInfo() {
    const url = environment.API_BASE + "getAccountInfo";
    return this.http.get<Object[]>(url, this.httpOptions);
  }
  getRolePracticeGroups() {
    var url = environment.API_BASE + "getRolePracticeGroups";
    return this.http.get(url, this.httpOptions);
  }
  getClientCategory() {
    var url = environment.API_BASE + "getClientCategory";
    return this.http.get(url, this.httpOptions);
  }
  updateResumeDetails(data) {
    var url = environment.API_BASE + "updateResumeDetails";
    return this.http.post(url, data, this.httpOptions);
  }
  saveRolePracticeGroup(data): Observable<any> {
    var url = environment.API_BASE + "saveRolePracticeGroup";
    return this.http.post(url, data, this.httpOptions);
  }

  // Check current user whether can manage the communication settings when a user landing the app
  checkUserCommunicationAdmin() {
    const url = environment.API_BASE + "user/checkUserCommunicationAdmin";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getAllUserCommunicationSettings() {
    const url =
      environment.API_BASE + "communication/getAllUserCommunicationSettings";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getAllActiveUsers() {
    const url = environment.API_BASE + "user/getAllActiveUsers";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  updateUserCommunicationSettings(edit_user, edited_user_viewers) {
    const url =
      environment.API_BASE + "communication/updateUserCommunicationSettings";
    return this.http.post(
      url,
      { user: edit_user, user_viewers: edited_user_viewers },
      this.httpOptions
    );
  }

  getCommunicationAdmins() {
    const url = environment.API_BASE + "communication/getCommunicationAdmins";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  updateCommunicationAdmins(admins) {
    const url =
      environment.API_BASE + "communication/updateCommunicationAdmins";
    return this.http.post(url, admins, this.httpOptions);
  }

  sendEmailCode() {
    const url = environment.API_BASE + "sendEmailCode";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  verifyEmailCode(code) {
    const url = environment.API_BASE + "verifyEmailCode";
    return this.http.post(url, { code: code }, this.httpOptions);
  }

  hasVerifiedEmailCode() {
    const url = environment.API_BASE + "hasVerifiedEmailCode";
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  updateCommunicationViewHistory(user_id, has_viewed) {
    const url =
      environment.API_BASE + "communication/updateCommunicationViewHistory";
    return this.http.post(
      url,
      { user_id: user_id, has_viewed: has_viewed },
      this.httpOptions
    );
  }

  getCommunicationViewHistory(contact_id) {
    const url =
      environment.API_BASE +
      `communication/getCommunicationViewHistory/${contact_id}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  getContactViewer(contact_id) {
    const url =
      environment.API_BASE + `communication/contact/getViewers/${contact_id}`;
    return this.http.get<Object[]>(url, this.httpOptions);
  }

  updateContactViewer(contact_id, viewers) {
    const url = environment.API_BASE + `communication/contact/updateViewers`;
    return this.http.post(
      url,
      { viewers: viewers, contact_id: contact_id },
      this.httpOptions
    );
  }
}
