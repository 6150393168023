import { Inject, Optional, Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material";
import { TalentService } from "../../talent.service";
import { saveAs } from "file-saver";

@Component({
  selector: "app-role-template-generator",
  templateUrl: "./role-template-generator.component.html",
  styleUrls: ["./role-template-generator.component.css"],
})
export class RoleTemplateGeneratorComponent implements OnInit {
  data;
  seq_description: string = `<p>SEQ Technology is an IT Services firm that provides staff augmentation, project and permanent placement
  opportunities for qualified candidates at prominent clients throughout North America. Owing to
  relationships that have been cultivated through multiple decades in the technology industry, SEQ operates
  offices in New York, Washington, D.C. and Toronto and services clients in the Financial Services, Technology,
  Healthcare and Federal Services sectors. Despite being a growing, multinational company, SEQ is deeply
  committed to fostering a warm and welcoming environment for all that work with us, contributing to our
  reputation as being one of the best places to work everywhere we operate. As evidenced by our name, SEQ
  is committed to Service, Execution and Quality, both in regards to client service and consultant care. We
  look forward to you joining our team! You can discover more about SEQ at<nobr> <a href="https://seqtechnology.com" target="_blank" style="display:inline">www.seqtechnology.com</a></p>`;
  eeo_disclosure: string = `<p>SEQ Technology LLC provides equal employment opportunities to all employees and applicants for
  employment and prohibits discrimination and harassment of any type without regard to race, color,
  religion, age, sex, national origin, disability status, genetics, protected veteran status, sexual orientation,
  gender identity or expression, or any other characteristic protected by federal, state or local laws.<br/>
  This policy applies to all terms and conditions of employment, including recruiting, hiring, placement,
  promotion, termination, layoff, recall, transfer, leaves of absence, compensation and training.</p>`;
  constructor(
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    private talentService: TalentService
  ) {
    this.data = data;
  }

  ngOnInit() {}

  copyToClipboard(rich_text) {
    function listener(e) {
      e.clipboardData.setData("text/html", rich_text);
      e.clipboardData.setData("text/plain", rich_text);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  }

  downloadTemplate() {
    let html_string = "";
    html_string += `<h3>Job Title: ${this.data.title}</h3>`;
    html_string += `<h3>Open Date: ${this.data.open_date}</h3><br>`;
    html_string += `<br> ${this.seq_description}<br>`;
    html_string += `<br>${this.data.client_description
      .split("<br>")
      .join("")}<br>`;
    html_string += `<br> ${this.data.description.split("<br>").join("")}<br>`;
    if (this.data.new_york_disclosure != "") {
      html_string += `<br> ${this.data.new_york_disclosure
        .split("<br>")
        .join("")}<br>`;
    }
    html_string += `<br> ${this.eeo_disclosure}<br>`;
    html_string = html_string
      .split("color: red")
      .join("color : rgda(191, 15, 15)");
    html_string = html_string
      .split("color: blue")
      .join("color : rgda(42, 96, 184)");
    html_string = html_string.split(", 0.9").join("");
    let filename = `SEQ Job-${this.data.client}-${this.data.title}-${this.data.open_date}`;
    filename = filename.split("/").join("-");
    let arg_data = { html_string: html_string, filename: filename };
    this.talentService.DownloadPDFFromHTML(arg_data).subscribe((res) => {
      const file = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      saveAs(file, filename);
    });
  }
}
