import { Inject, Optional, Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material";
import { TalentService } from "../talent.service";

@Component({
  selector: "app-communication-view-history",
  templateUrl: "./communication-view-history.component.html",
  styleUrls: ["./communication-view-history.component.css"],
})
export class CommunicationViewHistoryComponent implements OnInit {
  user_id;
  view_history: any = [];
  is_loading: boolean = true;
  sort_type: string = "view_time";
  sort_asc: boolean = false;

  constructor(
    @Optional()
    private dialogRef: MatDialogRef<CommunicationViewHistoryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    private talentService: TalentService
  ) {
    this.user_id = data.user_id;
  }

  ngOnInit() {
    this.talentService
      .getCommunicationViewHistory(this.user_id)
      .subscribe((result) => {
        this.view_history = result;
        this.is_loading = false;
      });
  }
}
