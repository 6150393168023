import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Optional,
  Inject,
} from "@angular/core";
import { TalentService } from "../talent.service";
import { ActivatedRoute } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";
@Component({
  selector: "app-client-detail",
  templateUrl: "./client-detail.component.html",
  styleUrls: ["./client-detail.component.css"],
})
export class ClientDetailComponent implements OnInit {
  @Input() id;
  @Input() contacts;
  @Input() create_new: Boolean = false;
  @Output() doneWithSave = new EventEmitter<boolean>();
  @Output() onCloseEvent = new EventEmitter<any>();
  client: Object = {};
  notes: any = [];
  roles: any = [];
  sortType: string = "last_modified";
  new_note: string = "";
  isLoading: boolean = false;
  hasDone: boolean = false;
  show_detail_list: Array<Boolean> = [];
  attachments: any = [];

  client_category: any=[];

  user_drop_down_data: any;

  hasChanged: boolean = false;
  isChildComponent: boolean = true;

  constructor(
    private talentService: TalentService,
    private route: ActivatedRoute,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    if (data && data.create_new) {
      this.create_new = data.create_new;
    }
  }

  ngOnInit() {
    this.getClientDetail();
  }

  getClientDetail(): void {
    if (this.create_new) {
      this.client = {
        company_name: "",
        primary_phone: "",
        website: "",
        owner: 0,
        category:null
      };
      this.getYodaweUsersData();
    } else {
      if (!this.id) {
        this.isChildComponent = false;
        const id = +this.route.snapshot.paramMap.get("id");
        if (id) {
          this.id = id;
        }
      }

      this.talentService.getYodaweClient(this.id).subscribe((client) => {
        this.client = client;
        this.client["files"] = [];
        this.getRolesDetail();
      });
      this.getYodaweUsersData();
      this.getContacts();
      this.getNotesDetail();
      this.getAttachments();
    }
    this.talentService.getClientCategory().subscribe((category) => {
      this.client_category=category;
    })
  }

  getYodaweUsersData() {
    this.talentService.getYodaweUsersData().subscribe((yodawe_users) => {
      this.user_drop_down_data = yodawe_users;
      this.user_drop_down_data.shift();
      this.user_drop_down_data.unshift({ id: 0, name: "ALL" });
      if (this.create_new) {
        for (let user of this.user_drop_down_data) {
          if (
            user["name"].toLowerCase() ==
            this.talentService.displayName.toLowerCase()
          ) {
            this.client["owner"] = user.id;
            break;
          }
        }
      }
    });
  }

  getContacts(): void {
    this.talentService.getContacts(this.id).subscribe((contacts) => {
      this.contacts = contacts;
    });
  }

  getNotesDetail(): void {
    this.talentService
      .getClientNoteDetail(this.id)
      .subscribe((notes) => (this.notes = notes));
  }

  getAttachments(): void {
    this.talentService.getClientFiles(this.id).subscribe((files) => {
      this.client["files"] = files;
    });
  }

  getRolesDetail(): void {
    this.talentService
      .getRolesByClientName(this.client["company_name"])
      .subscribe((roles) => {
        this.roles = roles;
      });
  }

  attachmentDrop(event): void {
    for (const item of event) {
      this.attachments.push(item);
    }
  }

  attachmentUpload(event): void {
    let attachmentList = event.target.files;
    for (const item of attachmentList) {
      this.attachments.push(item);
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  formatTime(lastModified) {
    let modified_date = new Date(lastModified);
    var curr_date = modified_date.getDate();
    var curr_month = modified_date.getMonth() + 1; //Months are zero based
    var curr_year = modified_date.getFullYear();
    return curr_year + "-" + curr_month + "-" + curr_date;
  }

  removeattachment(i): void {
    this.attachments.splice(i, 1);
  }

  select_primary_address(eventData: { address_id: number; client_id: number }) {
    this.talentService
      .updateClientPrimaryAddress(eventData)
      .subscribe((res) => {
        console.log("Set new primary address");
      });
  }

  finishSave(): void {
    console.log("Save Changes");
    this.isLoading = false;
    this.hasDone = true;
    let title = "Confirmation";
    let message = "Client changes saved successfully";
    let confirm_data = { title: title, type: "info", message: message };
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      maxWidth: "600px",
      data: confirm_data,
    });
    dialogRef.afterClosed().subscribe((dialogRef) => {
      if (dialogRef) {
        window.location.reload();
      }
    });
  
  }

  saveClientFile(client_id): void {
    let clientFileFormData = new FormData();
    for (const file of this.attachments) {
      clientFileFormData.append("file", file);
    }
    clientFileFormData.append("client_id", client_id);
    this.talentService.clientFileUpload(clientFileFormData).subscribe(
      () => {
        console.log("Uploded attachments");
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      },
      () => {
        this.finishSave();
      }
    );
  }

  save(): void {
    let validate = [];
    if (this.client["name"] == "") {
      validate.push("Please enter a client name");
    }

    if (this.client["website"]) {
      if (!this.client["website"].includes("http")) {
        validate.push(
          "Website Address format is incorrect, please include the https or http as prefix"
        );
      }
    }

    if (validate.length > 0) {
      let confirm_data = {};

      confirm_data["type"] = "validate";
      confirm_data["title"] =
        "Please fix the following issue(s) to create a new address";
      confirm_data["validate"] = validate;

      this.dialog.open(CustomDialogComponent, {
        maxWidth: "800px",
        data: confirm_data,
      });
    } else {
      this.isLoading = true;
      this.talentService
        .saveYodaweClient(this.client, this.create_new)
        .subscribe(
          (res) => {
            if (this.new_note != "") {
              this.talentService
                .saveClientNote(res["id"], this.new_note)
                .subscribe(
                  () => {
                    console.log("Finished");
                  },
                  (error) => {
                    console.log(error);
                    this.isLoading = false;
                  },
                  () => {
                    if (this.attachments.length !== 0) {
                      this.saveClientFile(res["id"]);
                    } else {
                      this.finishSave();
                    }
                  }
                );
            } else {
              if (this.attachments.length !== 0) {
                this.saveClientFile(res["id"]);
              } else {
                this.finishSave();
              }
            }
          },
          (error) => {
            // window.alert("Client name already exists");
            window.alert(error.error);
            this.isLoading = false;
          },
          () => {}
        );
    }
  }

  updateHasChanged() {
    this.hasChanged = true;
  }

  handleClose() {
    if (this.create_new) {
      if (this.hasChanged) {
        let title = "Seems like you have unsaved change.";
        let message = "Do you still want to close?";
        let confirm_data = { title: title, type: "confirm", message: message };
        const dialogRef = this.dialog.open(CustomDialogComponent, {
          maxWidth: "600px",
          data: confirm_data,
        });
        dialogRef.afterClosed().subscribe((dialogRef) => {
          if (dialogRef) {
            this.dialog.closeAll();
          }
        });
      } else {
        this.dialog.closeAll();
      }
    } else {
      this.onCloseEvent.emit();
    }
  }
}
