import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cFilter'
})
export class ActiveCandidatesFilter  {

   transform(input,x) {
   if (x === undefined || x == ""){
            return input;
           }
           var y = x.split(/[\s,]+/)
           function checkValue(e) {
             var z=e.search_string;
             for (var i=0;i< y.length;i++){
                 if(z.indexOf(y[i].toLowerCase()) < 0 )
                      return false;
             }
             return true;
           };
           if(input){
             return input.filter(checkValue);
           }
           return [];
         }

}

@Pipe({
  name: 'orderBy'
})
export class OrderBySort  {

   transform(input,x,reverse) {
      if (x === undefined || x == "" || input === undefined ){ return input; } 
      return input.sort(function(a,b) {
        // let a1 = a[x] || '';
        // let b1 = b[x] || '';
         if(!reverse){
            if(!a[x]){
              return 1;
            }
            if(!b[x]){
              return -1
            }
            if (b[x]===a[x]){
              return 0
            }
          return String(b[x]).localeCompare(a[x],undefined,{numeric:true}); 
         }
        if(!a[x]){
          return 1;
        }
        if(!b[x]){
          return -1
        }
        if (b[x]===a[x]){
          return 0
        }
        return String(a[x]).localeCompare(b[x],undefined,{numeric:true});
       }); 


   }

}

@Pipe({
  name: 'getCount'
})
export class GetCount  {
   transform(input) {
    let green = 0
    let yellow = 0
    let red = 0
    for (let role of input) {
      if (role.days_posted < 14) {
        green +=1 
      }
      else if (role.days_posted < 60 ) {
        yellow += 1 
      }
      else {
        red += 1
      }
    }
    let res = {}
    res['green'] = green
    res['yellow'] = yellow
    res['red'] =red
    return res
   }
}
