import { Input, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TalentService } from "../talent.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-saved-candidates",
  templateUrl: "./saved-candidates.component.html",
  styleUrls: ["./saved-candidates.component.css"],
})
export class SavedCandidatesComponent implements OnInit {
  p;

  makeArray(x): any {
    return Array(x).fill(1);
  }

  @Input() keywords: string;
  @Input() filter: string;
  @Input() roleIdInput: number;
  @Input() initialFilter: string;
  candidates: any[] = [];
  sortType: string = "last_update";
  sortReverse: boolean = false;
  shortlist_name;
  show_detail: Object = {};
  pdf_show: Object = {};
  active_candidates: any = {};
  role_id;
  role_title;
  role_client;
  roles: any = [];
  active_only: boolean = true;
  pdf: any = {};

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private talentService: TalentService
  ) {}

  ngOnInit() {
    if (this.roleIdInput) {
      this.role_id = this.roleIdInput;
    } else {
      this.role_id = this.route.snapshot.paramMap.get("role_id");
    }
    if (this.initialFilter) {
      this.filter = this.initialFilter;
    }
    this.getSavedCandidates(this.role_id);
    this.getRoleDetail(this.role_id);
    // this.talentService.socketConnection().subscribe(message => {
    //   if(message==="updateSavedCandidates"||"new_active_candidates"){
    //     console.log("updateSavedCandidates");
    //     this.getSavedCandidates(this.role_id);
    //   }
    // });
  }

  getRoleDetail(role_id): void {
    this.talentService.getUserRoles().subscribe((roles) => {
      this.roles = roles;
      // console.log("roles:" + roles);

      for (var i = 0; i < this.roles.length; i++) {
        if (role_id == this.roles[i].role_id) {
          this.role_title = this.roles[i].title;
          console.log(roles[i]);
          this.role_client = this.roles[i].client;
        }
      }
      console.log(this.role_title);
    });
  }

  getSavedCandidates(role_id): void {
    if (this.active_only == true) {
      this.talentService.getSavedCandidates(role_id).subscribe((candidates) => {
        this.candidates = candidates;
        console.log(candidates);
      });
    } else {
      this.talentService
        .getSavedCandidatesAll(role_id)
        .subscribe((candidates) => {
          this.candidates = candidates;
          console.log(candidates);
        });
    }
  }

  Checkbox() {
    console.log(this.active_only);
    this.getSavedCandidates(this.role_id);
  }

  isdisabled1 = [];

  removeFromRole(candidate_id): void {
    this.isdisabled1[candidate_id] = true;
    var role_id = this.role_id;
    this.talentService
      .removeCandidateFromRole(role_id, candidate_id)
      .subscribe((result) => {
        console.log("result" + result);
      });
  }

  detail_toggle(x): void {
    var id = x.id;
    this.show_detail[id] = !this.show_detail[id];
    console.log(id);
    console.log(this.show_detail[id]);
    if (this.show_detail[id]) {
      this.pdf_show[id] = false;
    }
  }
  pdf_toggle(x): void {
    var id = x.id;
    // this.pdf_show[id]=!this.pdf_show[id];
    x.pdf_file_id =
      "https://www.yodawe.com/pdf_viewer/web/viewer.html?file=/docs/resumes/" +
      x.file_id +
      "?" +
      x.filename;
    if (!this.pdf_show[id]) {
      this.talentService
        .updateCandidateLastViewedTime(id)
        .subscribe((result) => console.log(result));
      this.show_detail[id] = false;
      this.talentService.getPDF(x.file_id).subscribe((result) => {
        this.pdf[x.id] = new Uint8Array(result);
        this.pdf_show[id] = !this.pdf_show[id];
      });
    } else {
      this.pdf_show[id] = !this.pdf_show[id];
    }
  }
  cleanURL(url) {
    console.log(url);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  // getPDF(url){
  //   return this.talentService.getPDF(url);
  // }

  receiveMessage($event) {
    console.log($event.id);
    console.log("received event" + event);
    for (var i = 0; i < this.candidates.length; i++) {
      if (this.candidates[i]) {
        if (this.candidates[i].id == $event.id) {
          // not the best but
          this.candidates[i].first_name = $event.first_name;
          this.candidates[i].last_name = $event.last_name;
          this.candidates[i].phone_mobile = $event.phone_mobile;
          this.candidates[i].email_address = $event.email_address;
          this.candidates[i].primary_skill = $event.primary_skill_string;
          this.candidates[i].rating = $event.rating;
          this.candidates[i].job_preference = $event.job_preference;
          this.candidates[i].working_status = $event.working_status;
          this.candidates[i].comp_expectation = $event.comp_expectation;
        }
      }
    }
  }
  receiveMessageWorkflow($event) {
    for (var i = 0; i < this.candidates.length; i++) {
      if (this.candidates[i]) {
        if (this.candidates[i].id == $event[0].candidate_id) {
          for (var j = 0; j < $event.length; j++) {
            this.candidates[i].latest_status = $event[0].latest_status;
          }
        }
      }
    }
  }
}
