import { Inject, Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { TalentService }  from '../talent.service';
import { OpenRolesComponent} from '../open-roles/open-roles.component';
import { WorkflowHistoryComponent } from '../workflow-history/workflow-history.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import {NotesHistoryComponent} from '../noteshistory/noteshistory.component';




export interface DialogData {
  first_name: string;
  last_name: string;
  override:string;
}
@Component({
  selector: 'duplicate-override-dialog',
  templateUrl: 'duplicate-override-dialog.component.html',
})
export class DuplicateOverrideDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DuplicateOverrideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onClick(value): void {
    this.dialogRef.close(value);
  }

}



