import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Pipe,
  ViewChild,
  Inject,
  Optional,
  ViewChildren,
  AfterViewInit,
} from "@angular/core";
import { TalentService } from "../talent.service";
import { CandidateDetailComponent } from "../candidate-detail/candidate-detail.component";
import { DomSanitizer } from "@angular/platform-browser";
import { StarRatingComponent } from "../star-rating/star-rating.component";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";
import * as io from "socket.io-client";

@Component({
  selector: "active-candidates",
  templateUrl: "./active-candidates.component.html",
  styleUrls: ["./active-candidates.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveCandidatesComponent implements OnInit {
  @ViewChild("pdfViewerOnDemand", { static: false }) pdfViewerOnDemand;
  @ViewChild("candidateDetailComponent", { static: false })
  candidateDetail: CandidateDetailComponent;
  private candidateDetailWatch: CandidateDetailComponent;
  @Input() filter: string;
  show_detail: Object = {};
  pdf_show: Object = {};
  sortType: string = "workflow_last_update";
  sortReverse: boolean = false;
  active_candidates: any[];
  id;
  displayName;
  socket_connection;
  emitCandidateDetail: number[] = [];
  indexEmitCandidateDetail;
  emitResume: number[] = [];
  indexEmitResume;
  nameId;
  viewValue;
  total_active_candidates: any[];
  user_drop_down_data;
  activeCandidatesContext = "Basic";
  notificationId = 999;
  notificationCount;
  lastViewedTimes: Object = {};
  userSettings: any = {};
  pdf: any = {};
  isLoading: boolean = false;
  constructor(
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private talentService: TalentService,
    @Optional() @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data) {
      this.activeCandidatesContext = data["activeCandidatesContext"];
    }
  }

  ngOnInit() {
    this.talentService.getYodaweUsersData().subscribe((yodawe_users) => {
      this.user_drop_down_data = yodawe_users;

      for (let x of yodawe_users) {
        if (
          x["name"].toLowerCase() ==
          this.talentService.displayName.toLowerCase()
        ) {
          this.nameId = +x["id"];
          this.notificationId = +x["id"];
          break;
        } else {
          this.nameId = 999;
        }
      }
      this.viewValue = this.talentService.getViewValue();
      if (this.viewValue != 0) {
        this.nameId = this.viewValue;
      }
      if (this.nameId == 1) {
        this.nameId = 999;
      }
      this.getMyActiveCandidates();
    });
    // this.socket_connection = this.talentService.socketConnection().subscribe(active_candidates => {
    //   this.getMyActiveCandidates()
    // });
    // this.isLoading = false;
    this.talentService.getNotificationCount().subscribe((notificationCount) => {
      this.notificationCount = notificationCount;
      this.talentService.sendNotificationCount(this.notificationCount);
    });
    this.getCandidateLastViewedTimes();
    this.talentService.getUserSettings().subscribe((result) => {
      this.userSettings = result;
    });
  }

  // ngAfterViewInt(): void {
  //   this.candidateDetail.changes.
  // }

  ngOnDestroy() {
    // this.socket_connection.unsubscribe()
  }

  getCandidateLastViewedTimes(): void {
    this.talentService.getCandidateLastViewedTimes().subscribe((times) => {
      this.lastViewedTimes = times;
    });
  }

  getMyActiveCandidates(): void {
    // TODO: add this active
    // if (this.activeCandidatesContext == "fromNotification") {

    // }
    this.talentService
      .getMyActiveCandidates()
      .subscribe((my_active_candidates) => {
        this.total_active_candidates = my_active_candidates;
        this.active_candidates = this.displayCandidates();
        for (var i = 0; i < this.active_candidates.length; i++) {
          this.id = this.active_candidates[i].id;
        }
      });
  }

  displayCandidates() {
    var filtered_ac: any[];
    var unfiltered_ac = Array.from(this.total_active_candidates);
    if (this.activeCandidatesContext == "fromNotification") {
      var j = unfiltered_ac.length;
      while (j--) {
        if (unfiltered_ac[j].user_id.indexOf(+this.notificationId) > -1) {
        } else {
          unfiltered_ac.splice(j, 1);
        }
      }
      unfiltered_ac = this.notificationCandidates(unfiltered_ac);
      return unfiltered_ac;
    } else {
      if (this.nameId != 999) {
        var j = unfiltered_ac.length;
        while (j--) {
          if (unfiltered_ac[j].user_id.indexOf(+this.nameId) > -1) {
          } else {
            unfiltered_ac.splice(j, 1);
          }
        }
        filtered_ac = unfiltered_ac;
        return filtered_ac;
      } else {
        return unfiltered_ac;
      }
    }
  }

  notificationCandidates(filteredCandidates) {
    var d14 = new Date(new Date().setDate(new Date().getDate() - 7));
    var notificationArray = filteredCandidates;
    var j = notificationArray.length;
    while (j--) {
      var k = notificationArray[j].workflow.length;
      while (k--) {
        var date_workflow = new Date(
          notificationArray[j].workflow[k].last_update
        );
        if (d14 < date_workflow) {
          notificationArray[j].workflow.splice(k, 1);
        } else {
          if (
            notificationArray[j].workflow[k].wf_user_id == this.notificationId
          ) {
          } else {
            notificationArray[j].workflow.splice(k, 1);
          }
        }
      }
      if (notificationArray[j].workflow.length == 0) {
        notificationArray.splice(j, 1);
      }
    }

    return notificationArray;
  }

  selectUser() {
    this.active_candidates = this.displayCandidates();
    this.talentService.setViewValue(this.nameId);
  }

  detail_toggle(id): void {
    console.log(this.candidateDetail);
    if (
      this.show_detail[id] &&
      this.candidateDetail &&
      this.candidateDetail.hasChanged
    ) {
      let title = "Seems like you have unsaved change.";
      let message = "Do you still want to close?";
      let confirm_data = { title: title, type: "confirm", message: message };
      const dialogRef = this.dialog.open(CustomDialogComponent, {
        maxWidth: "600px",
        data: confirm_data,
      });
      dialogRef.afterClosed().subscribe((dialogRef) => {
        if (dialogRef) {
          this.show_detail[id] = !this.show_detail[id];
          if (this.show_detail[id]) {
            this.indexEmitResume = this.emitResume.indexOf(id);
            this.emitResume.splice(this.indexEmitResume, 1);
            this.pdf_show[id] = false;
          } else {
            this.getCandidateLastViewedTimes();
          }
        }
      });
    } else {
      this.show_detail[id] = !this.show_detail[id];
      if (this.show_detail[id]) {
        this.indexEmitResume = this.emitResume.indexOf(id);
        this.emitResume.splice(this.indexEmitResume, 1);
        this.pdf_show[id] = false;
      } else {
        this.getCandidateLastViewedTimes();
      }
    }
    // this.indexEmitCandidateDetail = this.emitCandidateDetail.indexOf(id);
    // if (this.indexEmitCandidateDetail < 0) {
    //   this.emitCandidateDetail.push(id)
    //   if (this.emitCandidateDetail.length > 0) {
    //     this.socket_connection.unsubscribe();
    //   }
    // }
    // else {
    //   this.emitCandidateDetail.splice(this.indexEmitCandidateDetail, 1)
    //   if (this.emitCandidateDetail.length < 1 && this.emitResume.length < 1) {
    //     this.getMyActiveCandidates();
    //     this.socket_connection = this.talentService.socketConnection().subscribe(active_candidates => {
    //       this.getMyActiveCandidates()
    //     });
    //   }
    // }
  }

  pdf_toggle(x): void {
    // this.pdf_show[x.id]=!this.pdf_show[x.id];
    // this.indexEmitResume = this.emitResume.indexOf(x.id);
    // if (this.indexEmitResume < 0) {
    //   this.emitResume.push(x.id)
    //   if (this.emitResume.length > 0) {
    //     this.socket_connection.unsubscribe();
    //   }
    // }
    // else {
    //   this.emitResume.splice(this.indexEmitResume, 1)
    //   if (this.emitResume.length < 1 && this.emitCandidateDetail.length < 1) {
    //     this.socket_connection = this.talentService.socketConnection().subscribe(active_candidates => {
    //       this.getMyActiveCandidates()
    //     });
    //   }
    // }

    // x.pdf_file_id = "https://www.yodawe.com/pdf_viewer/web/viewer.html?file=/docs/resumes/" + x.file_id + "?" + x.filename;

    if (!this.pdf_show[x.id]) {
      this.updateLastViewed(x.id);
      // this.indexEmitCandidateDetail = this.emitCandidateDetail.indexOf(x.id);
      // this.emitCandidateDetail.splice(this.indexEmitCandidateDetail, 1)
      this.show_detail[x.id] = false;
      let file_id;
      let file_name;
      this.talentService
        .getMyActiveCandidateResume(x.id)
        .subscribe((result) => {
          file_id = result["file_id"];
          file_name = result["filename"];

          if (file_id === "-") {
            alert("This candidate does't have a Resume so far");
          } else {
            this.talentService.getPDF(file_id).subscribe((result) => {
              // this.pdf[x.id] = result;
              this.pdf[x.id] = {
                src: new Uint8Array(result),
                file_name: file_name,
              };
              this.pdf_show[x.id] = true;
            });
          }
        });
    } else {
      this.pdf_show[x.id] = !this.pdf_show[x.id];
    }
  }
  updateLastViewed(id) {
    this.talentService
      .updateCandidateLastViewedTime(id)
      .subscribe((result) => this.getCandidateLastViewedTimes());
  }

  cleanURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  person: string;
  receiveMessage($event) {
    for (var i = 0; i < this.active_candidates.length; i++) {
      if (this.active_candidates[i]) {
        if (this.active_candidates[i].id == $event.id) {
          // not the best but
          this.active_candidates[i].first_name = $event.first_name;
          this.active_candidates[i].last_name = $event.last_name;
          this.active_candidates[i].phone_mobile = $event.phone_mobile;
          this.active_candidates[i].email_address = $event.email_address;
          this.active_candidates[i].primary_skill = $event.primary_skill_string;
        }
      }
    }
  }

  receiveMessageWorkflow($event) {
    for (var i = 0; i < this.active_candidates.length; i++) {
      if (this.active_candidates[i]) {
        if (this.active_candidates[i].id == $event[0].candidate_id) {
          for (var j = 0; j < $event.length; j++) {
            console.log($event[j].latest_status);
            this.active_candidates[i].workflow[j].latest_status =
              $event[j].latest_status;
            this.active_candidates[i].workflow[j].wf_client = $event[j].client;
            this.active_candidates[i].workflow[j].wf_notes = $event[j].notes;
          }
        }
      }
    }
  }
}
