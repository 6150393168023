import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dropdown-basic',
  templateUrl: './dropdown-basic.component.html',
  styleUrls: ['./dropdown-basic.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownBasicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
