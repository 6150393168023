import { Location } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { TalentService } from "../talent.service";
import { DuplicateOverrideDialogComponent } from "../duplicate-override-dialog/duplicate-override-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "app-add-new",
  templateUrl: "./add-new.component.html",
  styleUrls: ["./add-new.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AddNewComponent implements OnInit {
  tag_delete_msg;
  add_alert_msg;

  skills: any = [];
  regions: any = [];
  clients: any = [];
  resumeSources: any = [];
  vendorSource: any = [];
  businessKnowledge: any = [];
  workflowStatus: any = [];
  graduationYearOptions: any = [];

  candidate: any = {};

  workingStatus: any = [];
  jobPreference: any = [];
  titles: any = [];

  workExperienceOptions: any = [];
  leavePeriod: any = [];

  fileUploadFormData: FormData;
  isScaning: boolean = false;
  isScaningDone: boolean = false;

  Allfiles: any = [];
  resumes: any = [];
  otherFiles: any = [];

  saveCompensation() {}
  viewCompensation() {}

  viewContract() {}
  saveContractRate() {}

  addWorkflow() {}

  addAlert() {}

  save_confirm(): void {
    this.talentService.checkDuplicateCandidate(this.candidate).subscribe(
      (res) => {
        if (res.duplicate) {
          const dialogRef = this.dialog.open(DuplicateOverrideDialogComponent, {
            width: "550px",
            data: {
              first_name: this.candidate.first_name,
              last_name: this.candidate.last_name,
              override: "",
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result == "Yes") {
              this.save();
            }
          });
        } else {
          console.log("will save");
          this.save();
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        console.log("done");
      }
    );
  }

  doneMsg;
  save(): void {
    this.doneMsg = "";
    for (const key in this.candidate) {
      this.candidate[key] = String(this.candidate[key]);
    }
    this.talentService.saveCandidateDetail(this.candidate).subscribe(
      (res) => {
        if (this.resumes.length !== 0) {
          let fileUploadFormData = new FormData();
          for (const file of this.resumes) {
            fileUploadFormData.append("file", file);
          }
          fileUploadFormData.append("candidate_id", res.id);
          this.talentService.fileUpload(fileUploadFormData).subscribe(
            (res) => {},
            (error) => {
              window.alert("Error saving Resume");
            }
          );
        }
        if (this.otherFiles.length !== 0) {
          let otherFileUploadFormData = new FormData();
          for (const file of this.otherFiles) {
            otherFileUploadFormData.append("file", file);
          }
          otherFileUploadFormData.append("candidate_id", res.id);
          this.talentService.otherFileUpload(otherFileUploadFormData).subscribe(
            (res) => {},
            (error) => {
              window.alert("Error saving files");
            }
          );
        }
        this.router.navigate(["candidate-detail/" + String(res.id)]);
      },
      (error) => {
        console.log("error saving");
      },
      () => {
        this.doneMsg = "Done";
      }
    );
  }

  goBack(): void {
    this.location.back();
  }

  uploaded = false;

  // fileUpload(id,event)  {
  //   this.uploaded = false;
  //   this.Allfiles=[];
  //   let fileList: FileList= event.target.files;
  //   console.log(fileList);
  //   if(fileList.length > 0) {
  //     let formData:FormData = new FormData();
  //     for (var i=0;i<fileList.length;i++){
  //       let file: File = fileList[i];
  //       console.log(file);
  //       formData.append('file', file);
  //       formData.append('candidate_id',id);
  //       this.Allfiles[i]=fileList[i].name;
  //     }
  //       this.fileUploadFormData = formData;
  //       this.uploaded = true;
  //   }
  // }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private talentService: TalentService,
    private location: Location
  ) {}

  logger() {
    console.log(this.candidate);
  }

  toggler(key, value) {
    if (this.candidate[key] === true) {
      this.candidate[key] = "1";
    } else {
      this.candidate[key] = "0";
    }
  }

  ngOnChanges() {}

  ngOnInit() {
    for (let i = 0; i < 31; i++) {
      this.workExperienceOptions[i] = String(i);
    }
    this.leavePeriod[0] = ["Immediate"];
    for (let i = 1; i < 13; i++) {
      this.leavePeriod[i] = i + " Week";
    }
    this.jobPreference = this.talentService.jobPreference;
    this.titles = this.talentService.titles;
    this.workingStatus = this.talentService.workingStatus;

    this.talentService.getSkills().subscribe((skills) => {
      this.skills = skills;
      // console.log(skills);
    });
    this.talentService.getRegions().subscribe((regions) => {
      this.regions = regions;
      // console.log(regions);
    });
    // Comment out the function because i dont think the client table has maintained since a
    // long time ago

    // this.talentService.getClients().subscribe((clients) => {
    //   this.clients = clients;
    //   // console.log(clients);
    // });
    this.talentService.getResumeSource().subscribe((resumeSources) => {
      this.resumeSources = resumeSources;
      // console.log(resumeSources);
    });
    this.talentService.getBusinessKnowledge().subscribe((businessKnowledge) => {
      this.businessKnowledge = businessKnowledge;
      // console.log(businessKnowledge);
    });
    this.talentService.getVendorSource().subscribe((vendorSource) => {
      this.vendorSource = vendorSource;
      // console.log(vendorSource);
    });

    this.graduationYearOptions = Array(100)
      .fill(new Date().getFullYear())
      .map((x, y) => x - y);
  }

  // fileUpload(id,event)  {
  //   let fileList: FileList= event.target.files;
  //   if(fileList.length > 0) {
  //     for (var i=0;i<fileList.length;i++){
  //       let file: File = fileList[i];
  //       this.fileUploadFormData.append('file', file);
  //       this.fileUploadFormData.append('candidate_id',id);
  //       this.fileUploadFormData.append('candidate_id',id);
  //       this.Allfiles.push(file["name"])
  //     }
  //   }
  // }

  get_auto_fill_data(file) {
    this.isScaning = true;
    let fileUploadFormData = new FormData();
    fileUploadFormData.append("file", file);

    this.talentService.getAutoFillInfo(fileUploadFormData).subscribe(
      (res) => {
        console.log(res);
        this.candidate.first_name = res.name.split(" ")[0];
        this.candidate.last_name = res.name.split(" ")[1];
        this.candidate.phone_mobile = res.phone;
        this.candidate.email_address = res.email;
        if (res.graduation_year) {
          this.candidate.graduate_year = res.graduation_year;
        }

        this.candidate.colleges = res.school[0];
        // this.candidate.current_employer = res.company[0];
        this.isScaning = false;
        this.isScaningDone = true;
        setTimeout(() => {
          this.isScaningDone = false;
        }, 1500);
      },
      (error) => {
        window.alert("Scanning Resume Failed... ");
        console.log(error);
        this.isScaning = false;
      }
    );
  }

  fileUpload(event) {
    let resumesList = event.target.files;
    for (const item of resumesList) {
      this.resumes.push(item);
    }
    this.get_auto_fill_data(this.resumes[this.resumes.length - 1]);
  }

  fileDrop(event) {
    for (const item of event) {
      this.resumes.push(item);
    }
    this.get_auto_fill_data(this.resumes[this.resumes.length - 1]);
  }

  removeResume(i) {
    this.resumes.splice(i, 1);
  }

  otherFileUpload(event) {
    let otherFileList = event.target.files;
    for (const item of otherFileList) {
      this.otherFiles.push(item);
    }
  }

  otherFileDrop(event) {
    for (const item of event) {
      this.otherFiles.push(item);
    }
  }

  removeOtherFile(i) {
    this.otherFiles.splice(i, 1);
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  formatTime(lastModified) {
    let modified_date = new Date(lastModified);
    var curr_date = modified_date.getDate();
    var curr_month = modified_date.getMonth() + 1; //Months are zero based
    var curr_year = modified_date.getFullYear();
    return curr_year + "-" + curr_month + "-" + curr_date;
  }

  handleSkillUpdate(e) {
    console.log("ee>>>", e);
    if (e == "text" && this.candidate.newSkill) {
      if (this.candidate.newSkill.length != 0 && this.candidate.primary_skill) {
        this.candidate.primary_skill = null;
      }
    } else if (e == "select" && this.candidate.primary_skill) {
      this.candidate.newSkill = null;
    }
  }
}
