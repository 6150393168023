import { Input,Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TalentService } from '../talent.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { StarRatingComponent } from '../star-rating/star-rating.component';

@Component({
  selector: 'app-shortlisted',
  templateUrl: './shortlisted.component.html',
  styleUrls: ['./shortlisted.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShortlistedComponent implements OnInit {
  filteredCandidates:any =[];
  p;
  @Input() shortlistIdInput: number;
  @Input() keywords:string;
  @Input() filter:string;
  candidates:any[];
  sortType: string = 'last_update';
  sortReverse: boolean = false;
  show_detail: Object = {};
  pdf_show: Object = {};
  active_candidates: any = {};
  shortlist_id;
  candidate_id;
  shortlist_name;
  pdf: any = {};
  show_filter: boolean = false;
  filterUsers: any = [];
  selectedUsers: any = {[-1]: true};
  min_experience: number;
  max_experience: number;
  workingStatusOptions: any = [];
  selectedWorkingStatus: any = {[-1]: true};
  show_filter_button: boolean = false;
  jobPreferenceOptions: any = [];
  selectedJobPreference: any = {[-1]: true};



  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private talentService: TalentService) { }
  
 

  ngOnInit() {
    if(this.shortlistIdInput){
      this.shortlist_id = this.shortlistIdInput;
    }
    else{
      this.shortlist_id = this.route.snapshot.paramMap.get('shortlist_id');
    }
    this.getShortlistedCandidates(this.shortlist_id);
    this.getShortlistInfo(this.shortlist_id);
    this.getShortlistFilterOptions();
  }

  getShortlistFilterOptions(){
    this.talentService.getShortlistFilterOptions().subscribe(options=>{
      this.filterUsers = options.users;
      this.filterUsers.forEach(n=>this.selectedUsers[n.id]=true);
      var op = this.talentService.workingStatus;
      this.workingStatusOptions = op.slice(0,1).concat(op.slice(2));
      this.workingStatusOptions.forEach(n=>this.selectedWorkingStatus[n.value]=true);
      this.jobPreferenceOptions = this.talentService.jobPreference;
      this.jobPreferenceOptions.forEach(n=>this.selectedJobPreference[n.value]=true);
      this.show_filter_button = true;
    })
  }
  getShortlistInfo(id): void {
    this.talentService.getShortlistInfo(id)
      .subscribe(shortlist => {
        this.shortlist_name = shortlist['label'];
      });
  }
  
  getShortlistedCandidates(id): void {
    
    this.talentService.getShortlistedCandidates(id)
      .subscribe(candidates => {
        this.candidates = candidates;
        console.log(candidates);
        this.filteredCandidates=candidates;
      });
  }

  removeCandidate(candidate_id){
    this.candidate_id = candidate_id;
    this.talentService.removeCandidateFromShortlist(this.shortlist_id,this.candidate_id)
      .subscribe(result => { 
        console.log("result"+result); 
        this.getShortlistedCandidates(this.shortlist_id);
       }); 
  }

  detail_toggle(x): void {
    
    var id = x.id;
    this.show_detail[id] = !this.show_detail[id] ; 
    console.log(id);
    console.log(this.show_detail[id]); 
    if(this.show_detail[id]){
      this.pdf_show[id]=false;
    }
  }
  
  pdf_toggle(x): void {
    
    var id = x.id;
    // this.pdf_show[id]=!this.pdf_show[id];
    x.pdf_file_id="https://www.yodawe.com/pdf_viewer/web/viewer.html?file=/docs/resumes/"+x.file_id+"?"+x.filename;
    if(!this.pdf_show[id]){ 
      this.show_detail[id]=false;
      this.talentService.updateCandidateLastViewedTime(id)
      .subscribe(result=>console.log(result));
      this.talentService.getPDF(x.file_id).subscribe(result=>{
        this.pdf[x.id] = new Uint8Array(result);
        this.pdf_show[id]=!this.pdf_show[id];
      })
    }
    else{
      this.pdf_show[id]=!this.pdf_show[id];
    }
  }
  cleanURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  receiveMessage($event) {
    for(var i=0;i<this.candidates.length;i++){
      if(this.candidates[i]){
        if(this.candidates[i].id == $event.id) { // not the best but
            this.candidates[i].first_name=$event.first_name;
            this.candidates[i].last_name=$event.last_name;
            this.candidates[i].phone_mobile=$event.phone_mobile;
            this.candidates[i].email_address = $event.email_address;
            this.candidates[i].primary_skill = $event.primary_skill_string;  
            this.candidates[i].rating=$event.rating;
            this.candidates[i].job_preference = $event.job_preference;
            this.candidates[i].working_status = $event.working_status;
            this.candidates[i].comp_expectation= $event.comp_expectation; 
        }
      } 
    }
  }
  allUsersSelected() {
    return this.filterUsers.every(n=>this.selectedUsers[n.id]===true) && this.selectedUsers[-1]===true;
  }
  toggleAllUsers() {
    if(this.allUsersSelected()){
      this.selectedUsers = {};
    }
    else{
      this.filterUsers.forEach(n=>this.selectedUsers[n.id]=true);
      this.selectedUsers[-1]=true;
    }
    this.filterShortlisted();
  }
  toggleUser(user_id) {
    this.selectedUsers[user_id] = !this.selectedUsers[user_id];
    this.filterShortlisted();
  }
  filterUser(candidates){
    if(this.allUsersSelected()){
      return candidates;
    }
    let uncheckedUsers = this.filterUsers.reduce((obj,n)=>{
        obj[n.id] = this.selectedUsers[n.id]!==true
        return obj;
      },{})
    return candidates.filter(n=>uncheckedUsers[n.added_by_id]===false||
      (uncheckedUsers[n.added_by_id]===undefined&&this.selectedUsers[-1]))
  }
  filterExperience(candidates){
    if(!this.min_experience && !this.max_experience){
      return candidates;
    }
    else if(this.min_experience && this.max_experience){
      return candidates.filter(n=>{
        let exp = parseInt(n.work_experience);
        return exp >= this.min_experience && exp <= this.max_experience;
      })
    }
    else if(this.min_experience){
      return candidates.filter(n=>parseInt(n.work_experience) >= this.min_experience);
    }
    else{
      return candidates.filter(n=>parseInt(n.work_experience) <= this.max_experience);
    }
  }
  allWorkingStatus() {
    return this.workingStatusOptions.every(n=>this.selectedWorkingStatus[n.value]===true) && this.selectedWorkingStatus[-1]===true;
  }
  toggleAllWorkingStatus() {
    if(this.allWorkingStatus())
      this.selectedWorkingStatus = {};
    else{
      this.workingStatusOptions.forEach(n=>this.selectedWorkingStatus[n.value]=true);
      this.selectedWorkingStatus[-1]=true;
    }
    this.filterShortlisted();
  }
  toggleWorkingStatus(id) {
    this.selectedWorkingStatus[id] = !this.selectedWorkingStatus[id];
    this.filterShortlisted();
  }
  filterWorkingStatus(candidates){
    return candidates.filter(n=>(this.selectedWorkingStatus[-1]&&!n.working_status)||this.selectedWorkingStatus[n.working_status]
    ||(n.working_status==="Green Card"&&this.selectedWorkingStatus["US Citizen/Green Card"]))
  }
  allJobPreference() {
    return this.jobPreferenceOptions.every(n=>this.selectedJobPreference[n.value]===true) && this.selectedJobPreference[-1]===true;
  }
  toggleAllJobPreference() {
    if(this.allJobPreference())
      this.selectedJobPreference = {};
    else{
      this.jobPreferenceOptions.forEach(n=>this.selectedJobPreference[n.value]=true);
      this.selectedJobPreference[-1]=true;
    }
    this.filterShortlisted();
  }
  toggleJobPreference(id) {
    this.selectedJobPreference[id] = !this.selectedJobPreference[id];
    this.filterShortlisted();
  }
  filterJobPreference(candidates){
    return candidates.filter(n=>(this.selectedJobPreference[-1]&&!n.job_preference)||
    (n.job_preference&&n.job_preference.split(",").some(pref=>this.selectedJobPreference[pref])))
  }
  filterShortlisted(){
    this.filteredCandidates = this.candidates;
    this.filteredCandidates = this.filterUser(this.filteredCandidates);
    this.filteredCandidates = this.filterExperience(this.filteredCandidates)
    this.filteredCandidates = this.filterWorkingStatus(this.filteredCandidates)
    this.filteredCandidates = this.filterJobPreference(this.filteredCandidates)
  }
}
