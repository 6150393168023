import {
  ViewChild,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TalentService } from "../talent.service";
import { PageEvent, MatPaginator, MatIconRegistry } from "@angular/material";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
  p;
  isDisabled = [];
  @ViewChild("paginator1", { static: false }) paginator1: MatPaginator;
  @Input() keywords: string = "";
  @Input() filter: string;
  candidates: any[];
  sortType: string = "";
  sortReverse: boolean = false;
  show_filter: boolean = false;
  show_detail: Object = {};
  pdf_show: Object = {};
  active_candidates: any = {};
  length;
  safe_url;
  role_id;
  roles: any = [];
  search_context: string;
  pageSize = 10;
  role_title;
  client;
  change_color = [];
  pdf: any = {};
  filteredCandidates: any = [];
  show_advanced_filter: boolean = false;
  filter_city: any;
  filter_state: any;
  regions: Array<any>;
  min_experience: number;
  max_experience: number;
  workingStatusOptions: any = [];
  selectedWorkingStatus: any = { [-1]: true };
  show_filter_button: boolean = false;
  jobPreferenceOptions: any = [];
  selectedJobPreference: any = { [-1]: true };
  itemsPerPage: number = 20;
  userSettings: any = {};
  lastViewedTimes: Object = {};
  isloading: boolean = false;
  note: string = "";
  showNote: boolean = false;
  //pageSizeOptions = [10,20,50,100];
  list_regions: any = {};
  allGenderList: any = { M: true, F: true, Unknown: true };
  // MatPaginator Output
  pageEvent: PageEvent;
  activateCandidateIds: any = [];
  hasSelectActivateCandidates: boolean = false;
  beginSearch: boolean = false;

  constructor(
    iconRegistry: MatIconRegistry,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private talentService: TalentService
  ) {
    iconRegistry.addSvgIcon(
      "list",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-list-24px.svg"
      )
    );

    iconRegistry.addSvgIcon(
      "add",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-add_circle_outline-24px.svg"
      )
    );

    iconRegistry.addSvgIcon(
      "remove",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/baseline-remove_circle_outline-24px.svg"
      )
    );

    this.role_id = +this.route.snapshot.paramMap.get("role_id");
    if (this.role_id) {
      this.search_context = "Role-Search";
      this.itemsPerPage = 10;
    } else {
      this.search_context = "Generic-Search";
    }
    this.show_filter = true;
  }

  ngOnInit() {
    this.getRoleDetail(this.role_id);
    this.getShortlistFilterOptions();
    this.getCandidateLastViewedTimes();
    this.talentService.getUserSettings().subscribe((result) => {
      this.userSettings = result;
    });
    this.talentService.getRegions().subscribe((regions) => {
      this.regions = regions;
      this.generate_list_region();
    });
    const search_history = localStorage.getItem("searchHistory");
    if (search_history != "") {
      this.loadNote("history");
      this.keywords = search_history;
      // this.searchCandidates()
    }
    this.talentService.getActiveCandidateIds().subscribe((ids) => {
      this.activateCandidateIds = ids;
    });
  }

  getCandidateLastViewedTimes(): void {
    this.talentService.getCandidateLastViewedTimes().subscribe((times) => {
      this.lastViewedTimes = times;
    });
  }
  getShortlistFilterOptions() {
    var op = this.talentService.workingStatus;
    this.workingStatusOptions = op.slice(0, 1).concat(op.slice(2));
    this.workingStatusOptions.forEach(
      (n) => (this.selectedWorkingStatus[n.value] = true)
    );
    this.jobPreferenceOptions = this.talentService.jobPreference;
    this.jobPreferenceOptions.forEach(
      (n) => (this.selectedJobPreference[n.value] = true)
    );
    this.show_filter_button = true;
  }

  i = 0;
  setColor(x) {
    if (this.i == 0) {
      this.change_color[x.id] = true;
      this.i++;
    } else if (this.i == 1 && this.change_color[x.id] == true) {
      this.change_color[x.id] = false;
      this.i--;
    }
  }

  getRoleDetail(role_id): void {
    this.talentService.getUserRoles().subscribe((roles) => {
      this.roles = roles;
      // console.log("roles:" + roles);

      for (var i = 0; i < this.roles.length; i++) {
        if (role_id == this.roles[i].role_id) {
          this.role_title = this.roles[i].title;
          this.client = this.roles[i].client;
        }
      }
    });
  }

  isdisabled = [];

  addToRole(candidate_id, role_id): void {
    this.isdisabled[candidate_id] = true;
    this.show_detail[candidate_id] = false;
    this.pdf_show[candidate_id] = false;
    this.talentService.getUserRoles().subscribe((roles) => {
      this.roles = roles;
      console.log("roles:" + roles);
      var role_detail;
      for (var i = 0; i < this.roles.length; i++) {
        if (role_id == this.roles[i].role_id) {
          role_detail = this.roles[i];
        }
      }
      console.log(role_detail);
      var x = { candidate_id: candidate_id, role_details: role_detail };
      this.talentService.addCandidateToRole(x).subscribe((result) => {
        console.log(result);
      });
    });
  }

  isdisabled1 = [];

  removeFromRole(candidate_id): void {
    this.isdisabled1[candidate_id] = true;
    this.show_detail[candidate_id] = false;
    this.pdf_show[candidate_id] = false;
    var role_id = this.role_id;
    this.talentService
      .removeCandidateFromRole(role_id, candidate_id)
      .subscribe((result) => {
        console.log("result" + result);
      });
  }

  makeArray(x): any {
    return Array(x).fill(1);
  }

  filename;
  searchCandidates(pagenum = 1, pagelen = 10): void {
    this.beginSearch = true;
    let startTime = new Date();
    this.isloading = true;
    console.log("Search Key word : ", this.keywords);
    localStorage.setItem("searchHistory", this.keywords);
    var search_role_id = 0;
    this.sortType = "";
    this.sortReverse = false;
    if (this.search_context == "Role-Search") {
      search_role_id = this.role_id;
    }

    this.talentService
      .searchCandidates(search_role_id, this.keywords)
      .subscribe((results) => {
        this.isloading = false;
        this.candidates = results.results;
        let endTime = new Date();
        let timeDiff = endTime.valueOf() - startTime.valueOf();
        timeDiff /= 1000;
        console.log(timeDiff);
        this.loadNote("finish", timeDiff);
        for (var i = 0; i < this.candidates.length; i++) {
          this.candidates[i].last_viewed =
            this.lastViewedTimes[this.candidates[i].id];
          if (this.candidates[i].filename == "-") {
            this.candidates[i].filename = "";
          }
        }
        this.filteredCandidates = this.candidates;
        this.length = results.total;
      });
  }

  generate_list_region() {
    let list_res = {};
    for (let r of this.regions) {
      if (r.country in list_res) {
        list_res[r.country].push(r.state_name);
      } else {
        list_res[r.country] = [r.state_name];
      }
    }
    this.list_regions = list_res;
  }

  detail_toggle(x): void {
    var id = x.id;
    this.show_detail[id] = !this.show_detail[id];
    console.log(this.show_detail[id]);
    if (this.show_detail[id]) {
      this.pdf_show[id] = false;
    } else {
      this.getCandidateLastViewedTimes();
    }
  }

  pdf_toggle(x): void {
    var id = x.id;
    // this.pdf_show[id]=!this.pdf_show[id];
    x.pdf_file_id =
      "https://www.yodawe.com/pdf_viewer/web/viewer.html?file=/docs/resumes/" +
      x.file_id +
      "?" +
      x.filename;
    console.log(x.pdf_file_id);
    if (!this.pdf_show[id]) {
      this.updateLastViewed(x.id);
      this.show_detail[id] = false;
      this.talentService
        .updateCandidateLastViewedTime(id)
        .subscribe((result) => console.log(result));
      this.talentService.getPDF(x.file_id).subscribe((result) => {
        this.pdf[x.id] = new Uint8Array(result);
        this.pdf_show[id] = !this.pdf_show[id];
      });
    } else {
      this.pdf_show[id] = !this.pdf_show[id];
    }
  }
  updateLastViewed(id) {
    this.talentService
      .updateCandidateLastViewedTime(id)
      .subscribe((result) => this.getCandidateLastViewedTimes());
  }

  resetConfirm(): void {
    if (confirm("This will add back all removed candidates.")) {
      this.talentService
        .resetRoleRemovedCandidates(this.role_id)
        .subscribe((result) => {
          this.isdisabled1 = [];
          this.searchCandidates();
          console.log(result);
        });
    }
  }

  cleanURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  cleanURL2(x) {
    var url =
      "https://www.yodawe.com/pdf_viewer/web/viewer.html?file=/docs/resumes/" +
      x.file_id +
      "?" +
      x.filename;
    this.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  receiveMessage($event) {
    console.log("received event" + event);
    console.log("received event" + $event);
    for (var i = 0; i < this.candidates.length; i++) {
      if (this.candidates[i]) {
        if (this.candidates[i].id == $event.id) {
          // not the best but
          this.candidates[i].first_name = $event.first_name;
          this.candidates[i].last_name = $event.last_name;
          this.candidates[i].phone_mobile = $event.phone_mobile;
          this.candidates[i].job_preference = $event.job_preference;
          this.candidates[i].rating = $event.rating;
          this.candidates[i].email_address = $event.email_address;
          this.candidates[i].primary_skill = $event.primary_skill_string;
          this.candidates[i].working_status = $event.working_status;
          this.candidates[i].comp_expectation = $event.comp_expectation;
          this.candidates[i].region = $event.region;
          this.candidates[i].city = $event.city;
        }
      }
    }
  }

  onPageClick(event) {
    this.searchCandidates(event.pageIndex + 1, event.pageSize);
    window.scroll(0, 0);
  }

  filterActivateCandidates(candidates) {
    if (this.hasSelectActivateCandidates) {
      return candidates.filter((n) =>
        this.activateCandidateIds.includes(n["id"])
      );
    }
    return candidates;
  }

  filterExperience(candidates) {
    let currYear = new Date().getFullYear();
    if (!this.min_experience && !this.max_experience) {
      return candidates;
    } else if (this.min_experience && this.max_experience) {
      return candidates.filter((n) => {
        let exp = currYear - parseInt(n.undergraduate_year);
        return exp >= this.min_experience && exp <= this.max_experience;
      });
    } else if (this.min_experience) {
      return candidates.filter(
        (n) => currYear - parseInt(n.undergraduate_year) >= this.min_experience
      );
    } else {
      return candidates.filter(
        (n) => currYear - parseInt(n.undergraduate_year) <= this.max_experience
      );
    }
  }

  filterCityAndState(candidates) {
    if (this.filter_city) {
      candidates = candidates.filter((n) => n.city == this.filter_city);
    }
    if (this.filter_state && this.filter_state != "ALL") {
      candidates = candidates.filter((n) => n.region == this.filter_state);
    }
    return candidates;
  }

  filterStatesByCountry(country) {
    let new_regions = this.regions.filter((x) => x.country === country);
    return new_regions;
  }

  allWorkingStatus() {
    return (
      this.workingStatusOptions.every(
        (n) => this.selectedWorkingStatus[n.value] === true
      ) && this.selectedWorkingStatus[-1] === true
    );
  }
  toggleAllWorkingStatus() {
    if (this.allWorkingStatus()) this.selectedWorkingStatus = {};
    else {
      this.workingStatusOptions.forEach(
        (n) => (this.selectedWorkingStatus[n.value] = true)
      );
      this.selectedWorkingStatus[-1] = true;
    }
    this.filterShortlisted();
  }
  toggleWorkingStatus(id) {
    this.selectedWorkingStatus[id] = !this.selectedWorkingStatus[id];
    this.filterShortlisted();
  }
  filterWorkingStatus(candidates) {
    return candidates.filter(
      (n) =>
        (this.selectedWorkingStatus[-1] && !n.working_status) ||
        this.selectedWorkingStatus[n.working_status] ||
        (n.working_status === "Green Card" &&
          this.selectedWorkingStatus["US Citizen/Green Card"])
    );
  }

  allGenderStatus() {
    for (const key in this.allGenderList) {
      if (this.allGenderList[key] === false) {
        return false;
      }
    }
    return true;
  }

  toggleAllgenderStatus() {
    if (this.allGenderStatus()) {
      for (const key in this.allGenderList) {
        this.allGenderList[key] = false;
      }
    } else {
      for (const key in this.allGenderList) {
        this.allGenderList[key] = true;
      }
    }
    this.filterShortlisted();
  }

  toggleGender(key) {
    this.allGenderList[key] = !this.allGenderList[key];
    this.filterShortlisted();
  }

  allJobPreference() {
    return (
      this.jobPreferenceOptions.every(
        (n) => this.selectedJobPreference[n.value] === true
      ) && this.selectedJobPreference[-1] === true
    );
  }
  toggleAllJobPreference() {
    if (this.allJobPreference()) this.selectedJobPreference = {};
    else {
      this.jobPreferenceOptions.forEach(
        (n) => (this.selectedJobPreference[n.value] = true)
      );
      this.selectedJobPreference[-1] = true;
    }
    this.filterShortlisted();
  }
  toggleJobPreference(id) {
    this.selectedJobPreference[id] = !this.selectedJobPreference[id];
    this.filterShortlisted();
  }
  filterJobPreference(candidates) {
    return candidates.filter(
      (n) =>
        (this.selectedJobPreference[-1] && !n.job_preference) ||
        (n.job_preference &&
          n.job_preference
            .split(",")
            .some((pref) => this.selectedJobPreference[pref]))
    );
  }

  toggleActivateCandidates() {
    this.hasSelectActivateCandidates = !this.hasSelectActivateCandidates;
    this.filterShortlisted();
  }

  filterGender(candidates) {
    let res: any = [];
    for (const c of candidates) {
      let gender = c["gender"];
      if (gender === "M" || gender === "F") {
        if (this.allGenderList[gender]) {
          res.push(c);
        }
      } else {
        if (this.allGenderList["Unknown"]) {
          res.push(c);
        }
      }
    }
    return res;
  }
  filterShortlisted() {
    this.filteredCandidates = this.candidates;
    this.filteredCandidates = this.filterExperience(this.filteredCandidates);
    this.filteredCandidates = this.filterWorkingStatus(this.filteredCandidates);
    this.filteredCandidates = this.filterJobPreference(this.filteredCandidates);
    this.filteredCandidates = this.filterCityAndState(this.filteredCandidates);
    this.filteredCandidates = this.filterGender(this.filteredCandidates);
    this.filteredCandidates = this.filterActivateCandidates(
      this.filteredCandidates
    );
    this.length = this.filteredCandidates.length;
  }

  clearSearch() {
    this.loadNote("clear");
    this.keywords = "";
    this.show_filter = false;
    this.show_advanced_filter = false;
    localStorage.setItem("searchHistory", "");
    this.candidates = [];
    this.filteredCandidates = [];
    this.length = 0;
    this.beginSearch = false;
  }

  loadNote(note_type, elasped_time = 0) {
    let timeout = 2500;
    if (note_type == "history") {
      this.note = "Automatically Load search history ...";
    } else if (note_type == "clear") {
      this.note = "Clear the search history";
    } else if (note_type == "finish") {
      this.note = "Finish in " + elasped_time + " second(s)";
      timeout = 4000;
    }
    this.showNote = true;
    setTimeout(() => {
      this.showNote = false;
    }, timeout);
  }

  dynamicSort(property) {
    let sortOrder = -1;
    if (this.sortReverse) {
      sortOrder = 1;
    }

    if (property == "last_viewed") {
      return function (a, b) {
        let a_time = a[property];
        let b_time = b[property];
        let a_time_list = [];
        let a_rank = 0;
        let b_time_list = [];
        let b_rank = 0;
        let a_num = 0;
        let b_num = 0;
        const rank_list = {
          seconds: 2,
          minute: 3,
          minutes: 4,
          hour: 5,
          hours: 6,
          days: 8,
          week: 9,
          weeks: 10,
          month: 11,
          months: 12,
          year: 13,
          years: 14,
        };

        if (typeof a_time == "undefined") {
          a_rank = 999 * sortOrder;
        } else {
          a_time_list = a_time.split(" ");
          if (a_time_list.length == 1) {
            a_rank = 7;
          } else if (a_time_list.length == 2) {
            a_rank = 1;
          } else {
            a_rank = rank_list[a_time_list[1]];
            a_num = parseInt(a_time_list[0]);
          }
        }

        if (typeof b_time == "undefined") {
          b_rank = 999 * sortOrder;
        } else {
          b_time_list = b_time.split(" ");
          if (b_time_list.length == 1) {
            b_rank = 7;
          } else if (b_time_list.length == 2) {
            b_rank = 1;
          } else {
            b_num = parseInt(b_time_list[0]);
            b_rank = rank_list[b_time_list[1]];
          }
        }
        let res;
        if (a_rank < b_rank) {
          res = -1 * sortOrder;
        } else if (a_rank > b_rank) {
          res = 1 * sortOrder;
        } else {
          if (a_rank == 1 || a_rank == 7 || a_rank == 999 || a_rank == -999) {
            res = 0;
          } else if (a_num != b_num) {
            res = a_num - b_num;
          } else {
            res = 0;
          }
        }
        return res;
      };
    } else {
      return function (a, b) {
        let result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    }
  }

  sortOrders(property) {
    this.sortType = property;
    this.sortReverse = !this.sortReverse;
    this.filteredCandidates.sort(this.dynamicSort(property));
  }

  showAllCandidates() {
    this.keywords = "";
    this.searchCandidates();
  }
}
