import { Inject,Optional,Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material'
import { TalentService } from '../talent.service';

@Component({
  selector: 'app-candidate-communication-history',
  templateUrl: './candidate-communication-history.component.html',
  styleUrls: ['./candidate-communication-history.component.css']
})
export class CandidateCommunicationHistoryComponent implements OnInit {
  candidate_id;
  is_client_contact;
  communications:any = [];

  constructor(@Optional() private dialogRef: MatDialogRef<CandidateCommunicationHistoryComponent>,  @Optional() @Inject(MAT_DIALOG_DATA) data, private talentService: TalentService) {
    this.candidate_id=data.candidate_id;
    this.is_client_contact=data.is_client_contact
  }

  ngOnInit() {
    this.talentService.getCandidateCommunicationHistory(this.candidate_id,this.is_client_contact).subscribe((result) => {
      console.log(result);
      this.communications = result;
    });
  }
}