import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { TalentService } from "../talent.service";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { ContactDetailComponent } from "../contact-detail/contact-detail.component";
import { TodoContactActivityNotificationComponent } from "./todo-contact-activity-notification/todo-contact-activity-notification.component";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";
@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit {
  @ViewChild(ContactDetailComponent, { static: false })
  contactDetail: ContactDetailComponent;
  @Input() filter_input: string = "";
  @Input() contacts: any;
  @Input() client_id: number;
  @Input() client_parent: string;

  current_contacts: any = [];
  show_detail: Array<Boolean> = [];
  notes: Object = {};
  activities: Object = {};
  sortType: string = "last_modified";
  sortReverse: boolean = true;
  clients: any;
  clients_lookup_by_id: Object = {};
  clients_category_lookup_by_id: Object = {};
  filter_lookup: Object = {};
  notification_list = [];
  pagination_id = "main";
  contact_number_in_one_page = 40;
  constructor(private talentService: TalentService, public dialog: MatDialog) {}

  ngOnInit() {
    if (this.contacts && this.contacts.length > 0) {
      this.pagination_id = this.contacts[0].client_id;
      this.contact_number_in_one_page = 8;
    }
    if (!this.contacts) {
      this.talentService.getContacts(0).subscribe(
        (res) => {
          this.contacts = res;
          this.getClients();
        },
        (error) => {
          console.log(error);
          window.alert(error);
        }
      );
    } else {
      this.getClients();
    }

    this.getContactNotification();
    // this.talentService.getYodaweClients().subscribe((yodawe_client) => {
    //   this.clients = yodawe_client;
    //   for (let c of this.clients) {
    //     this.clients_lookup_by_id[c["id"]] = c["company_name"];
    //     this.initDataAfterContactsLoad();
    //   }
    // });
  }

  getClients() {
    this.talentService.getYodaweClients().subscribe((yodawe_client) => {
      this.clients = yodawe_client;
      for (let c of this.clients) {
        this.clients_lookup_by_id[c["id"]] = c["company_name"];
        this.clients_category_lookup_by_id[c["id"]] = c["category"]
        this.initDataAfterContactsLoad();
      }
    });
  }

  getContactNotification() {
    this.talentService.getContactNotification().subscribe((res) => {
      this.notification_list = res;
    });
  }

  initDataAfterContactsLoad() {
    this.show_detail = new Array<Boolean>(this.contacts.length + 1).fill(false);
    for (let contact of this.contacts) {
      let filter_string = "";
      for (let k in contact) {
        if (k == "notes" || k == "activities") {
          if (contact[k].length > 0) {
            filter_string += " ";
            filter_string += String(contact[k][0]["full_name"]).toLowerCase();
            filter_string += " ";
            if (k == "notes") {
              filter_string += String(contact[k][0]["notes"]).toLowerCase();
            } else {
              filter_string += String(
                contact["activities"][0]["activity"]
              ).toLowerCase();
            }
          }
        } else if (k == "client_id") {
          filter_string += " ";
          filter_string += String(
            this.clients_lookup_by_id[contact["client_id"]]
          ).toLowerCase();
          filter_string += String(
            this.clients_category_lookup_by_id[contact["client_id"]]
          ).toLowerCase();
        } else {
          filter_string += " ";
          filter_string += String(contact[k]).toLowerCase();
        }
      }
      this.filter_lookup[contact["id"]] = filter_string;
    }
    this.current_contacts = this.contacts;
  }

  detail_toggle(id) {
    if (this.show_detail[id]) {
      if (this.contactDetail.hasChanged) {
        let title = "Seems like you have unsaved change.";
        let message = "Do you still want to close?";
        let confirm_data = { title: title, type: "confirm", message: message };
        const dialogRef = this.dialog.open(CustomDialogComponent, {
          maxWidth: "600px",
          data: confirm_data,
        });
        dialogRef.afterClosed().subscribe((dialogRef) => {
          if (dialogRef) {
            this.show_detail[id] = !this.show_detail[id];
          }
        });
      } else {
        this.show_detail[id] = !this.show_detail[id];
      }
    } else {
      this.show_detail[id] = !this.show_detail[id];
    }
  }

  applyFilter() {
    let value = this.filter_input;
    if ((value = "")) {
      this.current_contacts = this.contacts;
    } else {
      let new_contacts = [];
      for (let c of this.contacts) {
        if (
          this.filter_lookup[c["id"]].includes(this.filter_input.toLowerCase())
        ) {
          new_contacts.push(c);
        }
      }
      this.current_contacts = [...new_contacts];
    }
  }

  createContact(): void {
    let data = {
      create_new: true,
    };
    if (this.client_parent) {
      data["client_name"] = this.client_parent;
    }
    const dialogRef = this.dialog.open(ContactDetailComponent, {
      width: "1000px",
      height: "800px",
      data: data,
      disableClose: true,
    });
  }

  openNewTab(link): void {
    window.open(link, "_blank");
  }

  oepnTodo() {
    const dialogRef = this.dialog.open(
      TodoContactActivityNotificationComponent,
      {
        width: "1200px",
        height: "600px",
        data: { notification_list: this.notification_list },
      }
    );
    dialogRef.afterClosed().subscribe((res) => this.getContactNotification());
  }
}
