import { Component, OnInit, Input, ViewChild,Optional,Inject } from "@angular/core";
import { TalentService } from "../talent.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-candidates-workflow",
  templateUrl: "./candidates-workflow.component.html",
  styleUrls: ["./candidates-workflow.component.css"],
})
export class CandidatesWorkflowComponent implements OnInit {
  @Input() role_id;
  @Input() detail_view;
  @Input() feedbackHistory?: boolean;
  candidates: any = [];
  filtered_candidates: any = [];

  candidate_loading = true;

  hide_history: boolean = false;
  hide_closed: boolean = false;

  @ViewChild("pdfViewerOnDemand", { static: false }) pdfViewerOnDemand;

  // TODO: put it in the databse and backend query
  status_list = [
    "All",
    "Prospect",
    "Urgent Prospect",
    "Recruiter Screen",
    "Left Message",
    "Resume Submitted",
    "First Interview",
    "Pending Scheduling",
    "Selected",
    "Awaiting Feedback",
    "Started",
  ];
  @Input() filtered_status = "All";
  show_pdf: Object = {};
  pdf: Object = {};

  constructor(private talentService: TalentService,
    // public dialogRef: MatDialogRef<CandidatesWorkflowComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data
    ) {
      if(data){
      this.role_id = data.role_id;
      this.detail_view=data.detail_view
      this.feedbackHistory=data.feedbackHistory
      }
    }

  ngOnInit() {
    this.talentService
      .getCandidatesByRolesId(this.role_id)
      .subscribe((result) => {
        for (let key in result) {
          this.candidates.push(result[key]);
          this.show_pdf[result[key]["id"]] = false;
        }
        this.filtered_candidates = this.candidates;
        this.candidate_loading = false;
        this.applyFilterStatus();
      });
  }

  changeHideClosed() {
    this.hide_closed = !this.hide_closed;
    this.applyFilter();
  }

  applyFilter() {
    this.applyFilterHideClosed();
    this.applyFilterStatus();
  }

  applyFilterHideClosed() {
    if (this.hide_closed) {
      let temp_list = [];
      for (let c of this.candidates) {
        if (
          c.workflow.latest_status != "Closed" &&
          c.workflow.latest_status != "Selected"
        ) {
          temp_list.push(c);
        }
      }
      this.filtered_candidates = temp_list;
    } else {
      this.filtered_candidates = this.candidates.slice(0);
    }
  }

  applyFilterStatus() {
    if (this.feedbackHistory) {
      this.filtered_candidates = this.filtered_candidates.filter(candidate => {
        const filteredStates = candidate.states.filter(state =>
          state.status.includes("Candidate Feedback") || state.status.includes("Client Feedback")
        );
    
        if (filteredStates.length > 0) {
          candidate.states = filteredStates;
          return true;
        }
    
        return false;
      });
    }
    else if (this.filtered_status != "All") {
      let temp_list = [];
      for (let c of this.filtered_candidates) {
        for (let s of c.states) {
          if (s.status.includes(this.filtered_status)) {
            temp_list.push(c);
            break;
          }
        }
      }
      this.filtered_candidates = temp_list;
    }
  }

  toggleShowPdf(candidate_id) {
    if (!this.show_pdf[candidate_id]) {
      let file_id;
      let file_name;
      this.talentService
        .getMyActiveCandidateResume(candidate_id)
        .subscribe((result) => {
          file_id = result["file_id"];
          file_name = result["filename"];

          if (file_id === "-") {
            alert("This candidate does't have a Resume so far");
          } else {
            this.talentService.getPDF(file_id).subscribe((result) => {
              this.pdf[candidate_id] = {
                src: new Uint8Array(result),
                file_name: file_name,
              };
              this.show_pdf[candidate_id] = true;
              console.log(this.show_pdf);
            });
          }
        });
    } else {
      this.show_pdf[candidate_id] = false;
    }
  }
}
